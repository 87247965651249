import { filterNullsFromObject, filterUndefinedsFromObject } from 'helpers';
import { tag } from 'types';
import { TagsFilterInterface } from 'utils/context/reducers/tagsReducer';
import { FilterOption } from 'utils/types/filters';
import { stringToBooleanParser } from './filters';

export const convertSelectedTagsToFilters = (
  tags: Record<string, tag>,
): Array<FilterOption> => {
  return Object.keys(tags).map(key => ({
    value: tags[key].id.toString(),
    label: tags[key].name,
    isFixed: false,
  }));
};

const tagsCreatedByParser = (options: Array<FilterOption>) => {
  if (!options || options.length === 0) return undefined;
  return options.map(option => option.value);
};

export const convertFilterTagsOptionsToDataFilters = (
  filters: TagsFilterInterface,
) => {
  const dataFilters = {
    isArchived: stringToBooleanParser(filters?.status),
    isListVisible: stringToBooleanParser(filters?.visibility),
    createdBy: tagsCreatedByParser(filters?.createdBy),
  };
  return filterUndefinedsFromObject(filterNullsFromObject(dataFilters));
};
