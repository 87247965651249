export const UPDATE_SHIFT_CRITERIA_EXTENSION = `
  mutation UPDATE_SHIFT_CRITERIA_EXTENSION(
    $extensions: [Int]
    $status: String
    $args: JSONString
  ) {
    updateShiftCriteriaExtension(
      extensions: $extensions
      status: $status
      args: $args
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
