import { communicationBatch } from './communicationBatch';
import { messageHistory } from './messageHistory';
import { user } from './user';

export enum CommunicationStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
}

export enum CommunicationMethod {
  TEST = 'TEST',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SLACK = 'SLACK',
}

export type communication = {
  attributionId?: string; // DEPRECATED. Prefer batch.uuid
  batch?: communicationBatch;
  content?: string;
  createdAt?: string; // DateTime
  event?: messageHistory;
  id?: string;
  meta?: string; // JSONField
  method?: CommunicationMethod;
  status?: CommunicationStatus;
  to?: user;
  updatedAt?: string; // DateTime
};
