import { shift } from './shift';
import { UserStatus, WorkerTierOptions } from './user';

export type shiftCriteria = {
  approvedAt?: boolean;
  avgPrioritization?: [number]; // Float
  avgRating?: [number]; // Float
  createdAt?: string; // DateTime
  extensions?: shiftCriteriaExtension[];
  favoriteAt?: boolean;
  hasReviews?: boolean;
  hasWorkedAtLocation?: boolean;
  hasWorkedOnPlatform?: boolean;
  lastProcessed?: string; // DateTime
  market?: [number]; // Int
  name?: string;
  notTags?: [number]; // Int
  notTier?: WorkerTierOptions[];
  processTime?: string; // DateTime
  shift?: shift;
  shiftsCompleted?: [number]; // Int
  status?: UserStatus[];
  stepNumber?: number; // Int
  tags?: [number]; // Int
  tier?: WorkerTierOptions[];
  timingPercentage?: number; // Float
  updatedAt?: string; // DateTime
};

export enum ShiftCriteriaExtensionStatus {
  NEW = 'NEW',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export enum ShiftCriteriaExtensionAction {
  MESSAGE = 'MESSAGE',
  TEST = 'TEST',
}

export type shiftCriteriaExtension = {
  action?: ShiftCriteriaExtensionAction;
  args?: string; // JSONField
  createdAt?: string; // DateTime
  lastProcessed?: string; // DateTime
  name?: string;
  parent?: shiftCriteria;
  status?: ShiftCriteriaExtensionStatus;
  id?: number;
};

export type shiftCriteriaExtensionParsedArgs = {
  playbook_message_template_id?: string; // should be number, but comes back from json blob as string
};

export type playbookMessageTemplate = {
  id?: string; // should be number, but comes back from graph as string
  name?: string;
  subject?: string;
  body?: string;
};
