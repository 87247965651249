import { focusColor } from './colors.config';

const chromePrefillOverride = {
  transition: 'background-color 100ms ease-in-out 0s',
  transitionDelay: '5000s',
};

export const Input = {
  parts: ['field'],
  baseStyle: {
    field: {
      borderWidth: 1,
      backgroundColor: 'white',
      _focus: {
        boxShadow: `0 0 0 2px ${focusColor}`,
      },
      '&:-webkit-autofill': chromePrefillOverride,
      '&:-webkit-autofill:is(hover, focus, active)': chromePrefillOverride,
    },
  },
  variants: {
    default: {
      field: {
        backgroundColor: 'white',
        borderColor: 'blackAlpha.200',
      },
    },
    subtle: {
      field: {
        backgroundColor: 'main.sky',
        borderColor: 'blackAlpha.50',
        _placeholder: {
          color: 'blackAlpha.400',
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
