import { CalendarDefaultTheme } from '@uselessdev/datepicker';

const {
  Calendar: CalendarComponent,
  CalendarControl,
  CalendarMonth,
  CalendarDay,
} = CalendarDefaultTheme.components;

export const Calendar = {
  Calendar: {
    ...CalendarComponent,
    parts: ['calendar', 'months'],
    baseStyle: {
      calendar: {
        ...CalendarComponent.baseStyle.calendar,
        shadow: 'none',
        borderColor: 'none',
        borderWidth: 'none',
      },
      months: {
        ...CalendarComponent.baseStyle.months,
      },
    },
  },
  CalendarControl: {
    ...CalendarControl,
    parts: ['controls', 'button'],
    baseStyle: {
      controls: {
        ...CalendarControl.baseStyle.controls,
      },
      button: {
        ...CalendarControl.baseStyle.button,
        h: 6,
        backgroundColor: 'red',
        _hover: {
          backgroundColor: 'main.blue',
        },
        _focus: {
          outline: 'none',
        },
      },
    },
  },
  CalendarMonth: {
    ...CalendarMonth,
    parts: ['month', 'name', 'week', 'weekday', 'days'],
    baseStyle: {
      ...CalendarMonth.baseStyle,
      name: {
        ...CalendarMonth.baseStyle.name,
        fontFamily: 'default',
        color: 'text.secondary',
        linHeight: 8,
        pt: 1,
        fontWeight: 'semi',
      },
      weekday: {
        ...CalendarMonth.baseStyle.weekday,
        color: 'text.disabled',
        fontSize: 'sm',
        py: 1.5,
      },
    },
  },
  CalendarDay: {
    ...CalendarDay,
    baseStyle: {
      ...CalendarDay.baseStyle,
      fontWeight: 'semi',
      color: 'text.secondary',
      _hover: {
        bgColor: 'navy.100',
        rounded: 'sm',
      },
      _disabled: {
        ...CalendarDay.baseStyle._disabled,
        color: 'gray.100',
        fontWeight: 'normal',
      },
      _active: {
        bgColor: 'navy.500',
        color: 'main.white',
      },
    },
    sizes: CalendarDay.sizes,
    variants: {
      ...CalendarDay.variants,
      selected: {
        ...CalendarDay.variants.selected,
        bgColor: 'navy.500',
        rounded: 'sm',
        _hover: {
          bgColor: 'navy.500',
        },
        _active: {
          bgColor: 'navy.500',
        },
      },
      range: {
        ...CalendarDay.variants.range,
        bgColor: 'navy.300',
        _hover: {
          bgColor: 'navy.500',
        },
        _disabled: {
          _hover: {
            bgColor: 'main.medium',
          },
        },
        _active: {
          bgColor: 'navy.600',
        },
        _focus: {
          bgColor: 'navy.500',
        },
      },
      outside: {
        color: 'gray.400',
        fontWeight: 'medium',
      },
      today: {
        bgColor: 'pink.400',
        rounded: 'sm',
        color: 'main.white',
        _hover: {
          bgColor: 'navy.500',
        },
      },
    },
    defaultProps: CalendarDay.defaultProps,
  },
};
