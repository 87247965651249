import { applyContext, mutateObject, Mutations } from 'helpers';
import { GQL, LocationPermission } from 'types';

export interface LocationPermissionsMutationInterface {
  users: string[];
  locations: string[];
  permissionTypes: LocationPermission[];
}
export type mutateAddLocationPermissionsProps =
  LocationPermissionsMutationInterface;
export type mutateRemoveLocationPermissionsProps =
  LocationPermissionsMutationInterface;

export const mutateAddLocationPermissions = async (
  data: mutateAddLocationPermissionsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'ADD_LOCATION_PERMISSIONS'),
    query: Mutations.ADD_LOCATION_PERMISSIONS,
    data,
    returnKey: 'addLocationPermissions',
  });

export const mutateRemoveLocationPermissions = async (
  data: mutateRemoveLocationPermissionsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'REMOVE_LOCATION_PERMISSIONS'),
    query: Mutations.REMOVE_LOCATION_PERMISSIONS,
    data,
    returnKey: 'removeLocationPermissions',
  });
