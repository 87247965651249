export const UPDATE_REVIEW_MUTATION = `
  mutation UPDATE_REVIEW_MUTATION(
    $id: Int
    $ids: [Int]
    $uuid: String
    $uuids: [String]
    $coaching: String
    $endorsements: [ID]
    $growthAreas: [ID]
    $feedback: String
    $prioritization: Int
    $rating: Float
    $recommended: Boolean
    $status: String
  ) {
    updateReview(
      id: $id
      ids: $ids
      uuid: $uuid
      uuids: $uuids
      coaching: $coaching
      endorsements: $endorsements
      growthAreas: $growthAreas
      feedback: $feedback
      prioritization: $prioritization
      rating: $rating
      recommended: $recommended
      status: $status
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_WORKER_REVIEW_MUTATION = `
  mutation UPDATE_WORKER_REVIEW_MUTATION(
    $id: Int
    $rating: Float
    $ratingDetails: String
    $repeat: Int
    $repeatDetails: String
    $activities: [ID]
    $activitiesDetails: String
    $status: String
  ) {
    updateReview (
      id: $id
      rating: $rating
      ratingDetails: $ratingDetails
      repeat: $repeat
      repeatDetails: $repeatDetails
      activities: $activities
      activitiesDetails: $activitiesDetails
      status: $status
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
