import { BoxProps } from '@chakra-ui/react';
import { SelectProps } from 'ui';

export type ControlValues = Partial<Record<string, ControlOption[]>>;

export enum ControlType {
  MENU = 'menu',
  SELECT = 'select',
  DATE = 'date',
  ZIP = 'zip',
}

export interface ControlOption {
  label: string;
  value: string | boolean | number;
}

export interface ControlProps {
  type: ControlType;
  name?: string;
  label?: string;
  placeholder?: string;
  options?: ControlOption[];
  default?: ControlOption[];
  value?: string | boolean | Date;
  dynamicKey?: string;
  data?: unknown;
  containerProps?: BoxProps;
  selectProps?: SelectProps;
  handleUpdate?: (name: string, values: ControlOption[]) => void;
  values?: ControlValues;
  dynamicOptions?: ControlValues;
}
