import { focusColor } from './colors.config';

export const Select = {
  parts: ['field'],
  baseStyle: {
    field: {
      borderWidth: 1,
      _focus: {
        boxShadow: `0 0 0 2px ${focusColor}`,
      },
    },
  },
  variants: {
    default: {
      field: {
        backgroundColor: 'white',
        borderColor: 'blackAlpha.200',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
