import { uuid as uuidType } from '../../types';

export type FlexDetail = {
  uuid?: uuidType;
  name?: string;
  detailType?: FlexDetailType;
  order?: number;
  visibility?: FlexDetailVisibility;
  values?: FlexDetailValue[];
};

export enum FlexDetailType {
  LOCATION_PHOTOS = 'LOCATION_PHOTOS',
  SHIFT_DETAILS = 'SHIFT_DETAILS',
  BRAND_INFO = 'BRAND_INFO',
  STYLE = 'STYLE',
  STORE_DETAILS = 'STORE_DETAILS',
  AFTER_FLEX = 'AFTER_FLEX',
  SUPPORT = 'SUPPORT',
  CUSTOM = 'CUSTOM',
  DISCOUNT = 'DISCOUNT',
  WHAT_TO_EXPECT = 'WHAT_TO_EXPECT',
  WARDROBE_POLICY = 'WARDROBE_POLICY',
  STORE_LOCATION = 'STORE_LOCATION',
  ABOUT_BRAND = 'ABOUT_BRAND',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export enum FlexDetailVisibility {
  PRE_BOOKING = 'PRE_BOOKING',
  POST_BOOKING = 'POST_BOOKING',
  POST_COMPLETION = 'POST_COMPLETION',
  ALL = 'ALL',
}

export type FlexDetailValue = {
  uuid: uuidType;
  valueType: FlexDetailValueType;
  value: string;
  meta?: string;
};

export enum FlexDetailValueType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
  MARKDOWN = 'MARKDOWN',
  STORE_HOURS = 'STORE_HOURS',
  CLOSED_DATES = 'CLOSED_DATES',
  STORE_LINK = 'STORE_LINK',
  RETURN_POLICY = 'RETURN_POLICY',
  DISCOUNT = 'DISCOUNT',
  WIFI = 'WIFI',
  REVIEWS_LINK = 'REVIEWS_LINK',
  PARKING = 'PARKING',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
}

export enum FlexDetailStatus {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  CLOCKED_IN = 'CLOCKED_IN',
  COMPLETE = 'COMPLETE',
}
