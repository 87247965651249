import { location, organization } from 'types';

export const getOrganizationsFromLocationsList = (
  locations: location[],
): organization[] => {
  const uniqueOrganizations = new Map<string, organization>();

  for (const location of locations) {
    const { organization } = location || {};
    uniqueOrganizations.set(organization?.uuid, organization);
  }

  return Array.from(uniqueOrganizations.values()).filter(x => !!x);
};
