import { paging, SortMap, SortState } from 'types';

export interface CommonStore<T> {
  search: string;
  updateSearch: (value: string) => void;
  sort?: SortMap<Partial<T>>;
  updateSort: (key: string, value: SortState) => void;
  pagination: paging;
  updatePageSize: (pageSize: number) => void;
  updateCurrentPage: (page: number) => void;
  resetPagination: () => void;
}

export const defaultCommonStoreState = {
  search: '',
  sort: {},
  pagination: {
    pageSize: 10,
    currentPage: 0,
  },
};

export const commonStore = <T>(set): CommonStore<T> => ({
  ...defaultCommonStoreState,
  updateSearch: value => {
    set(prev => ({ ...prev, search: value }), false, 'updateSearch');
  },
  updateSort: (key, value) => {
    set(
      prev => ({
        ...prev,
        sort: { ...prev?.sort, [key]: value },
      }),
      false,
      'updateSort',
    );
  },
  updatePageSize: pageSize => {
    set(prev => ({
      pagination: {
        ...prev?.pagination,
        pageSize,
      },
    }));
  },
  updateCurrentPage: page => {
    set(prev => ({
      pagination: {
        ...prev?.pagination,
        currentPage: page,
      },
    }));
  },
  resetPagination: () => {
    set(prev => ({
      pagination: {
        ...prev?.pagination,
        currentPage: 0,
      },
    }));
  },
});
