export const Heading = {
  baseStyle: {
    fontWeight: 'bold',
    fontFamily: 'decorative',
    lineHeight: 'none',
  },
  sizes: {},
  defaultProps: {},
  variants: {},
};
