import { SortState } from 'types';
import { MultiSelectOptionProps } from 'ui';
import { titleCase } from './strings';

export const getSortString = sort => {
  return Object.entries(sort).reduce((acc, [key, value]) => {
    if (value === SortState.ASC) {
      return acc ? `${acc},${key}` : key;
    }
    if (value === SortState.DESC) {
      return acc ? `${acc},-${key}` : `-${key}`;
    }
    return acc;
  }, '');
};

export const sortGenerator = (value: SortState, options: unknown) =>
  Object.keys(options).reduce((acc, key) => {
    acc[key] =
      value === SortState.NONE
        ? null
        : value === SortState.ASC
          ? key
          : `-${key}`;
    return acc;
  }, {});

export const convertEnumToMultiSelectOptions = (
  en: object,
  labels: object = {},
): Array<MultiSelectOptionProps> => {
  return Object.keys(en).map(key => ({
    value: key,
    label:
      Object.keys(labels)?.length > 0
        ? labels[en[key]]
        : titleCase(en[key].toLowerCase()).replace('_', ' '),
  }));
};

export const createBooleanMultiSelectOptions = (
  trueLabel = 'True',
  falseLabel = 'False',
): Array<MultiSelectOptionProps> => {
  return [
    { value: 'true', label: trueLabel },
    { value: 'false', label: falseLabel },
  ];
};
