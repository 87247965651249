import { createNumericIntervalOptions } from 'helpers';
import {
  LocationStatus,
  LocationType,
  PaymentContractTypeOptions,
  RetailerCategoryLabel,
  RetailerSize,
  ReviewType,
  ShiftRequestType,
  UserStatus,
  WorkerShiftStatus,
  WorkerTierOptions,
} from 'types';
import {
  WorkerBookedOptions,
  WorkerHistoryOptions,
  ZIPCODE_DISTANCE_OPTIONS,
} from 'types/src/user';
import { UserFlagType } from 'types/src/userFlag';
import {
  CreatedFilters,
  LastNotWorkedFilters,
  LastWorkedFilters,
  LocationStatusOptions,
  OnboardingStatusFilters,
  ReviewStatusOptions,
  ShiftPaperworkFilters,
  ShiftStatusFilters,
  TimingFilters,
  WorkerFlaggedOptions,
} from 'utils/types/filters';
import {
  convertArrayToFilterOptions,
  convertEnumToFilterOptions,
  convertLabelObjectToFilterOptions,
  getFilterObjectByValue,
} from './filters';

export enum FilterScopes {
  CAMPAIGNS = 'campaigns',
  COMMON = 'common',
  DYNAMIC = 'dynamic',
  LOCATIONS = 'locations',
  REVIEWS = 'reviews',
  SHIFTS = 'shifts',
  SHIFT = 'shift',
  TAGS = 'tags',
  WORKERS = 'workers',
}

export const filters = {
  [FilterScopes.COMMON]: {
    timing: {
      options: convertEnumToFilterOptions(TimingFilters),
      default: getFilterObjectByValue(
        TimingFilters.NEXT_3_DAYS,
        convertEnumToFilterOptions(TimingFilters),
      ),
    },
    retailerSize: {
      options: convertEnumToFilterOptions(RetailerSize),
      default: [],
    },
    retailerCategory: {
      options: convertLabelObjectToFilterOptions(RetailerCategoryLabel),
      default: [],
    },
  },
  [FilterScopes.DYNAMIC]: {
    locations: {
      options: [],
      default: [],
    },
    markets: {
      options: [],
      default: [],
    },
    retailers: {
      options: [],
      default: [],
    },
    staffUsers: {
      options: [],
      default: [],
    },
    tags: {
      options: [],
      default: [],
    },
    acquisitionCampaigns: {
      options: [],
      default: [],
    },
  },
  [FilterScopes.LOCATIONS]: {
    status: {
      options: convertEnumToFilterOptions(LocationStatus),
      default: getFilterObjectByValue(
        LocationStatus.ACTIVE,
        convertEnumToFilterOptions(LocationStatus),
      ),
    },
    active: {
      options: convertEnumToFilterOptions(LocationStatusOptions),
      default: getFilterObjectByValue(
        LocationStatusOptions.ACTIVE,
        convertEnumToFilterOptions(LocationStatusOptions),
      ),
    },
    payment: {
      options: convertEnumToFilterOptions(PaymentContractTypeOptions),
      default: [],
    },
    type: {
      options: convertEnumToFilterOptions(LocationType),
      default: [],
    },
  },
  [FilterScopes.REVIEWS]: {
    status: {
      options: convertEnumToFilterOptions(ReviewStatusOptions),
      default: getFilterObjectByValue(
        ReviewStatusOptions.INTERNAL_REVIEW,
        convertEnumToFilterOptions(ReviewStatusOptions),
      ),
    },
    hasFeedback: {
      options: [
        {
          label: 'Has Feedback',
          value: 'true',
        },
        {
          label: 'No Feedback',
          value: 'false',
        },
      ],
      default: [],
    },
    hasCoaching: {
      options: [
        {
          label: 'Has Coaching',
          value: 'true',
        },
        {
          label: 'No Coaching',
          value: 'false',
        },
      ],
      default: [],
    },
    rating: {
      options: [1, 2, 3, 4, 5].map(val => {
        return {
          label: `${val} star${val === 1 ? '' : 's'}`,
          value: val.toString(),
        };
      }),
      default: [],
    },
    prioritization: {
      options: [1, 2, 3].map(val => {
        return {
          label: `${val}`,
          value: val.toString(),
        };
      }),
      default: [],
    },
    createdSince: {
      options: convertEnumToFilterOptions(CreatedFilters),
      default: [],
    },
    updatedSince: {
      options: convertEnumToFilterOptions(CreatedFilters),
      default: [],
    },
    reviewType: {
      options: convertEnumToFilterOptions(ReviewType),
      default: getFilterObjectByValue(
        ReviewType.RETAILER,
        convertEnumToFilterOptions(ReviewType),
      ),
    },
  },
  [FilterScopes.SHIFTS]: {
    status: {
      options: convertEnumToFilterOptions(ShiftStatusFilters),
      default: [],
    },
    paperwork: {
      options: convertEnumToFilterOptions(ShiftPaperworkFilters),
      default: getFilterObjectByValue(
        ShiftPaperworkFilters.ALL,
        convertEnumToFilterOptions(ShiftPaperworkFilters),
      ),
    },
    isRetailerApproved: {
      options: convertArrayToFilterOptions([true, false]),
      default: convertArrayToFilterOptions([true, false])[0],
    },
    requestType: {
      options: convertEnumToFilterOptions(ShiftRequestType),
      default: null,
    },
  },
  [FilterScopes.SHIFT]: {
    workershiftStatus: {
      options: convertEnumToFilterOptions(WorkerShiftStatus),
      default: [],
    },
    tiers: {
      options: convertEnumToFilterOptions(WorkerTierOptions),
      default: [],
    },
    ratingMax: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 5.0,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    ratingMin: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 5.0,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    priorityMax: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 3.1,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    priorityMin: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 3.1,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    shiftsCompletedMin: {
      options: convertArrayToFilterOptions([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
      ]),
      default: [],
    },
    shiftsCompletedMax: {
      options: convertArrayToFilterOptions([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
      ]),
      default: [],
    },
    zipDistance: {
      options: convertArrayToFilterOptions([1, 3, 5, 7, 10, 15, 20, 25, 30]),
      default: [],
    },
    zipcode: {
      options: [], // Intentionally blank. Input field, not a select option
      default: [],
    },
    zipcodeMaxDistance: {
      options: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
      default: [],
    },
    zipcodeMinDistance: {
      options: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
      default: [],
    },
    hasReviews: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
  },
  [FilterScopes.TAGS]: {
    status: {
      options: [
        {
          label: 'All',
          value: 'none',
        },
        {
          label: 'Is Archived',
          value: 'true',
        },
        {
          label: 'Active',
          value: 'false',
        },
      ],
      default: [],
    },
    visibility: {
      options: [
        {
          label: 'All',
          value: 'none',
        },
        {
          label: 'Hidden',
          value: 'false',
        },
        {
          label: 'Visible',
          value: 'true',
        },
      ],
      default: [],
    },
  },
  [FilterScopes.WORKERS]: {
    created: {
      options: convertEnumToFilterOptions(CreatedFilters),
      default: [],
    },
    onboardingStatus: {
      options: convertEnumToFilterOptions(OnboardingStatusFilters),
      default: [],
    },
    tiers: {
      options: convertEnumToFilterOptions(WorkerTierOptions),
      default: [],
    },
    hasAddress: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
    hasExperience: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
    hasProfileImage: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
    hasWorked: {
      options: convertEnumToFilterOptions(WorkerHistoryOptions),
      default: [],
    },
    isBooked: {
      options: convertEnumToFilterOptions(WorkerBookedOptions),
      default: [],
    },
    flags: {
      options: convertEnumToFilterOptions(UserFlagType),
      default: [],
    },
    isFlagged: {
      options: convertEnumToFilterOptions(WorkerFlaggedOptions),
      default: [],
    },
    status: {
      options: convertEnumToFilterOptions({ all: 'ALL', ...UserStatus }),
      default: [],
    },
    ratingMax: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 5.0,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    ratingMin: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 5.0,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    priorityMax: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 3.1,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    priorityMin: {
      options: convertArrayToFilterOptions(
        createNumericIntervalOptions({
          start: 1.0,
          end: 3.1,
          interval: 0.1,
        }),
      ),
      default: [],
    },
    workedSince: {
      options: convertEnumToFilterOptions(LastWorkedFilters),
      default: [],
    },
    notWorkedSince: {
      options: convertEnumToFilterOptions(LastNotWorkedFilters),
      default: [],
    },
    shiftsCompletedMin: {
      options: convertArrayToFilterOptions([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
      ]),
      default: [],
    },
    shiftsCompletedMax: {
      options: convertArrayToFilterOptions([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
      ]),
      default: [],
    },
    canScheduleCall: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
    zipDistance: {
      options: convertArrayToFilterOptions([1, 3, 5, 7, 10, 15, 20, 25, 30]),
      default: [],
    },
    zipcode: {
      options: [], // Intentionally blank. Input field, not a select option
      default: [],
    },
    zipcodeMaxDistance: {
      options: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
      default: [],
    },
    zipcodeMinDistance: {
      options: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
      default: [],
    },
    hasReviews: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
    excludeAttributeDormant: {
      options: convertArrayToFilterOptions([true, false]),
      default: [],
    },
  },
  [FilterScopes.CAMPAIGNS]: {},
};
