import { applyContext, fetchObject, Queries } from 'helpers';
import { GQL } from 'types/src/routes';

export interface fetchPaperworkProps {
  user?: string;
  usersArray?: string[];
}

export const fetchPaperwork = async (
  data: fetchPaperworkProps,
  query = Queries.PAPERWORK_FOR_WORKER_QUERY,
  contextOverride?: string,
) =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'PAPERWORK_FOR_WORKER_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey: ['activePaperwork', 'archivedPaperwork'],
  });
