export const CREATE_NOTIFICATION_RULE_MUTATION = `
  mutation CREATE_UPDATE_NOTIFICATION_RULE_MUTATION (
    $userUuid: String!
    $ruleType: String
    $dates: [SimpleNotificationRuleInput]
    $daysOfWeek: [String]
    $deleteDates: Boolean
    $deleteDaysOfWeek: Boolean
  ) {
    createSimpleNotificationRule (
      userUuid: $userUuid
      ruleType: $ruleType
      dates: $dates
      daysOfWeek: $daysOfWeek
      deleteDates: $deleteDates
      deleteDaysOfWeek: $deleteDaysOfWeek
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
