import { _LOCATION_COMPLIANCE_FULL } from './location';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const _RETAILERS_PARAMS = `
  $email: String 
  $emails: [String]
  $hasContracts: Boolean
  $hasLocations: Boolean
  $limit: Int
  $uuid: String
  $uuids: [String]
  ${_PAGING_PARAMS}
`;

export const _RETAILER_VARIABLES = `
  email: $email
  emails: $emails
  hasContracts: $hasContracts
  hasLocations: $hasLocations
  limit: $limit
  uuid: $uuid
  uuids: $uuids
  ${_PAGING_VARIABLES}
`;

export const RETAILERS_LIST_QUERY = `
  query RETAILERS_LIST_QUERY(${_RETAILERS_PARAMS}) {
    retailers(${_RETAILER_VARIABLES}) {
      name
      uuid
      logoImage
    }
  }
`;

export const BRANDS_LIST_QUERY = `
  query RETAILERS_LIST_QUERY(${_RETAILERS_PARAMS}) {
    retailers(${_RETAILER_VARIABLES}) {
      name
      uuid
      logoImage
      brandImage
      linkWebsite
      linkInstagram
      linkTiktok
      locations {
        uuid
        name
        organization {
          name
          uuid
        }
      }
      customers {
        uuid
        name
      }
      accountOwner {
        uuid
        firstName
        lastName
        profileImg
      }
    }
  }
`;
