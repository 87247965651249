import { getEnumKeyByValue } from 'helpers';
import { ReviewStatus } from 'types';
import { CreatedFilters, FilterOption } from 'utils/types/filters';
import { dateCreatedParser } from './filters';

export const reviewsRatingParser = (ratingArray: Array<FilterOption>) => {
  if (!ratingArray.length) return undefined;
  return { rating: ratingArray.map(val => Number.parseInt(val.value)) };
};

export const reviewsPrioritizationParser = (
  prioritizationArray: Array<FilterOption>,
) => {
  if (!prioritizationArray.length) return undefined;
  return { prioritization: parseInt(prioritizationArray[0].value) };
};

export const reviewsHasCoachingParser = (
  hasCoachingArray: Array<FilterOption>,
) => {
  if (hasCoachingArray.length === 1) {
    return { hasCoaching: hasCoachingArray[0].value === 'true' };
  }
  return undefined;
};

export const reviewsHasFeedbackParser = (
  hasFeedbackArray: Array<FilterOption>,
) => {
  if (hasFeedbackArray.length === 1) {
    return { hasFeedback: hasFeedbackArray[0].value === 'true' };
  }
  return undefined;
};

export const reviewsStatusParser = (statusArray: Array<FilterOption>) => {
  if (!statusArray || statusArray.length === 0) return undefined;
  switch (statusArray[0].value) {
    // Type 'all' does not exist in backend. Backend returns all by default.
    case 'ALL':
      return undefined;
    default:
      return {
        status: statusArray.map(val => val.value) as ReviewStatus[],
      };
  }
};

export const reviewsCreatedSinceParser = (
  createdSinceFilters: Array<FilterOption>,
) => {
  if (!createdSinceFilters || !createdSinceFilters.length) return undefined;
  return {
    createdSince: dateCreatedParser(
      CreatedFilters[
        getEnumKeyByValue(CreatedFilters, createdSinceFilters[0].value)
      ],
    ).since,
  };
};

export const reviewsUpdatedSinceParser = (
  updatedSinceFilters: Array<FilterOption>,
) => {
  if (!updatedSinceFilters || !updatedSinceFilters.length) return undefined;
  return {
    updatedSince: dateCreatedParser(
      CreatedFilters[
        getEnumKeyByValue(CreatedFilters, updatedSinceFilters[0].value)
      ],
    ).since,
  };
};
