import { applyContext, fetchObject, Queries } from 'helpers';
import { GQL, ReflexResponse } from 'types';

export interface fetchCompliancesProps {
  name?: string;
  locations?: string[]; // location uuids
}

export const fetchCompliances = async (
  data: fetchCompliancesProps,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'COMPLIANCE_QUERY'),
    query: Queries.COMPLIANCE_QUERY,
    data,
    returnKey: ['compliances', 'compliances_paging'],
  });
