import { ActionMap, tag } from 'types';
import { convertEnumToFilterOptions } from 'utils/helpers/filters';
import { FilterOption, TimingFilters } from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

const statusOptions = [
  {
    label: 'All',
    value: 'none',
  },
  {
    label: 'Is Archived',
    value: 'true',
  },
  {
    label: 'Active',
    value: 'false',
  },
];

const visibilityOptions = [
  {
    label: 'All',
    value: 'none',
  },
  {
    label: 'Hidden',
    value: 'false',
  },
  {
    label: 'Visible',
    value: 'true',
  },
];

export enum EditTagsActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export type TagsFilterInterface = {
  times?: Array<FilterOption>;
  createdBy?: Array<FilterOption>;
  users?: Array<FilterOption>;
  status?: Array<FilterOption>;
  visibility?: Array<FilterOption>;
};

export interface TagsStateInterface {
  editingWorkers?: EditTagsActions;
  editingLocations?: EditTagsActions;
  editingShifts?: EditTagsActions;
  filters: TagsFilterInterface;
  formState: formState;
  selectedTags: Record<number, tag>;
  search?: string;
}

export const filterDefaults = {
  times: [],
  createdBy: [],
  users: [],
  status: [statusOptions[2]],
  visibility: [visibilityOptions[0]],
};

export const initialState = {
  editingWorkers: null,
  editingLocations: null,
  editingShifts: null,
  filterOptions: {
    timing: convertEnumToFilterOptions(TimingFilters),
    status: statusOptions,
    visibility: visibilityOptions,
  },
  filters: filterDefaults,
  formState: formStateDefaults,
  selectedTags: {},
  search: '',
};

export enum TagsActions {
  CHANGE_FILTER = 'CHANGE_TAGS_FILTER',
  RESET_FILTER = 'RESET_TAGS_FILTER',
  SELECT_TAG = 'SELECT_TAG',
  SELECT_TAGS = 'SELECT_TAGS',
  DESELECT_TAG = 'DESELECT_TAG',
  DESELECT_ALL = 'DESELECT_ALL_TAGS',
  UPDATE_EDITING_WORKERS = 'UPDATE_TAGS_IS_EDITING_WORKERS',
  UPDATE_EDITING_LOCATIONS = 'UPDATE_TAGS_IS_EDITING_LOCATIONS',
  UPDATE_EDITING_SHIFTS = 'UPDATE_TAGS_IS_EDITING_SHIFTS',
  UPDATE_FORM_STATE = 'UPDATE_TAG_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_TAG_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_TAG_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_TAG_FORM_ERRORS',
  UPDATE_SEARCH = 'UPDATE_TAGS_SEARCH',
}

type TagsPayload = {
  [TagsActions.CHANGE_FILTER]: Record<string, FilterOption>;
  [TagsActions.RESET_FILTER]: string;
  [TagsActions.SELECT_TAG]: Partial<tag>;
  [TagsActions.SELECT_TAGS]: Array<tag>;
  [TagsActions.DESELECT_TAG]: string; // id
  [TagsActions.DESELECT_ALL]: null;
  [TagsActions.UPDATE_EDITING_WORKERS]?: EditTagsActions;
  [TagsActions.UPDATE_EDITING_LOCATIONS]?: EditTagsActions;
  [TagsActions.UPDATE_EDITING_SHIFTS]?: EditTagsActions;
  [TagsActions.UPDATE_FORM_PROCESSING]: boolean;
  [TagsActions.UPDATE_FORM_SUCCESS]: boolean;
  [TagsActions.UPDATE_FORM_ERRORS]: Array<string>;
  [TagsActions.UPDATE_SEARCH]: string;
};

type TagsActionTypes = ActionMap<TagsPayload>[keyof ActionMap<TagsPayload>];

export const tagsReducer = (draft, action: TagsActionTypes) => {
  const { type } = action;
  switch (type) {
    case TagsActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case TagsActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case TagsActions.SELECT_TAG:
      draft.selectedTags[action?.payload.id] = action?.payload;
      break;
    case TagsActions.SELECT_TAGS:
      action?.payload.forEach(tag => {
        const key = tag.id;
        draft.selectedTags[key] = tag;
      });
      break;
    case TagsActions.DESELECT_TAG:
      delete draft.selectedTags[action?.payload];
      break;
    case TagsActions.DESELECT_ALL:
      draft.selectedTags = {};
      break;
    case TagsActions.UPDATE_EDITING_WORKERS:
      draft.editingWorkers = action?.payload;
      break;
    case TagsActions.UPDATE_EDITING_LOCATIONS:
      draft.editingLocations = action?.payload;
      break;
    case TagsActions.UPDATE_EDITING_SHIFTS:
      draft.editingShifts = action?.payload;
      break;
    case TagsActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case TagsActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case TagsActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    case TagsActions.UPDATE_SEARCH:
      draft.search = action?.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
