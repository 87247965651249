import { PaperworkStatus } from 'types';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';
import { _LOCATION_RETAILER } from './location';

const _USERS_PARAMS = `
  $excludeFavoriteAt: [String]
  $excludeHasWorkedAt: [String]
  $favoriteAt: [String]
  $hasWorked: Boolean
  $hasWorkedAt: [String]
  $locations: [String]
  $markets: [Int]
  $role: [String]
  $shiftsCompletedMax: Int
  $shiftsCompletedMin: Int
  $status: [String]
  $tier: [String]
  $uuid: String
  $uuids: [String]
  $organization: String
  $withAccessTo: [String]
`;
const _USERS_VARIABLES = `
  excludeFavoriteAt: $excludeFavoriteAt
  excludeHasWorkedAt: $excludeHasWorkedAt
  favoriteAt: $favoriteAt
  hasWorked: $hasWorked
  hasWorkedAt: $hasWorkedAt
  locations: $locations
  markets: $markets
  role: $role
  shiftsCompletedMax: $shiftsCompletedMax
  shiftsCompletedMin: $shiftsCompletedMin
  status: $status
  tier: $tier
  uuid: $uuid
  uuids: $uuids
  organization: $organization
  withAccessTo: $withAccessTo
`;

export const USERS_QUERY = `
  query USERS_QUERY(
    $uuid: String
    $uuids: [String]
    $role: [String]
    $status: [String]
  ) {
    users (
      uuid: $uuid
      uuids: $uuids
      role: $role
      status: $status
    ) { 
      uuid
      status
      lastLogin
      firstName
      lastName
      preferredName
      profileImg
      phoneNumber
      email
      isWorker
      isRetail
      isW2PayproviderComplete
      is1099PayproviderComplete
      isBackcheckComplete
      hoursThisWeek
      totalShiftsCompleted
      lastShiftDate
    }
  }
`;

export const USER_SELECT_QUERY = `
  query selectUsersQuery (
    $locations: [String]
    $markets: [Int]
    $role: [String]
    $shiftsCompletedMax: Int
    $shiftsCompletedMin: Int
    $status: [String]
    $tier: [String]
    $uuids: [String]
    $hasWorked: Boolean
    $favoriteAt: [String]
    $excludeFavoriteAt: [String]
    $hasWorkedAt: [String]
    $excludeHasWorkedAt: [String]
  ) {
    users (
      locations: $locations
      markets: $markets
      role: $role
      shiftsCompletedMax: $shiftsCompletedMax
      shiftsCompletedMin: $shiftsCompletedMin
      status: $status
      tier: $tier
      uuids: $uuids
      hasWorked: $hasWorked
      favoriteAt: $favoriteAt
      excludeFavoriteAt: $excludeFavoriteAt
      hasWorkedAt: $hasWorkedAt
      excludeHasWorkedAt: $excludeHasWorkedAt
    ) {
      uuid
    }
  }
`;

export const USER_BASIC_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      assignedTier
      birthdate
      firstName
      lastName
      phoneNumber
      preferredName
      profileImg
      textingLink
      status
    }
  }
`;

export const USER_CORE_INFO_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      id
      assignedTier
      birthdate
      firstName
      hoursThisWeek
      isStaff
      isRetail
      lastName
      phoneNumber
      preferredName
      profileImg
      pronouns
      status
      isFlagged
      tier
      isCurrentlyWorking
      markets {
        id
        name
        state
      }
      attributes
      notificationPreferences {
        isPushNotificationEnabled
      }
    }
  }
`;

export const USER_SHIFTS_QUERY = `
  query usersQuery($uuid: String!, $wsoStatus: [String]) {
    users(uuid: $uuid) {
      shifts(status: $wsoStatus){
        uuid
        status
        cancelClassification
        cancelNote
        shift {
          uuid
          start
          end
          status
          acceptedCount
          acceptLimit
          overbookLimit
          reviews {
            id
            prioritization
            recommended
            rating
            status
            reviewType
          }
          retailerLocation {
            name
            type
            timezone
            retailer {
              logoImage
              name
            }
          }
        }
      }
    }
  }
`;

export const USER_DANGER_ZONE_WSO_QUERY = `
query usersQuery($uuid: String!, $wsoStatus: [String]) {
  users(uuid: $uuid) {
    shifts(status: $wsoStatus){
      uuid
      status
      shift {
        end
      }
    }
  }
}
`;

export const USER_WORKER_BENEFITS_QUERY = `
query usersQuery($uuid: String!) {
  users(uuid: $uuid) {
    workerBenefits {
      availableBenefits
      benefitType
      totalBenefits
      uuid
      compliance {
        name
      }
    }
  }
}
`;

export const USER_ONBOARDING_QUERY = `
  query usersQuery(
    $uuid: String!) {
    users(uuid: $uuid) {
      status
      onboarding
      onboardingDate
      dateJoined
      onboardedBy {
        firstName
        lastName
        uuid
      }
      activatedBy {
        firstName
        lastName
        uuid
      }
      canScheduleCall
    }
  }
`;

export const USER_DETAILS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      aboutMe
      address {
        address1
        address2
        city
        state
        zip
      }
      birthdate
      email
      location
      phoneNumber
      isW2PayproviderComplete
      is1099PayproviderComplete
      referrer {
        code
        owner {
          firstName
          lastName
          preferredName
          uuid
        }
      }
      referralCode {
        code
        usedBy {
          firstName
          lastName
          preferredName
          uuid
        }
      }
    }
  }
`;

export const USER_METRICS_FOR_SHIFT_WIDGET_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      ratingAvg
      ratingAvgActual
      prioritizationAvg
      totalShiftsCompleted
      lastShiftDate
    }
  }
`;

export const USER_METRICS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      dateJoined
      hoursThisWeek
      lastLogin
      lastShiftDate
      ratingAvg
      ratingAvgActual
      ratingCount
      prioritizationAvg
      referralCode {
        code
        usedBy {
          firstName
          lastName
          preferredName
          uuid
        }
      }
      shifts {
        uuid
        status
        cancelClassification
        cancelNote
        cancellationPenalty
        shift {
          uuid
          start
          end
          isCancelled
          status
          retailerLocation {
            name
            type
            retailer {
              name
            }
          }
        }
      }
      totalShiftsCompleted
      flags {
        flagType
      }
      activeFlags {
        flagType
      }
      isFlagged
    }
  }
`;

export const USER_EXPERIENCE_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      experience {
        id
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
      }
    }
  }
`;

export const USER_NOTIFICATION_RULES_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      notificationRules {
        mon
        tue
        wed
        thu
        fri
        sat
        sun
        startDate
        endDate
        uuid
      }
    }
  }
`;

export const USER_RELATIONS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      firstName
      lastName
      preferredName
      markets {
        id
      }
      favoritedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      approvedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      blockedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      userBlocked {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
    }
  }
`;

export const USER_NOTES_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_EDIT_FORM_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      is1099PayproviderComplete
      isBackcheckComplete
      isI9Verified
      isW2PayproviderComplete
      onboardingDate
      onboarding
      status
      tier
      profileImg
      firstName
      lastName
      preferredName
      markets {
        id
        name
        state
      }
      onboardedBy {
        firstName
        lastName
        uuid
      }
      activatedBy {
        firstName
        lastName
        uuid
      }
      canScheduleCall
    }
  }
`;

export const USER_EDIT_PAPERWORK_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      is1099PayproviderComplete
      isBackcheckComplete
      isI9Verified
      isW2PayproviderComplete
    }
  }
`;

export const USER_UUID_QUERY = `
  query usersQuery($uuid: String, $id: Int) {
    users(uuid: $uuid, id: $id) {
      uuid
      id
      isRetail
      isWorker
    }
  }
`;

export const USER_TAGS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      profileImg
      firstName
      lastName
      preferredName
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const USER_FLAGS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      isFlagged
      flags {
        uuid
        flagType
        status
        blocking
        expiresAt
        flaggedNote
        resolutionNote
        flaggedBy {
          uuid
          firstName
          lastName
        }
        createdAt
        resolvedBy {
          uuid
          firstName
          lastName
        }
        resolvedAt
      }
    }
  }
`;

export const USER_INTEGRATION_IDS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      integrationIds {
        integration
        externalId
        integrationState
        updatedAt
        lastModifiedBy {
          firstName
        }
      }
    }
  }
`;

export const USER_POPUP_SUMMARY_QUERY = `
  query USER_POPUP_SUMMARY_QUERY($uuid: String!) {
    users(uuid: $uuid) {
      firstName
      lastName
      preferredName
      profileImg
      phoneNumber
      tier
      assignedTier
      status
      ratingAvgActual
      prioritizationAvg
      lastShiftDate
      totalShiftsCompleted
      textingLink
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      isFlagged
    }
  }
`;

export const USER_EMPLOYMENT_REQUIREMENTS_QUERY = `
  query USER_EMPLOYMENT_REQUIREMENTS_QUERY (
    $user: String!
  ) {
    users (uuid: $user) {
      uuid
      employmentRequirements {
        id
        requirementType
        state
        expiresAt
        externalLink
        logs {
          id
          note
          createdAt
          user {
            firstName
            lastName
          }
          meta
        }
      }
    }
    activePaperwork: paperwork (
      status: "${PaperworkStatus.ACTIVE}"
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
        logs {
          id
          note
          createdAt
          user {
            firstName
            lastName
          }
          meta
        }
      }
    }
    archivedPaperwork: paperwork (
      status: "${PaperworkStatus.ARCHIVED}"
      user: $user
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
      }
    }
  }
`;

export const USER_NOTIFICATION_PREFERENCES_QUERY = `
  query USERS_QUERY($uuids: [String]) {
    users (uuids: $uuids) { 
      notificationPreferences {
        isPushNotificationEnabled
      }
    }
  }
`;

// Queries that relate to the Worker details page
export const WorkerQueries = {
  uuid: USER_UUID_QUERY,
  basic: USER_BASIC_QUERY,
  coreInfo: USER_CORE_INFO_QUERY,
  shifts: USER_SHIFTS_QUERY,
  onboarding: USER_ONBOARDING_QUERY,
  details: USER_DETAILS_QUERY,
  metrics: USER_METRICS_QUERY,
  metricsForShiftWidget: USER_METRICS_FOR_SHIFT_WIDGET_QUERY,
  experience: USER_EXPERIENCE_QUERY,
  notifications: USER_NOTIFICATION_RULES_QUERY,
  relations: USER_RELATIONS_QUERY,
  notes: USER_NOTES_QUERY,
  editForm: USER_EDIT_FORM_QUERY,
  editPaperwork: USER_EDIT_PAPERWORK_QUERY,
  tags: USER_TAGS_QUERY,
  flags: USER_FLAGS_QUERY,
  integrationIds: USER_INTEGRATION_IDS_QUERY,
  popupSummaryQuery: USER_POPUP_SUMMARY_QUERY,
  employmentRequirements: USER_EMPLOYMENT_REQUIREMENTS_QUERY,
  dangerZoneWSOQuery: USER_DANGER_ZONE_WSO_QUERY,
  workerBenefitsQuery: USER_WORKER_BENEFITS_QUERY,
};

export const ACTIVATE_USERS_LIST_QUERY = `
  query ACTIVATE_USERS_LIST_QUERY(
    $hasAddress: Boolean
    $hasExperience: Boolean
    $hasProfileImage: Boolean
    $markets: [Int]
    $since: Date
    $status: [String]
    $tags: [Int]
    $tagsMissing: [Int]
    $tier: [String]
    $to: Date
    $zipcode: String
    $zipcodeMaxDistance: Float
    $zipcodeMinDistance: Float

    $pageSize: Int
    $page: Int
    $search: String
    $orderBy: String
  ) {
    users (
      hasAddress: $hasAddress
      hasExperience: $hasExperience
      hasProfileImage: $hasProfileImage
      markets: $markets
      since: $since
      status: $status
      tags: $tags
      tagsMissing: $tagsMissing
      tier: $tier
      to: $to
      zipcode: $zipcode
      zipcodeMinDistance: $zipcodeMinDistance
      zipcodeMaxDistance: $zipcodeMaxDistance
      
      pageSize: $pageSize
      page: $page
      search: $search
      orderBy: $orderBy
    ) { 
      uuid
      firstName
      lastName
      preferredName
      profileImg
      tier
      assignedTier
      aboutMe
      dateJoined
      onboarding
      status
      isRecentlyActive
      totalShiftsCompleted
      markets {
        name
        id
      }
      experience {
        id
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
      }
      referrer {
        code
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

// FUTURE: figure out if we can trim any props
interface UsersListQueryProps {
  includeRelations?: boolean;
  includeExperience?: boolean;
  includeOnboarding?: boolean;
  includeExtras?: boolean;
  includeStats?: boolean;
  includeRating?: boolean;
  includeTags?: boolean;
  includeNotificationPreferences?: boolean;
}

const UsersListPropsDefaults = {
  includeRelations: false,
  includeExperience: false,
  includeOnboarding: false,
  includeExtras: false,
  includeStats: false,
  includeRating: false,
  includeTags: false,
  includeNotificationPreferences: false,
};

export const USERS_LIST_QUERY = (
  props: UsersListQueryProps = UsersListPropsDefaults,
) => {
  const {
    includeRelations,
    includeExperience,
    includeOnboarding,
    includeExtras,
    includeStats,
    includeRating,
    includeTags,
    includeNotificationPreferences,
  } = props;
  return `
    query USERS_LIST_QUERY(
      $market: Int
      $markets: [Int]
      $flags: [String]
      $isFlagged: Boolean
      $uuid: String
      $uuids: [String]
      $role: [String]
      $tier: [String]
      $status: [String]
      $hasAddress: Boolean
      $hasExperience: Boolean
      $hasProfileImage: Boolean
      $hasWorked: Boolean
      $isBooked: Boolean
      $retailers: [String]
      $locations: [String]
      $since: Date
      $to: Date
      $metabaseQuestion: String
      $tags: [Int]
      $tagsMissing: [Int]
      $onboardingStatus: String
      $onboardedBy: [String]
      $activatedBy: [String]
      $reviewRatingAvgMax: Float
      $reviewRatingAvgMin: Float
      $reviewPrioritizationAvgMax: Float
      $reviewPrioritizationAvgMin: Float
      $shiftsCompletedMin: Int
      $shiftsCompletedMax: Int
      $workedSince: Date
      $notWorkedSince: Date
      $canScheduleCall: Boolean
      $pageSize: Int
      $page: Int
      $search: String
      $orderBy: String
      $excludeShifts: [String]
      $excludeAttributeDormant: Boolean
      $zipcode: String
      $zipcodeMinDistance: Float
      $zipcodeMaxDistance: Float
      $acquisitionCampaigns: [String]
      $attributionCodes: [String]
    ) {
      users(
        hasAddress: $hasAddress
        hasExperience: $hasExperience
        hasProfileImage: $hasProfileImage
        hasWorked: $hasWorked
        isBooked: $isBooked
        market: $market
        markets: $markets
        flags: $flags
        isFlagged: $isFlagged
        uuid: $uuid
        uuids: $uuids
        role: $role
        tier: $tier
        status: $status
        retailers: $retailers
        locations: $locations
        since: $since
        to: $to
        metabaseQuestion: $metabaseQuestion
        tags: $tags
        tagsMissing: $tagsMissing
        onboardingStatus: $onboardingStatus
        onboardedBy: $onboardedBy
        activatedBy: $activatedBy
        reviewRatingAvgMax: $reviewRatingAvgMax
        reviewRatingAvgMin: $reviewRatingAvgMin
        reviewPrioritizationAvgMax: $reviewPrioritizationAvgMax
        reviewPrioritizationAvgMin: $reviewPrioritizationAvgMin
        shiftsCompletedMin: $shiftsCompletedMin
        shiftsCompletedMax: $shiftsCompletedMax
        workedSince: $workedSince
        notWorkedSince: $notWorkedSince
        canScheduleCall: $canScheduleCall
        pageSize: $pageSize
        page: $page
        all: ["uuid"]
        search: $search
        orderBy: $orderBy
        excludeShifts: $excludeShifts
        excludeAttributeDormant: $excludeAttributeDormant
        zipcode: $zipcode
        zipcodeMinDistance: $zipcodeMinDistance
        zipcodeMaxDistance: $zipcodeMaxDistance
        acquisitionCampaigns: $acquisitionCampaigns
        attributionCodes: $attributionCodes
      ) { 
        uuid
        firstName
        lastName
        preferredName
        profileImg
        email
        isWorker
        isW2PayproviderComplete
        is1099PayproviderComplete
        isBackcheckComplete
        textingLink
        tier
        phoneNumber
        assignedTier
        status
        markets {
          name
          id
        }
        flags {
          flagType
        }
        isFlagged
        ${
          includeRelations
            ? `
          favoritedBy { uuid }
          approvedBy { uuid }
          blockedBy { uuid }
        `
            : ''
        }
        ${
          includeExperience
            ? `
          experience {
            id
            retailer {
              name
              logoImage
            }
            name
            location
            start
            end
          }
        `
            : ''
        }
        ${
          includeOnboarding
            ? `
          onboarding
          onboardingDate
          referrer {
            code
          }
          dateJoined
          canScheduleCall
        `
            : ''
        }
        ${
          includeExtras
            ? `
          birthdate
          preferredName
          pronouns
          language
          location
          aboutMe
        `
            : ''
        }
        ${
          includeStats
            ? `
          hoursThisWeek
          totalShiftsCompleted
          lastShiftDate
        `
            : ''
        }
        ${
          includeRating
            ? `
          ratingAvg
          ratingCount
        `
            : ''
        }
        ${
          includeTags
            ? `
          tags {
            id
            name
            isListVisible
          }
        `
            : ''
        }
        ${
          includeNotificationPreferences
            ? `
          notificationPreferences {
            isPushNotificationEnabled
          }
        `
            : ''
        }
      }
    }
  `;
};

export const USERS_ONBOARDINGS_QUERY = `
  query USERS_ONBOARDINGS_QUERY (
    $status: [String]
    $markets: [Int]
    $tier: [String]
    $since: Date
    $to: Date
    $onboardedBy: [String]
    $activatedBy: [String]
  ) {
    users (
      status: $status
      markets: $markets
      tier: $tier
      since: $since
      to: $to
      onboardedBy: $onboardedBy
      activatedBy: $activatedBy
    ) {
      firstName
      lastName
      preferredName
      dateJoined
      onboardingDate
      uuid
      profileImg
      phoneNumber
      tier
      assignedTier
      onboarding
      referrer {
        code
      }
      markets {
        name
        state
      }
      onboardedBy {
        firstName
        lastName
        uuid
      }
      activatedBy {
        firstName
        lastName
        uuid
      }
    }
  }
`;

export const STAFF_USERS_LIST_QUERY = `
  query STAFF_USERS_LIST_QUERY (
    $status: [String]
    $role: [String]
    ${_PAGING_PARAMS}
  ) {
    users (
      status: $status
      role: $role
      ${_PAGING_VARIABLES}
    ) {
      firstName
      lastName
      uuid
    }
  }
`;

export const SHIFT_DETAILS_PAGE_SHIFT_WORKERS_SUBQUERY = (
  shift_uuid,
  location_uuid,
) => `
  query SHIFT_DETAILS_PAGE_SHIFT_WORKERS_SUBQUERY (
    $uuids: [String]
    $tags: [Int]
    $tagsMissing: [Int]
    $tier: [String]
    $reviewRatingAvgMax: Float
    $reviewRatingAvgMin: Float
    $reviewPrioritizationAvgMax: Float
    $reviewPrioritizationAvgMin: Float
    $shiftsCompletedMin: Int
    $shiftsCompletedMax: Int
    $zipcode: String
    $zipcodeMinDistance: Float
    $zipcodeMaxDistance: Float
  ) {
    users (
      uuids: $uuids
      locationUuid: "${location_uuid}"
      tags: $tags
      tagsMissing: $tagsMissing
      tier: $tier
      reviewRatingAvgMax: $reviewRatingAvgMax
      reviewRatingAvgMin: $reviewRatingAvgMin
      reviewPrioritizationAvgMax: $reviewPrioritizationAvgMax
      reviewPrioritizationAvgMin: $reviewPrioritizationAvgMin
      shiftsCompletedMin: $shiftsCompletedMin
      shiftsCompletedMax: $shiftsCompletedMax
      zipcode: $zipcode
      zipcodeMinDistance: $zipcodeMinDistance
      zipcodeMaxDistance: $zipcodeMaxDistance
    ) {
      uuid
      firstName
      lastName
      preferredName
      email
      profileImg
      isW2PayproviderComplete
      is1099PayproviderComplete
      isBackcheckComplete
      isI9Verified
      hoursThisWeek
      totalShiftsCompleted
      tier
      isRecentlyActive
      hasWorkedAtLocation
      notificationPreferences {
        isPushNotificationEnabled
      }
      cohort(shift: "${shift_uuid}") {
        name
        uuid
        definition
        createdAt
        createdBy {
          uuid
          firstName
          lastName
        }
      }
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      attributes
    }
  }
`;

export const USER_ME_ESSENTIAL = `
  query usersQuery {
    me {
      uuid
      email
      isRetail
      isWorker
      isSupport
      isStaff
      status
      requiresPasswordUpdate
      permissionTypes
      organization { uuid }
      firstName
      lastName
      profileImg
      locationPermissionsMap
      hasMultipleBrands
    }
  }
`;

// FUTURE: add customer -> terms -> documents:
// - signatureRequired
// - signed
// - signedBy -> uuid
// in order to re-enable customer self-service
// (deprecated 01.18.2024)
export const USER_ME_RETAILER_HOME = `
  query usersQuery { me { uuid } }
`;

export const USER_ME_RETAILER_APPROVED_LOCATIONS = `
  query usersQuery {
    me {
      uuid
      approvedLocations {
        uuid
        name
        retailer {
          name
        }
      }
    }
  }
`;

export const USER_ME_RETAILER_LOCATION_PERMISSIONS = `
  query usersQuery {
    me {
      uuid
      locationPermissions {
        location {
          uuid
          name
          retailer {
            name
            uuid
            logoImage
          }
        }
        permissionType
      }
      locationPermissionsMap 
    }
  }
`;

export const USER_ME_HAS_OUTSTANDING_BUDGET_APPROVALS = `
  query usersQuery {
    me {
      uuid
      hasOutstandingBudgetApprovals
    }
  }
`;

export const USER_ME_RETAILER_LOCATION_PERMISSIONS_FOR_REQUEST_FORM = `
  query usersQuery {
    me {
      uuid
      locationPermissions {
        location {
          uuid
          name
          timezone
          specialRequestsAvailable
          status
          retailer {
            name
            uuid
            logoImage
          }
          compliance {
            name
            shiftStartDelta
            shiftGapDelta
            maxShiftsPerDay
            maxHoursPerDay
            shiftMaxLength
            shiftMinLength
            preFlexViewDisclaimers {
              name
            }
            preFlexClaimDisclaimers {
              name
            }
            requiredWorkerPaperwork {
              name
            }
            breakRules {
              shiftMinutesToRequireBreak
              breakLength
              paid
              requiresTimestamps
            }
          }
          myPermissions
        }
        permissionType
      }
    }
  }
`;

export const USER_ME_IDENTITY_EVENT_DATA = `
  query usersQuery {
    me {
      uuid
      firstName
      lastName
      email
      id
      profileImg
      phoneNumber
      status
      onboardingDate
      eritId
      isTextSubscribed
      isWorker
      isSupport
      isRetail
      markets {
        name
      }
    }
  }
`;

export const USER_PROFILE_FOR_RETAILER = `
  query USER_PROFILE_FOR_RETAILER (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      firstName
      lastName
      preferredName
      tagline
      uuid
      profileImg
      favoritedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      approvedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      blockedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      experience {
        id
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
      }
      aboutMe
      hoursThisWeek
      totalShiftsCompleted
      lastShiftDate
      ratingAvg
      ratingCount
      allBrands {
        name
        logoImage
      }
      platformExperienceBrands {
        name
        logoImage
      }
      workerEndorsements {
        name
        count
        priority
        icon
      }
      workerGrowthAreas {
        name
        count
        priority
        icon
      }

    }
  }
`;

export const WORKER_PROFILE_FOR_RETAILER_QUERY = `
  query WORKERS_QUERY(
    $uuid: String, 
    $locations: [String], 
    ${_PAGING_PARAMS}
  ) {
    users(
      uuid: $uuid, 
      locations: $locations, 
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      lastShiftAtLocations {
        start
        retailerLocation {
          uuid
          name
          timezone
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
    }
  }
`;

export const WORKERS_QUERY = `
  query WORKERS_QUERY(
    $uuid: String
    $uuids: [String]
    $organization: String
    $locations: [String]
    $hasWorked: Boolean
    $hasLocationRelation: Boolean
    $favoriteAt: [String]
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $workedAtLocationsSince: Date
    ${_PAGING_PARAMS}
  ) {
    users(
      uuid: $uuid
      uuids: $uuids
      organization: $organization
      locations: $locations
      hasWorked: $hasWorked
      hasLocationRelation: $hasLocationRelation
      favoriteAt: $favoriteAt
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      workedAtLocationsSince: $workedAtLocationsSince
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      isRetail
      preferredName
      profileImg
      eritId
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      totalShiftsCompleted
      lastShiftDate
      lastDateWorkedAtLocation
      relationLocations {
        uuid
      }
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
      lastShiftAtLocationsWithoutRelation {
        start
        end
        retailerLocation {
          uuid
          name
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
      totalShiftsAtLocation
    }
  }
`;

// FUTURE: move fetch users graph params and vars to a shared variable
export const RETAILER_SELECT_WORKERS_QUERY = `
  query RETAILER_SELECT_WORKERS_QUERY(
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $favoriteAt: [String]
    $hasLocationRelation: Boolean
    $hasWorked: Boolean
    $locations: [String]
    ${_PAGING_PARAMS}
  ) {
    users(
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      favoriteAt: $favoriteAt
      hasLocationRelation: $hasLocationRelation,
      hasWorked: $hasWorked
      locations: $locations 
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      profileImg
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      lastShiftDate
      lastDateWorkedAtLocation
      totalShiftsAtLocation
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
        }
      }
    }
  }
`;

export const OZ_RETAILER_SPECIAL_REQUEST_WORKERS_QUERY = `
  query RETAILER_SELECT_WORKERS_QUERY(
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $favoriteAt: [String]
    $hasLocationRelation: Boolean
    $hasWorked: Boolean
    $locations: [String]
    $uuids: [String]
    ${_PAGING_PARAMS}
  ) {
    users(
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      favoriteAt: $favoriteAt
      hasLocationRelation: $hasLocationRelation,
      hasWorked: $hasWorked
      locations: $locations 
      uuids: $uuids
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      profileImg
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      lastShiftDate
      lastDateWorkedAtLocation
      totalShiftsAtLocation
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
        }
      }
    }
  }
`;

const _PERMISSION_USERS_CORE = `
  uuid
  firstName
  lastName
  phoneNumber
  email
  preferredName
  profileImg
  pronouns
  status
  title
`;

export const RETAILER_PERMISSION_SETTINGS_USERS = `
  query usersQuery (
    ${_USERS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      ${_PERMISSION_USERS_CORE}
      organization {
        uuid
        name
      }
      locationsWithAccess {
        uuid
        name
        ${_LOCATION_RETAILER}
      }
    }
  }
`;

export const RETAILER_USERS_OZ = `
  query usersQuery (
    ${_USERS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      ${_PERMISSION_USERS_CORE}
      isRetail
      isWorker
      isSupport
      lastLogin
      organization {
        uuid
        name
      }
      locationsWithAccess {
        uuid
        name
        ${_LOCATION_RETAILER}
      }
    }
  }
`;

export const RETAILER_PERMISSION_SETTINGS_USERS_EXPANDED = `
  query usersQuery (
    ${_USERS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      ${_PERMISSION_USERS_CORE}
      phoneNumber
      email
      locationPermissions {
        location { uuid }
        permissionType
      }
    }
  }
`;

export const ORGANIZATION_USERS_QUERY = `
  query usersQuery (
    ${_USERS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      ${_PERMISSION_USERS_CORE}
      locationsWithAccess {
        uuid
        name
        ${_LOCATION_RETAILER}
      }
    }
  }
`;

export const WORKER_DANGER_ZONE_QUERY = `
  query usersQuery (
    ${_USERS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      uuid
      email
    }
  }
`;
