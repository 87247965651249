export const FETCH_SHIFT_COHORTS_QUERY = `
  query FETCH_SHIFT_COHORTS_QUERY($shifts: [String]) {
    cohorts(shifts: $shifts) {
      name
      uuid
      definition
    }
  }
`;

export const FIND_COHORT_USERS_QUERY = `
  query FIND_COHORT_USERS_QUERY(
    $cohortDefinition: CohortInput
    $shift: String
  ) {
    findCohortUsers(
      cohortDefinition: $cohortDefinition
      shift: $shift
      ) {
      count
      users {
        firstName
      }
    }
  }
`;
