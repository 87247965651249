import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const COMPLIANCE_QUERY = `
  query COMPLIANCE_QUERY (${_PAGING_PARAMS}) {
    compliances (
      ${_PAGING_VARIABLES}
    ) {
      name
      id
    }
  }
`;
