export const UPDATE_USER_PAPERWORK = `
  mutation UPDATE_USER_PAPERWORK(
    $uuid: String!
    $completed: DateTime
  ) {
    updateUserPaperwork (
      uuid: $uuid
      completed: $completed
    ) {
      ok
      uuid
      messages {
        code
        description
      }
    }
  }
`;

export const SEND_PAPERWORK_MUTATION = `
  mutation SEND_PAPERWORK_MUTATION (
    $users: [String]
    $paperworks: [String]
  ) {
    sendPaperwork(
      users: $users
      paperworks: $paperworks
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
