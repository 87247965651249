export type SortMap<T> = Partial<{ [value in keyof T]: SortState }>; // T is SortOptionsTypes

export enum SortState {
  DESC = 'DESC',
  ASC = 'ASC',
  NONE = 'NONE',
}

// FUTURE: generic for SortOptionsType, SortMap
// export const SortOptions = {
//   updated_at: 'Updated',
//   created_at: 'Shift Date',
// } as const;
// export type SortOptionsType = keyof typeof SortOptions;
// export type SortMap = Partial<{ [value in SortOptionsType]: SortState }>
