export const TAGS_LIST_QUERY = `
  query TAGS_LIST_QUERY (
    $id: Int
    $name: String
    $createdSince: Date
    $createdTo: Date
    $createdBy: [String]
    $users: [String]
    $isArchived: Boolean
    $isSystem: Boolean
    $isListVisible: Boolean
  ) {
    tags (
      id: $id
      name: $name
      createdSince: $createdSince
      createdTo: $createdTo
      createdBy: $createdBy
      users: $users
      isArchived: $isArchived
      isSystem: $isSystem
      isListVisible: $isListVisible
    ) {
      id
      name
    }
  }
`;

export const TAGS_FULL_QUERY = `
  query TAGS_FULL_QUERY (
    $id: Int
    $name: String
    $createdSince: Date
    $createdTo: Date
    $createdBy: [String]
    $users: [String]
    $isArchived: Boolean
    $isSystem: Boolean
    $isListVisible: Boolean
  ) {
    tags (
      id: $id
      name: $name
      createdSince: $createdSince
      createdTo: $createdTo
      createdBy: $createdBy
      users: $users
      isArchived: $isArchived
      isSystem: $isSystem
      isListVisible: $isListVisible
    ) {
      id
      name
      description
      updatedAt
      createdAt
      createdBy {
        uuid
        firstName
        lastName
      }
      isArchived
      isSystem
      isListVisible
      userCount
    }
  }
`;
