import { apiRetailer, apiRetailerLocationLight } from 'types';

type ReflexTag = 'form_retailer' | 'form_worker';

export enum UserPreferencesWeb {
  REPORTS_SUMMARY_CARD_TIMING = 'retailer_reports_summary_card_default_timing',
  REPORTS_PAGE_TIMING = 'retailer_reports_page_default_timing',
  LOCATION_NAME_DISPLAY_RETAILER = 'retailer_location_name_display_retailer', // FUTURE: remove
}

export interface ReflexStoreWeb {
  user?: ReflexStoreUser;
  meta?: ReflexStoreMeta;
  tags?: Array<ReflexTag>;
  preferences?: Record<UserPreferencesWeb | string, unknown>;
}

export interface ReflexStoreMeta {
  code?: string;
  attributionId?: Record<string, string>;
  last_source?: string;
  last_channel?: string;
  last_medium?: string;
  last_content?: string;
  last_term?: string;
  pageHistory?: Record<string, string>;
  lastIdentifyEvent?: string;
  lastPage?: string;
}

export interface ReflexStoreUser {
  uuid?: string;
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  role?: string;
  retailer?: apiRetailer;
  phone?: string;
  email?: string;
  location?: string;
  language?: string;
  about?: string;
  color?: string;
  approvedLocations?: Array<apiRetailerLocationLight>;
}
