export const CREATE_ORGANIZATION_MUTATION = `
  mutation CREATE_ORGANIZATION_MUTATION (
    $name: String
    $parentOrganization: String
  ) {
    createOrganization(
      name: $name
      parentOrganization: $parentOrganization
    ) {
      ok
      messages { code }
      uuid
    }
  }
`;
