export const cookie = {
  /**
   * @function get
   * @summary fetch cookie by name if cookie exists
   * @param {string} cookieName cookie to search for
   * @returns {string} value of cookie if cookie exists, else empty string.
   */
  get: (cookieName: string) => {
    if (typeof window === 'undefined') return;
    const cookies = cookie.getAll();
    if (cookies[cookieName]) {
      return cookies[cookieName];
    }
    return '';
  },

  /**
   * @function set
   * @summary create a new cookie if cookie does not exist (No clobber)
   * @param {Object} object where key is cookie name and value is cookie values
   * @param {string} expires: Optional. Denotes an expiry date/time for the cookie. Defaults to session duration if not provided.
   * @returns {Object} All document cookies in key/value format.
   */
  set: (cookieObj, expires = undefined) => {
    if (typeof window === 'undefined') return;
    const key = Object.keys(cookieObj)[0];
    const value = Object.values(cookieObj)[0];
    document.cookie = `${key}=${value}${expires ? `;expires=${expires}` : ''}`;
    return cookie.getAll();
  },

  /**
   * @function clear
   * @summary remove a cookie from document
   * @param {String} cookieName name of cookie to delete
   * @returns {void}
   */
  destroy: cookieName => {
    if (typeof window === 'undefined') return;
    // FUTURE: handle multiple paths
    document.cookie = `${cookieName}=; path=/; expires=Thu, 1 Jan 1970 00:00:01 GMT; domain=${document.domain}`;
  },

  /**
   * @function getAll
   * @summary fetch all cookies in document.cookie
   * @param {null}
   * @returns {Object} Object where key is cookie name and value is cookie value
   */
  getAll: (): { [key: string]: string } => {
    if (typeof window === 'undefined') return;
    const output = {};
    document.cookie
      .split('; ')
      // FUTURE: handle '=' characters better if '=' in cookie value string
      .map(cookie => cookie.split('='))
      .forEach((pair: Array<string>) => {
        output[pair[0]] = pair[1];
      });
    return output;
  },
};
