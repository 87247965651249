import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  EmploymentRequirementState,
  EmploymentRequirementType,
  GQL,
} from 'types';

export interface fetchEmploymentRequirementsData {
  user?: string;
  usersArray?: string[];
}

export const fetchEmploymentRequirements = async (
  data: fetchEmploymentRequirementsData,
  query = Queries.USER_EMPLOYMENT_REQUIREMENTS_QUERY,
  contextOverride?: string,
) =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'USER_EMPLOYMENT_REQUIREMENTS_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey: ['users', 'activePaperwork', 'archivedPaperwork'],
  });

export interface mutateSendEmploymentRequirementProps {
  users: string[];
  requirementType: EmploymentRequirementType;
}

export const mutateSendEmploymentRequirement = async (
  data: mutateSendEmploymentRequirementProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'SEND_EMPLOYMENT_REQUIREMENT_MUTATION'),
    query: Mutations.SEND_EMPLOYMENT_REQUIREMENT_MUTATION,
    data,
    returnKey: 'sendEmploymentRequirement',
  });

export interface mutateEditEmploymentRequirementProps {
  requirements: number[];
  state?: EmploymentRequirementState;
  expiresAt?: Date;
  log?: string;
}

export const mutateEditEmploymentRequirement = async (
  data: mutateEditEmploymentRequirementProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_EMPLOYMENT_REQUIREMENT_MUTATION'),
    query: Mutations.EDIT_EMPLOYMENT_REQUIREMENT_MUTATION,
    data,
    returnKey: 'updateEmploymentRequirement',
  });
