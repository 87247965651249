import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _CUSTOMER_PARAMS = `
  $uuid: String
  $uuids: [String]
  $accountOwner: String
  $retailers: [String]
  $hasTerms: Boolean
  ${_PAGING_PARAMS}
`;

const _CUSTOMER_VARIABLES = `
  uuid: $uuid
  uuids: $uuids
  accountOwner: $accountOwner
  retailers: $retailers
  hasTerms: $hasTerms
  ${_PAGING_VARIABLES}
`;

export const CUSTOMERS_QUERY = `
  query CUSTOMER_QUERY (${_CUSTOMER_PARAMS}) {
    customers (${_CUSTOMER_VARIABLES}) {
      name
      isActive
      uuid
      externalCustomerBillingId
      accountOwner {
        firstName
        lastName
        uuid
      }
      terms {
        uuid
        billingRate
        name
        activeFrom
        activeTo
        autoRenew
        billingUrgentBookingType
        billingUrgentBookingFee
        billingRate
        billingSchedule
        billingType
        billingMethod
        billingExtras
        paymentMethod {
          name
        }
        locations {
          name
          uuid
        }
        documents {
          name
          signed
          signedBy {
            firstName
            lastName
          }
        }
        cancellationFee {
          amounts {
            amount
            timing
            unit
          }
        }
      }
      paymentMethods {
        name
      }
      admins {
        firstName
        lastName
        uuid
      }
    }
  }
`;
