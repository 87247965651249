import { add, format } from 'date-fns';
import { CalendarValues } from 'ui';
import {
  CreatedFilters,
  FilterOption,
  TimingFilters,
} from 'utils/types/filters';

interface dateFilterObject {
  since: string; // "YYYY-MM-DD",
  to: string; // "YYYY-MM-DD",
}

/**
 * @function dateParser
 * @summary convert timing strings ('today', 'next7', etc) into objects for GQL queries
 * @param {TimingFilters} timePeriod one of options in TimingFilters
 * @return {dateFilterObject}
 */
export const dateParser = (timePeriod: TimingFilters): dateFilterObject => {
  const replaceSlashesWithDashes = (input: string) => input.replace(/\//g, '-');
  const yesterday = replaceSlashesWithDashes(
    format(add(new Date(), { days: -1 }), 'yyyy/MM/dd'),
  );
  const today = replaceSlashesWithDashes(format(new Date(), 'yyyy/MM/dd'));
  const tomorrow = replaceSlashesWithDashes(
    format(add(new Date(), { days: 1 }), 'yyyy/MM/dd'),
  );
  const next3 = replaceSlashesWithDashes(
    format(add(new Date(), { days: 3 }), 'yyyy/MM/dd'),
  );
  const next7 = replaceSlashesWithDashes(
    format(add(new Date(), { days: 7 }), 'yyyy/MM/dd'),
  );
  const next14 = replaceSlashesWithDashes(
    format(add(new Date(), { days: 14 }), 'yyyy/MM/dd'),
  );
  const next30 = replaceSlashesWithDashes(
    format(add(new Date(), { days: 30 }), 'yyyy/MM/dd'),
  );
  const last3 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -3 }), 'yyyy/MM/dd'),
  );
  const last7 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -7 }), 'yyyy/MM/dd'),
  );
  const last14 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -14 }), 'yyyy/MM/dd'),
  );
  const last30 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -30 }), 'yyyy/MM/dd'),
  );
  const last60 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -60 }), 'yyyy/MM/dd'),
  );
  const last90 = replaceSlashesWithDashes(
    format(add(new Date(), { days: -90 }), 'yyyy/MM/dd'),
  );
  // WEEK RUNS MON-SUN, INSTEAD OF SUN-SAT. To do regular weeks, get rid of the +1 offset
  const startOfWeek = replaceSlashesWithDashes(
    format(add(new Date(), { days: -new Date().getDay() + 1 }), 'yyyy/MM/dd'),
  );
  const endOfWeek = replaceSlashesWithDashes(
    format(
      add(new Date(), { days: 7 - new Date().getDay() + 1 }),
      'yyyy/MM/dd',
    ),
  );
  const startOfPrevWeek = replaceSlashesWithDashes(
    format(
      add(new Date(), { days: -new Date().getDay() + 1 - 7 }),
      'yyyy/MM/dd',
    ),
  );
  const endOfPrevWeek = replaceSlashesWithDashes(
    format(
      add(new Date(), { days: 7 - new Date().getDay() + 1 - 7 }),
      'yyyy/MM/dd',
    ),
  );
  // Offset by 1 to start on 1st
  const startOfMonth = replaceSlashesWithDashes(
    format(add(new Date(), { days: -new Date().getDate() + 1 }), 'yyyy/MM/dd'),
  );
  const endOfMonth = replaceSlashesWithDashes(
    format(
      add(new Date(), {
        days: -new Date().getDate(),
        months: 1,
      }),
      'yyyy/MM/dd',
    ),
  );
  const startOfPrevMonth = replaceSlashesWithDashes(
    format(
      add(new Date(), {
        days: -new Date().getDate() + 1,
        months: -1,
      }),
      'yyyy/MM/dd',
    ),
  );
  const endOfPrevMonth = replaceSlashesWithDashes(
    format(add(new Date(), { days: -new Date().getDate() }), 'yyyy/MM/dd'),
  );

  switch (timePeriod) {
    case TimingFilters.TODAY:
      return {
        since: today,
        to: today,
      };
    case TimingFilters.TODAY_YESTERDAY:
      return {
        since: yesterday,
        to: today,
      };
    case TimingFilters.TOMORROW:
      return {
        since: tomorrow,
        to: tomorrow,
      };
    case TimingFilters.NEXT_3_DAYS:
      return {
        since: today,
        to: next3,
      };
    case TimingFilters.THIS_WEEK:
      return {
        since: startOfWeek,
        to: endOfWeek,
      };
    case TimingFilters.THIS_MONTH:
      return {
        since: startOfMonth,
        to: endOfMonth,
      };
    case TimingFilters.NEXT_7_DAYS:
      return {
        since: today,
        to: next7,
      };
    case TimingFilters.NEXT_14_DAYS:
      return {
        since: today,
        to: next14,
      };
    case TimingFilters.NEXT_30_DAYS:
      return {
        since: today,
        to: next30,
      };
    case TimingFilters.YESTERDAY:
      return {
        since: yesterday,
        to: yesterday,
      };
    case TimingFilters.LAST_3_DAYS:
      return {
        since: last3,
        to: yesterday,
      };
    case TimingFilters.LAST_WEEK:
      return {
        since: startOfPrevWeek,
        to: endOfPrevWeek,
      };
    case TimingFilters.LAST_7_DAYS:
      return {
        since: last7,
        to: today,
      };
    case TimingFilters.LAST_14_DAYS:
      return {
        since: last14,
        to: today,
      };
    case TimingFilters.LAST_MONTH:
      return {
        since: startOfPrevMonth,
        to: endOfPrevMonth,
      };
    case TimingFilters.LAST_30_DAYS:
      return {
        since: last30,
        to: today,
      };
    case TimingFilters.LAST_60_DAYS:
      return {
        since: last60,
        to: today,
      };
    case TimingFilters.LAST_90_DAYS:
      return {
        since: last90,
        to: today,
      };
    // case TimingFilters.PREVIOUS:
    //   return {
    //     since: '1970-01-01',
    //     to: today,
    //   }
    case TimingFilters.UPCOMING:
      return {
        since: today,
        to: '2100-01-01',
      };
    default:
      return {
        since: null,
        to: null,
      };
  }
};

/**
 * @function dateCreatedParser
 * @summary convert timing strings ('today', 'next7', etc) into objects for GQL queries
 * @param {CreatedFilters} timePeriod one of options in CreatedFilters
 * @return {dateFilterObject}
 */
// LATER: figure out how to pass the correct type for time values, so can interchange
// with the enum TimingFilters and the dateParser function above
export const dateCreatedParser = (
  timePeriod: CreatedFilters,
): dateFilterObject => {
  const replaceSlashesWithDashes = (input: string) => input.replace(/\//g, '-');
  const yesterday = replaceSlashesWithDashes(
    format(add(new Date(), { days: -1 }), 'yyyy/MM/dd'),
  );
  const today = replaceSlashesWithDashes(format(new Date(), 'yyyy/MM/dd'));
  const lastX = (numDays: number) =>
    replaceSlashesWithDashes(
      format(add(new Date(), { days: -numDays }), 'yyyy/MM/dd'),
    );

  switch (timePeriod) {
    case CreatedFilters.TODAY:
      return {
        since: today,
        to: today,
      };
    case CreatedFilters.YESTERDAY:
      return {
        since: yesterday,
        to: yesterday,
      };
    case CreatedFilters.LAST_2_DAYS:
      return {
        since: lastX(2),
        to: today,
      };
    case CreatedFilters.LAST_3_DAYS:
      return {
        since: lastX(3),
        to: today,
      };
    case CreatedFilters.LAST_4_DAYS:
      return {
        since: lastX(4),
        to: today,
      };
    case CreatedFilters.LAST_5_DAYS:
      return {
        since: lastX(5),
        to: today,
      };
    case CreatedFilters.LAST_7_DAYS:
      return {
        since: lastX(7),
        to: today,
      };
    case CreatedFilters.LAST_14_DAYS:
      return {
        since: lastX(14),
        to: today,
      };
    case CreatedFilters.LAST_30_DAYS:
      return {
        since: lastX(30),
        to: today,
      };
    case CreatedFilters.LAST_60_DAYS:
      return {
        since: lastX(60),
        to: today,
      };
    case CreatedFilters.LAST_90_DAYS:
      return {
        since: lastX(90),
        to: today,
      };
    default:
      return {
        since: null,
        to: null,
      };
  }
};

/**
 * @function convertArrayToFilterOptions
 * @summary Create filter options from array. Label and value will be the same
 * @param {Array} arr array of values
 * @returns {Array<FilterOption>} list of filter options
 */
export const convertArrayToFilterOptions = (
  arr: Array<unknown>,
): Array<FilterOption> => {
  return arr.map(item => {
    return {
      label: `${item}`,
      value: `${item}`,
    };
  });
};

export const convertArrayOfNumbersToStringFilterOptions = (
  arr: Array<number>,
): Array<FilterOption> => {
  return convertArrayToFilterOptions(arr.map(item => item.toString()));
};

/**
 * @function convertEnumToFilterOptions
 * @summary Create filter options based on Enum.
 * Choose labels from either the keys or values
 * @param {object} en String num to process (Enum values must be string)
 * @param {string} labels key | value (value to show for filter label)
 * @returns {Array<FilterOption>} list of filter options
 */
export const convertEnumToFilterOptions = (
  en: object,
  labels: 'key' | 'value' = 'value',
): Array<FilterOption> => {
  const output = [];
  if (labels === 'key') {
    Object.keys(en).forEach((key: string) => {
      output.push({
        label: key.replace(/([a-z])([A-Z0-9])/g, '$1 $2'),
        value: en[key],
      });
    });
  }
  if (labels === 'value') {
    Object.values(en).forEach((value: string) => {
      output.push({
        label: (
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        ).replace('_', ' '),
        value: value,
      });
    });
  }
  return output;
};

/**
 * @function convertLabelObjectToFilterOptions
 * @summary Converts our Label Objects to filter options
 * @param {object} labelObject
 * @returns {Array<FilterOption>} list of filter options
 */
export const convertLabelObjectToFilterOptions = (
  obj: object,
): Array<FilterOption> => {
  const output = Object.values(obj).map(([value, label]) => ({ value, label }));
  return output;
};

/**
 * @function getFilterObjectByValue
 * @param {string} value string of the value you are looking up
 * @param  {FilterOption[]} options  which options array you want
 * @returns {FilterOption} object for use
 */
export const getFilterObjectByValue = (
  value: string,
  options: FilterOption[],
) => options.filter(opt => opt.value === value);

/**
 * @function getFilterObjectByValues
 * @param {string[]} values array of string of the value you are looking up
 * @param  {FilterOption[]} options  which options array you want
 * @returns {FilterOption} object for use
 */
export const getFilterObjectByValues = (
  values: Array<string>,
  options: FilterOption[],
) => options.filter(opt => values.includes(opt.value));

/**
 * @function translateFilterDependenciesToKeys
 * @summary used for creating unique data keys for combination of given filters and their selected values
 * @param {Array of Arrays FilterOption} filters
 * @returns {string[]}
 */
export const translateFilterDependenciesToKeys = (
  filters: FilterOption[][] = [],
) =>
  filters.map((selectedOptionsArr: Array<FilterOption>) =>
    selectedOptionsArr?.length
      ? selectedOptionsArr.map((option: FilterOption) => option.value).join('_')
      : null,
  );

export const translateFilterDependenciesDatesToKeys = (
  dates: CalendarValues,
): string => {
  // 07/04/3042 - 07/28/3042
  const { start, end } = dates;
  return `${start ? format(new Date(start), 'MM/dd/yyyy') : ''}${end ? `-${format(end, 'MM/dd/yyyy')}` : ''}`;
};

/**
 * @function flattenFilters
 * @param {object} filters Object of arrays of filter options
 * @returns {array}
 */
export const flattenFilters = (
  appliedFilters: Record<string, FilterOption[]>,
) => {
  return Object.keys(appliedFilters)
    .map(filterKey => {
      if (['role'].includes(filterKey)) return null; // skip role
      return appliedFilters[filterKey].map(filter => ({
        ...filter,
        field: filterKey,
      }));
    })
    .flat(1)
    .filter(x => !!x);
};

export const arrayOfStringsParser = (
  key: string,
  filters: Array<FilterOption>,
  returnOne = false,
) => {
  if (!key || !filters || filters.length === 0) return undefined;
  if (returnOne) {
    return {
      [key]: filters[0].value,
    };
  }
  return {
    [key]: filters.map(val => val.value),
  };
};

export const arrayOfBooleanStringsParser = (
  filterArray: Array<FilterOption>,
  filterKey: string,
) => {
  if (!filterArray || filterArray.length === 0) return undefined;
  switch (filterArray[0]?.value) {
    case 'true':
      return { [filterKey]: true };
    case 'false':
    default:
      return { [filterKey]: false };
  }
};

export const stringToBooleanParser = (options: Array<FilterOption>) => {
  if (!options || options.length === 0) return undefined;
  switch (options[0]?.value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'none':
    default:
      return undefined;
  }
};
