import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { paging, ReflexResponse, uuid as uuidType } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchCustomerProps extends paging {
  uuid?: string; // uuid
  uuids?: string[]; // uuid
  retailers?: string[]; // uuid;
  hasTerms?: boolean;
}

export const fetchCustomers = async (
  data: fetchCustomerProps,
  query = Queries.CUSTOMERS_QUERY,
  contextOverride?: string,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'CUSTOMER_QUERY'),
    query,
    data,
    returnKey: ['customers', 'customers_paging'],
  });

export interface mutateCustomerUpdateProps {
  customer: uuidType;
  name?: string;
  admins?: Array<uuidType>;
  retailers?: Array<uuidType>;
  isActive?: boolean;
  externalCustomerBillingId?: boolean;
  accountOwner?: uuidType;
}

export const mutateCustomerUpdate = async (data: mutateCustomerUpdateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_CUSTOMER_MUTATION'),
    query: Mutations.UPDATE_CUSTOMER_MUTATION,
    data,
    returnKey: 'updateCustomer',
  });

export interface mutateCustomerCreateProps {
  name: string;
  admins?: Array<uuidType>;
  externalCustomerBillingId?: string;
  accountOwner?: uuidType;
}

export const mutateCustomerCreate = async (data: mutateCustomerCreateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_CUSTOMER_MUTATION'),
    query: Mutations.CREATE_CUSTOMER_MUTATION,
    data,
    returnKey: 'createCustomer',
  });
