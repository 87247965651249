import { ReflexResponse } from 'types';
import { _gql } from './api';
import {
  filterNullsFromObject,
  filterUndefinedsFromObject,
  getObjectKey,
} from './data';
import { getStore, REFLEX_STORE } from './localStorage';

export interface GQLProps {
  route?: string;
  query: string;
  data: any;
  returnKey: string | string[]; // string[] supports multiple queries & mutations in one request
}

export const fetchObject = async ({
  route,
  query,
  data,
  returnKey,
}: GQLProps): Promise<ReflexResponse> => {
  const out: ReflexResponse = {};
  const response = _gql(route, query, filterNullsFromObject(data))
    .then((res: Response) => {
      out.hasErrors = res.status >= 400;
      out.redirected = res.redirected;
      out.status = res.status;
      out.statusText = res.statusText;
      return res.json();
    })
    .then(json => {
      out.hasErrors = out.hasErrors || !!json.errors?.length;
      out.errors = json.errors || [];
      out.body = json;
      if (typeof returnKey === 'string') {
        // Single query result to return
        out.data = json.data?.[returnKey];
        out[returnKey] = json.data?.[returnKey];
      } else if (Array.isArray(returnKey)) {
        // Multiple query results to return
        out.data = {};
        for (const key of returnKey) {
          out[key] = json.data?.[key];
          out.data[key] = json.data?.[key];
        }
      }
      return out;
    });
  return response;
};

export const mutateObject = async ({
  route,
  query,
  data,
  returnKey,
}: GQLProps): Promise<ReflexResponse> => {
  const out: ReflexResponse = {};
  const response = await _gql(route, query, data)
    .then((res: Response) => {
      out.hasErrors = res.status >= 400;
      out.redirected = res.redirected;
      out.status = res.status;
      out.statusText = res.statusText;
      return res.json();
    })
    .then(json => {
      out.hasErrors = out.hasErrors || !!json.errors?.length;
      out.errors = json.errors || [];
      out.body = json;
      if (typeof returnKey === 'string') {
        // Single mutation result to return
        out[returnKey] = json.data ? json.data[returnKey] : {};
      } else if (Array.isArray(returnKey)) {
        // Multiple mutation results to return
        out.data = {};
        for (const key of returnKey) {
          out[key] = json.data ? json.data[key] : {};
          out.data[key] = json.data ? json.data[key] : {};
        }
      }
      return out;
    });
  return response;
};

export const getContextOverride = (
  queryMapObject: Record<string, string>,
  description: string,
  query: string,
  defaultQuery: string,
) => {
  if (query === defaultQuery) return null;
  if (
    Object.keys(queryMapObject).includes(getObjectKey(queryMapObject, query))
  ) {
    return `${description}_${getObjectKey(queryMapObject, query)}_query`;
  }
  return null;
};

export const applyContext = (endpoint: string, queryName: string): string => {
  const activePage = getStore(REFLEX_STORE)?.analytics?.activePage;
  const queryParams = filterUndefinedsFromObject(
    filterNullsFromObject({
      ref: queryName.toLowerCase(),
      origin: activePage && activePage,
    }),
  );
  return `${endpoint}?${new URLSearchParams(queryParams)}`;
};

/**
 * @summary returns helpful context name for graph query
 * based on series of conditional extensions
 */
export const contextNameHelper = ({
  name,
  extensions,
}: {
  name: string;
  extensions?: Array<{ condition: boolean; name: string }>;
}): string => {
  const injectExtensions = list => {
    return list.map(item => (item.condition ? `${item.name}_` : '')).join('');
  };
  return `${name}_${extensions?.length ? injectExtensions(extensions) : ''}QUERY`;
};
