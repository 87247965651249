// FOR OZ
export const UPDATE_USER_MUTATION = `
  mutation UPDATE_REFLEX_USER_MUTATION(
    $activatedBy: String
    $canScheduleCall: Boolean
    $firstName: String
    $is1099PayproviderComplete: Boolean
    $isBackcheckComplete: Boolean
    $isI9Verified: Boolean
    $isW2PayproviderComplete: Boolean
    $lastName: String
    $markets: [Int]
    $onboardedBy: String
    $onboarding: JSONString
    $onboardingDate: String
    $phoneNumber: String
    $status: String
    $tier: String
    $users: [String]
    $title: String
    $email: String
  ) {
    updateUsers(
      activatedBy: $activatedBy
      canScheduleCall: $canScheduleCall
      firstName: $firstName
      is1099PayproviderComplete: $is1099PayproviderComplete
      isBackcheckComplete: $isBackcheckComplete
      isI9Verified: $isI9Verified
      isW2PayproviderComplete: $isW2PayproviderComplete
      lastName: $lastName
      markets: $markets
      onboardedBy: $onboardedBy
      onboarding: $onboarding
      onboardingDate: $onboardingDate
      phoneNumber: $phoneNumber
      status: $status
      tier: $tier
      users: $users
      title: $title
      email: $email
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CREATE_USER_MUTATION = `
  mutation CREATE_USER_MUTATION(
    $firstName: String!
    $lastName: String
    $email: String!
    $password: String!
    $title: String
    $phoneNumber: String
    $isRetail: Boolean
    $isWorker: Boolean
    $status: String
    $requiresPasswordUpdate: Boolean
    $locationPermissions: [LocationPermissionsUserInput]
    $organization: String
  ) {
    createUser (
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      title: $title
      phoneNumber: $phoneNumber
      isRetail: $isRetail
      isWorker: $isWorker
      status: $status
      requiresPasswordUpdate: $requiresPasswordUpdate
      locationPermissions: $locationPermissions
      organization: $organization
    ) {
      ok
      uuid
      messages {
        code
        description
      }
    }
  }
`;

// FOR WEB
export const UPDATE_USER_PROFILE_MUTATION = `
  mutation UPDATE_USER_PROFILE_MUTATION (
    $uuid: String!
    $aboutMe: String
    $address: String
    $birthdate: Date
    $city: String
    $firstName: String
    $language: String
    $lastName: String
    $location: String
    $phoneNumber: String
    $preferredName: String
    $pronouns: String
    $state: String
    $title: String
    $zip: String
  ) {
    updateUserProfile (
      uuid: $uuid
      aboutMe: $aboutMe
      address: $address
      birthdate: $birthdate
      city: $city
      firstName: $firstName
      language: $language
      lastName: $lastName
      location: $location
      phoneNumber: $phoneNumber
      preferredName: $preferredName
      pronouns: $pronouns
      state: $state
      title: $title
      zip: $zip
    ) {
      ok
      messages { code }
    }
  }
`;

export const RETAILER_REMOVE_ORGANIZATION_ACCESS_MUTATION = `
  mutation RETAILER_REMOVE_ORGANIZATION_ACCESS_MUTATION (
    $note: String!
    $userUuid: String!
  ) {
    retailerRemoveOrganizationAccess (
      note: $note
      userUuid: $userUuid
    ) {
      ok
      messages { code }
    }
  }
`;

export const CREATE_RETAILER_NOTE_MUTATION = `
  mutation CREATE_RETAILER_NOTE_MUTATION (
    $note: String,
  ) {
    genericRetailerNote(
      note: $note,
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const SEND_ONBOARDING_DOCUMENTS_MUTATION = `
  mutation SEND_ONBOARDING_DOCUMENTS_MUTATION (
    $uuids: [String]
    $sendEvereeRequest: Boolean
    $sendGigwageRequest: Boolean
    $sendCheckrRequest: Boolean
  ) {
    sendOnboardingDocuments(
      uuids: $uuids
      sendEvereeRequest: $sendEvereeRequest
      sendGigwageRequest: $sendGigwageRequest
      sendCheckrRequest: $sendCheckrRequest
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const CREATE_COHORT_WORKERSHIFTS_MUTATION = `
  mutation CREATE_COHORT_WORKERSHIFTS_MUTATION (
    $shift: String
    $cohortName: String
    $cohortDefinition: CohortInput
  ) {
    createCohortWorkershifts(
      shift: $shift
      cohortName: $cohortName
      cohortDefinition: $cohortDefinition
    ) {
      ok 
      messages {
        code
        description
      }
  }
  }
`;

export const SOFT_DELETE_USER_MUTATION = `
  mutation SOFT_DELETE_USER_MUTATION (
    $uuid: String!
    $confirmationString: String!
  ) {
    softDeleteUser (
      uuid: $uuid
      confirmationString: $confirmationString
    ) {
      ok
      messages { code description }
    }
  }
`;
