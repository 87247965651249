import { applyContext, fetchObject, Queries } from 'helpers';
import { ReflexResponse } from 'types';
import { GQL } from 'types/src/routes';

export const fetchWizardOptions = async (): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'WIZARD_OPTIONS_QUERY'),
    query: Queries.WIZARD_OPTIONS_QUERY,
    data: {},
    returnKey: 'filterOptions',
  });
