import { applyContext, mutateObject, Mutations } from 'helpers';
import { uuid as uuidType } from 'types';
import { GQL } from 'types/src/routes';

export interface mutatePaymentMethodCreateProps {
  customer?: uuidType;
  name?: string;
  externalPaymentMethodId?: string;
}

export const mutatePaymentMethodCreate = async (
  data: mutatePaymentMethodCreateProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_PAYMENT_METHOD_MUTATION'),
    query: Mutations.CREATE_PAYMENT_METHOD_MUTATION,
    data,
    returnKey: 'createPaymentMethod',
  });
