const black = '#1A1A1A';
const white = '#FFFFFF';
const whiteAlpha = {
  50: 'rgba(255, 255, 255, 0.04)',
  100: 'rgba(255, 255, 255, 0.06)',
  200: 'rgba(255, 255, 255, 0.08)',
  300: 'rgba(255, 255, 255, 0.16)',
  400: 'rgba(255, 255, 255, 0.24)',
  500: 'rgba(255, 255, 255, 0.36)',
  600: 'rgba(255, 255, 255, 0.48)',
  700: 'rgba(255, 255, 255, 0.64)',
  800: 'rgba(255, 255, 255, 0.80)',
  900: 'rgba(255, 255, 255, 0.92)',
};
const blackAlpha = {
  50: 'rgba(0, 0, 0, 0.04)',
  100: 'rgba(0, 0, 0, 0.06)',
  200: 'rgba(0, 0, 0, 0.08)',
  300: 'rgba(0, 0, 0, 0.16)',
  400: 'rgba(0, 0, 0, 0.24)',
  500: 'rgba(0, 0, 0, 0.36)',
  600: 'rgba(0, 0, 0, 0.48)',
  700: 'rgba(0, 0, 0, 0.64)',
  800: 'rgba(0, 0, 0, 0.80)',
  900: 'rgba(0, 0, 0, 0.92)',
};
const gray = {
  50: '#F5F5F5',
  100: '#EBEBEB',
  200: '#CECECE',
  300: '#BABABA',
  400: '#A5A5A5',
  500: '#919191',
  600: '#616161',
  700: '#454545',
  800: '#292929',
  900: '#0D0D0D',
};
const stone = {
  50: '#FAFAFA',
  100: '#F4F4F5',
  200: '#E4E4E7',
  300: '#D4D4D8',
  400: '#A1A1AA',
  500: '#71717A',
  600: '#52525B',
  700: '#3F3F46',
  800: '#27272A',
  900: '#18181B',
};
const yellow = {
  50: '#FFFCF6',
  100: '#FFF8EC',
  200: '#FFEED0',
  300: '#FFE4B3',
  400: '#FFD07B',
  500: '#FFBC42',
  600: '#E6A93B',
  700: '#BF8D32',
  800: '#997128',
  900: '#7D5C20',
};
const orange = {
  50: '#FFF7EF',
  100: '#FFEEDF',
  200: '#F8CFA9',
  300: '#F5BA84',
  400: '#F2A55E',
  500: '#EF9038',
  600: '#DD7412',
  700: '#A9580E',
  800: '#753D09',
  900: '#442406',
};
const red = {
  50: '#FFF4F4',
  100: '#FEEDED',
  200: '#FCC7C7',
  300: '#F9A0A0',
  400: '#F77A7A',
  500: '#F55353',
  600: '#F21E1E',
  700: '#CC0C0C',
  800: '#970909',
  900: '#620606',
};
const pink = {
  50: '#FFFAFA',
  100: '#FFF5F5',
  200: '#FFE6E6',
  300: '#FFD7D7',
  400: '#FFB8B8',
  500: '#FF9A9A',
  600: '#E68B8B',
  700: '#BF7474',
  800: '#995C5C',
  900: '#7D4B4B',
};
const green = {
  50: '#F3FAF9',
  100: '#E6F6F3',
  200: '#C2E7E1',
  300: '#9DD9CF',
  400: '#53BDAA',
  500: '#09A086',
  600: '#089079',
  700: '#077865',
  800: '#056050',
  900: '#044E42',
};
const purple = {
  50: '#F8F8FF',
  100: '#EDEDFD',
  200: '#CAC9F8',
  300: '#A7A5F3',
  400: '#8381EE',
  500: '#605DE9',
  600: '#5A46D4',
  700: '#441ABC',
  800: '#24237B',
  900: '#0E0C59',
};
const blue = {
  50: '#F6F9FB',
  100: '#E0F1FC',
  200: '#BDDDF9',
  300: '#90BBEF',
  400: '#5C8AD6',
  500: '#3B73CE',
  600: '#2A5AA7',
  700: '#1F417A',
  800: '#13294D',
  900: '#081120',
};
const navy = {
  50: '#F4F6F7',
  100: '#EAEDEF',
  200: '#C9D2D8',
  300: '#A9B7C1',
  400: '#698192',
  500: '#284B63',
  600: '#244459',
  700: '#1E384A',
  800: '#182D3B',
  900: '#142531',
};

const maroon = {
  50: '#F9F3F3',
  100: '#F2E6E6',
  200: '#E1C1C1',
  300: '#CF9C9C',
  400: '#A96E6E',
  500: '#832E2E',
  600: '#782828',
  700: '#631E1E',
  800: '#4E1919',
  900: '#3F1414',
};

const text = {
  primary: gray[900],
  secondary: gray[600],
  hint: gray[500],
  disabled: gray[400],
};

const background = {
  transparent: 'transparent',
  black,
  white,
  sky: '#FBFBFB',
  light: gray[50],
  medium: gray[100],
  gray: gray[300],
  stone: stone[500],
  dark: gray[500],
  navy: navy[500],
  concrete: navy[400],
  blue: blue[500],
  purple: purple[500],
  green: green[500],
  yellow: yellow[400],
  orange: orange[500],
  red: red[500],
  maroon: maroon[500],
  pink: pink[500],
  navyish: navy[50],
  blueish: blue[50],
  greenish: green[50],
  purpleish: purple[50],
  yellowish: yellow[50],
  orangeish: orange[50],
  redish: red[50],
  pinkish: pink[50],
};

const bg = background;
const main = background;

export const focusColor = blackAlpha[100];

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black,
  white,
  whiteAlpha,
  blackAlpha,
  gray,
  red,
  maroon,
  yellow,
  orange,
  green,
  blue,
  navy,
  pink,
  purple,
  stone,
  text,
  bg,
  background,
  main,
  focus: focusColor,
  hubspot: '#05a3bd',
};
