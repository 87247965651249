export const CREATE_FLEX_DETAIL = `
  mutation CREATE_FLEX_DETAIL (
    $name: String
    $location: String!
    $detailType: String!
    $roles: [String]
    $order: Int
    $visibility: String
  ) {
    createFlexDetail (
      name: $name
      location: $location
      detailType: $detailType
      roles: $roles
      order: $order
      visibility: $visibility
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;

export const UPDATE_FLEX_DETAIL = `
  mutation UPDATE_FLEX_DETAIL (
    $uuid: String!
    $name: String
    $location: String
    $detailType: String
    $roles: [String]
    $order: Int
    $visibility: String
  ) {
    updateFlexDetail (
      uuid: $uuid
      name: $name
      location: $location
      detailType: $detailType
      roles: $roles
      order: $order
      visibility: $visibility
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;

export const DELETE_FLEX_DETAIL = `
  mutation DELETE_FLEX_DETAIL (
    $uuid: String
    $retailer: String
    $detailType: String
  ) {
    deleteFlexDetail (
      uuid: $uuid
      retailer: $retailer
      detailType: $detailType
    ) {
      ok
      messages { code }
    }
  }
`;

export const SYNC_FLEX_DETAIL = `
  mutation SYNC_FLEX_DETAIL (
    $uuid: String
  ) {
    syncFlexDetail (
      uuid: $uuid
    ) {
      ok
      messages { code }
    }
  }
`;

export const DUPLICATE_FLEX_DETAILS = `
  mutation DUPLICATE_FLEX_DETAILS (
    $fromLocation: String!
    $toLocation: String!
  ) {
    duplicateFlexDetails (
      fromLocation: $fromLocation
      toLocation: $toLocation
    ) {
      ok
      messages { code }
    }
  }
`;

export const CREATE_FLEX_DETAIL_VALUE = `
  mutation CREATE_FLEX_DETAIL_VALUE (
    $flexDetail: String!
    $valueType: String!
    $value: String!
    $meta: JSONString
  ) {
    createFlexDetailValue (
      flexDetail: $flexDetail
      valueType: $valueType
      value: $value
      meta: $meta
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;

export const UPDATE_FLEX_DETAIL_VALUE = `
  mutation UPDATE_FLEX_DETAIL_VALUE (
    $uuid: String!
    $flexDetail: String
    $valueType: String
    $value: String
    $meta: JSONString
  ) {
    updateFlexDetailValue (
      uuid: $uuid
      flexDetail: $flexDetail
      valueType: $valueType
      value: $value
      meta: $meta
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;

export const DELETE_FLEX_DETAIL_VALUE = `
  mutation DELETE_FLEX_DETAIL_VALUE (
    $uuid: String!
  ) {
    deleteFlexDetailValue (
      uuid: $uuid
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;

export const UPSERT_FLEX_DETAIL_VALUE = `
  mutation UPSERT_FLEX_DETAIL_VALUE (
    $uuid: String
    $flexDetail: String
    $valueType: String
    $value: String
    $meta: JSONString
  ) {
    upsertFlexDetailValue (
      uuid: $uuid
      flexDetail: $flexDetail
      valueType: $valueType
      value: $value
      meta: $meta
    ) {
      uuid
      ok
      messages { code }
    }
  }
`;
