import { applyContext, mutateObject, Mutations } from 'helpers';
import { uuid as uuidType } from 'types';
import { GQL } from 'types/src/routes';

export interface mutateDocumentUpdateProps {
  document: uuidType;
  terms?: uuidType;
  name?: string;
  title?: string;
  body?: string;
  externalId?: string;
  signatureRequired?: boolean;
  signedBy?: uuidType;
  serviceProvider?: string;
}

export const mutateDocumentUpdate = (data: mutateDocumentUpdateProps) =>
  mutateObject({
    route: applyContext(GQL, 'UPDATE_DOCUMENT_MUTATION'),
    query: Mutations.UPDATE_DOCUMENT_MUTATION,
    data,
    returnKey: 'updateDocument',
  });

export interface mutateDocumentCreateProps {
  name?: string;
  signatureRequired?: boolean;
  terms?: uuidType;
  title?: string;
}

export const mutateDocumentCreate = (data: mutateDocumentCreateProps) =>
  mutateObject({
    route: applyContext(GQL, 'CREATE_DOCUMENT_MUTATION'),
    query: Mutations.CREATE_DOCUMENT_MUTATION,
    data,
    returnKey: 'createDocument',
  });
