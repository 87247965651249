import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const _LOCATION_BUDGET_SUB_PARAMS = `
  $startFromDate: Date
  $startToDate: Date
  $endFromDate: Date
  $endToDate: Date
`;
export const _LOCATION_BUDGET_SUB_VARIABLES = `
  startFromDate: $startFromDate
  startToDate: $startToDate
  endFromDate: $endFromDate
  endToDate: $endToDate
`;

const _LOCATION_BUDGET_PARAMS = `
  $locations: [String]
  $isCurrent: Boolean
  $hasUnapproved: Boolean
  ${_LOCATION_BUDGET_SUB_PARAMS}
  ${_PAGING_PARAMS}
`;
const _LOCATION_BUDGET_VARIABLES = `
  locations: $locations
  isCurrent: $isCurrent
  hasUnapproved: $hasUnapproved
  ${_LOCATION_BUDGET_SUB_VARIABLES}
  ${_PAGING_VARIABLES}
`;

export const _LOCATION_BUDGET_CORE = `
  uuid
  createdAt
  amountHours: amount(currency: HOURS)
  realizedTotalHours: realizedTotal(currency: HOURS)
  blendedTotalHours: blendedTotal(currency: HOURS)
  estimatedTotalHours: estimatedTotal(currency: HOURS)
  estimatedApprovedHours: estimatedApproved(currency: HOURS)
  estimatedPendingHours: estimatedPending(currency: HOURS)
  estimatedPendingFeeHours: estimatedPendingFees(currency: HOURS)
  totalFeeHours: totalFees(currency: HOURS)
  start
  end
  isCurrent
  isFuture
  isPast
`;

export const _LOCATION_BUDGETS_META_VARIABLES = `
  meta: {
    realized_total_hours: {
      sum: "realized_total_hours"
    }
    amount_hours : {
      sum: "amount_hours"
    }
    estimated_total_hours: {
      sum: "estimated_total_hours"
    }
    estimated_pending_hours: {
      sum: "estimated_pending_hours"
    }
    blended_total_hours: {
      sum: "blended_total_hours"
    }
    blended_approved_total_hours: {
      sum: "blended_approved_total_hours"
    }
    estimated_pending_fees_hours: {
      sum: "estimated_pending_fees_hours"
    }
    total_fees_hours: {
      sum: "total_fees_hours"
    }
  }
`;

export const LOCATION_BUDGETS_QUERY = `
  query LOCATION_BUDGETS_QUERY(
    ${_LOCATION_BUDGET_PARAMS}
  ) {
    locationBudgets(
      ${_LOCATION_BUDGET_VARIABLES}
      ${_LOCATION_BUDGETS_META_VARIABLES}
    ) {
      location {
        uuid
        name
      }
      ${_LOCATION_BUDGET_CORE}
    }
  }
`;

// Includes amount + location uuid for client filtering
export const LOCATION_BUDGETS_SUMMARY_QUERY = `
  query LOCATION_BUDGETS_QUERY(
    ${_LOCATION_BUDGET_PARAMS}
  ) {
    locationBudgets(
      ${_LOCATION_BUDGET_VARIABLES}
      ${_LOCATION_BUDGETS_META_VARIABLES}
    ) {
      uuid
      amount
      location { uuid }
    }
  }
`;

export const BUDGETS_QUERY = `
  query BUDGETS_WITH_LOCATIONS_QUERY(${_LOCATION_BUDGET_PARAMS}) {
    locationBudgets (${_LOCATION_BUDGET_VARIABLES}) {
      ${_LOCATION_BUDGET_CORE}
      budgetPeriod {
        name
        start
        end
        uuid
      }
    }
  }
`;

export const BUDGETS_WITH_LOCATIONS_QUERY = `
  query BUDGETS_WITH_LOCATIONS_QUERY(${_LOCATION_BUDGET_PARAMS}) {
    locationBudgets (${_LOCATION_BUDGET_VARIABLES}) {
      ${_LOCATION_BUDGET_CORE}
      totalUnapprovedShiftCount
      budgetPeriod {
        name
      }
      location {
        uuid
        name
        timezone
        retailer {
          uuid
          name
          logoImage
        }
      }
      requestingUsers {
        uuid
        firstName
        lastName
      }
      nextUnapproved {
        uuid
        start
        end
        status
        duration
        isRetailerApproved
      }
    }
  }
`;

export const BUDGETS_WITH_SHIFTS_LIGHT_QUERY = `
  query BUDGETS_WITH_LOCATIONS_QUERY(${_LOCATION_BUDGET_PARAMS}) {
    locationBudgets (${_LOCATION_BUDGET_VARIABLES}) {
      ${_LOCATION_BUDGET_CORE}
      budgetPeriod {
        name
      }
      shifts {
        uuid
        start
        end
        status
        duration
        isRetailerApproved
      }
    }
  }
`;

export const BUDGETS_WITH_LOCATIONS_AND_SHIFTS_QUERY = `
  query BUDGETS_WITH_LOCATIONS_QUERY(${_LOCATION_BUDGET_PARAMS}) {
    locationBudgets (${_LOCATION_BUDGET_VARIABLES}) {
      ${_LOCATION_BUDGET_CORE}
      totalUnapprovedShiftCount
      budgetPeriod {
        name
      }
      location {
        uuid
        name
        timezone
        retailer {
          uuid
          name
          logoImage
        }
      }
      requestingUsers {
        uuid
        firstName
        lastName
      }
      nextUnapproved {
        uuid
        start
        end
        status
        duration
        isRetailerApproved
      }
      shifts {
        uuid
        start
        end
        status
        duration
        isRetailerApproved
      }
    }
  }
`;

const _BUDGET_PERIODS_PARAMS = `
  $locations: [String]
  $startFromDate: Date
  $startToDate: Date
  $endFromDate: Date
  $endToDate: Date
  $isCurrent: Boolean
  $excludePast: Boolean
  $organizations: [String]
`;
const _BUDGET_PERIOD_VARIABLES = `
  locations: $locations
  startFromDate: $startFromDate
  startToDate: $startToDate
  endFromDate: $endFromDate
  endToDate: $endToDate
  isCurrent: $isCurrent
  excludePast: $excludePast
  organizations: $organizations
`;

// FUTURE: move this to new file
export const BUDGET_PERIODS_QUERY = `
  query BUDGET_PERIODS_QUERY (
    ${_BUDGET_PERIODS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    budgetPeriods (
      ${_BUDGET_PERIOD_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      start
      end
      name
      isCurrent
      isFuture
      isPast
      uuid
    }
  }
`;

export const BUDGET_PERIODS_VERBOSE_QUERY = `
  query BUDGET_PERIODS_QUERY (
    ${_BUDGET_PERIODS_PARAMS}
    ${_PAGING_PARAMS}
  ) {
    budgetPeriods (
      ${_BUDGET_PERIOD_VARIABLES}
      ${_PAGING_VARIABLES}
    ) {
      start
      end
      name
      isCurrent
      uuid
      sumAmountHours
    }
  }
`;
