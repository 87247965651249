import { createNumericIntervalOptions } from 'helpers';
import {
  ActionMap,
  WorkerShiftStatus,
  WorkerTierOptions,
  ZIPCODE_DISTANCE_OPTIONS,
} from 'types';
import {
  convertArrayToFilterOptions,
  convertEnumToFilterOptions,
  getFilterObjectByValues,
} from 'utils/helpers/filters';
import { FilterOption } from 'utils/types/filters';

export interface shiftFilterInterface {
  workershiftStatus?: Array<FilterOption>;
  userTags?: Array<FilterOption>;
  userTagsMissing?: Array<FilterOption>;
  tier?: Array<FilterOption>;
  isW2Ready?: Array<FilterOption>;
  is1099Ready?: Array<FilterOption>;
  ratingMax: Array<FilterOption>;
  ratingMin: Array<FilterOption>;
  priorityMax: Array<FilterOption>;
  priorityMin: Array<FilterOption>;
  shiftsCompletedMin?: Array<FilterOption>;
  shiftsCompletedMax?: Array<FilterOption>;
  zipDistance?: Array<FilterOption>;
  zipcode?: Array<FilterOption>;
  zipcodeMaxDistance?: Array<FilterOption>;
  zipcodeMinDistance?: Array<FilterOption>;
  hasReviews?: Array<FilterOption>;
}

export interface ShiftStateInterface {
  filters: shiftFilterInterface;
}

export const filterDefaults = {
  workershiftStatus: getFilterObjectByValues(
    [
      WorkerShiftStatus.ACCEPTED,
      WorkerShiftStatus.COMPLETE,
      WorkerShiftStatus.NEW,
      WorkerShiftStatus.OVERBOOKED,
      WorkerShiftStatus.CANCELLED,
    ],
    convertEnumToFilterOptions(WorkerShiftStatus),
  ),
  userTags: [],
  userTagsMissing: [],
  tier: [],
  ratingMax: [],
  ratingMin: [],
  priorityMax: [],
  priorityMin: [],
  shiftsCompletedMin: [],
  shiftsCompletedMax: [],
  zipDistance: [],
  zipcode: [],
  zipcodeMaxDistance: [],
  zipcodeMinDistance: [],
  hasReviews: [],
};

export const initialState = {
  filterOptions: {
    workershiftStatus: convertEnumToFilterOptions(WorkerShiftStatus),
    tier: convertEnumToFilterOptions(WorkerTierOptions),
    ratingMax: convertArrayToFilterOptions(
      createNumericIntervalOptions({
        start: 1.0,
        end: 5.0,
        interval: 0.1,
      }),
    ),
    ratingMin: convertArrayToFilterOptions(
      createNumericIntervalOptions({
        start: 1.0,
        end: 5.0,
        interval: 0.1,
      }),
    ),
    priorityMax: convertArrayToFilterOptions(
      createNumericIntervalOptions({
        start: 1.0,
        end: 3.1,
        interval: 0.1,
      }),
    ),
    priorityMin: convertArrayToFilterOptions(
      createNumericIntervalOptions({
        start: 1.0,
        end: 3.1,
        interval: 0.1,
      }),
    ),
    shiftsCompletedMin: convertArrayToFilterOptions([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
    ]),
    shiftsCompletedMax: convertArrayToFilterOptions([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 75, 100,
    ]),
    zipDistance: convertArrayToFilterOptions([1, 3, 5, 7, 10, 15, 20, 25, 30]),
    zipcode: [],
    zipcodeMinDistance: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
    zipcodeMaxDistance: convertArrayToFilterOptions(ZIPCODE_DISTANCE_OPTIONS),
    hasReviews: convertArrayToFilterOptions([true, false]),
  },
  filters: filterDefaults,
};

export enum ShiftActions {
  CHANGE_FILTER = 'CHANGE_SHIFT_FILTER',
  RESET_FILTER = 'RESET_SHIFT_FILTER',
  RESET_FILTERS = 'RESET_SHIFT_FILTERS',
  UPDATE_ACTIVE_SHIFT = 'UPDATE_ACTIVE_SHIFT',
  REMOVE_ALL_SHIFTS = 'REMOVE_ALL_SHIFTS',
}

type ShiftPayload = {
  [ShiftActions.CHANGE_FILTER]: {
    [key: string]: FilterOption;
  };
  [ShiftActions.RESET_FILTER]: string;
  [ShiftActions.RESET_FILTERS]: null;
};

type ShiftActionTypes = ActionMap<ShiftPayload>[keyof ActionMap<ShiftPayload>];

export const shiftReducer = (draft, action: ShiftActionTypes) => {
  const { type } = action;
  switch (type) {
    case ShiftActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case ShiftActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case ShiftActions.RESET_FILTERS: {
      Object.keys(draft.filters).forEach(key => {
        draft.filters[key] = filterDefaults[key];
      });
      break;
    }
    default:
      return draft;
  }
  return draft;
};
