import { ActionMap, campaign, formStateDefaults } from 'types';
import { filters, FilterScopes } from 'utils/helpers/filters/filters.config';
import { FilterOption } from 'utils/types/filters';
import { formState } from 'utils/types/form';

export type CampaignsFilterInterface = {
  markets?: Array<FilterOption>;
};

export enum EditCampaignsActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export interface CampaignsStateInterface {
  editingWorkers?: EditCampaignsActions;
  filters: CampaignsFilterInterface;
  formState: formState;
  selectedCampaigns: Record<number, campaign>;
  search?: string;
}

export const filterDefaults = {
  markets: filters[FilterScopes.DYNAMIC]?.markets.default,
};

export const initialState = {
  editingWorkers: null,
  filterOptions: {},
  filters: filterDefaults,
  formState: formStateDefaults,
  selectedCampaigns: {},
  search: '',
};

export enum CampaignsActions {
  CHANGE_FILTER = 'CHANGE_CAMPAIGNS_FILTERS',
  RESET_FILTER = 'RESET_CAMPAIGNS_FILTERS',
  SELECT_CAMPAIGN = 'SELECT_CAMPAIGN',
  SELECT_CAMPAIGNS = 'SELECT_CAMPAIGNS',
  DESELECT_CAMPAIGN = 'DESELECT_CAMPAIGN',
  DESELECT_ALL = 'DESELECT_ALL_CAMPAIGNS',
  UPDATE_EDITING_WORKERS = 'UPDATE_CAMPAIGNS_IS_EDITING_WORKERS',
  UPDATE_FORM_PROCESSING = 'UPDATE_CAMPAIGNS_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_CAMPAIGNS_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_CAMPAIGNS_FORM_ERRORS',
  UPDATE_SEARCH = 'UPDATE_CAMPAIGNS_SEARCH',
}

type CampaignsPayload = {
  [CampaignsActions.CHANGE_FILTER]: Record<string, FilterOption>;
  [CampaignsActions.RESET_FILTER]: string;
  [CampaignsActions.SELECT_CAMPAIGN]: Partial<campaign>;
  [CampaignsActions.SELECT_CAMPAIGNS]: Array<campaign>;
  [CampaignsActions.DESELECT_CAMPAIGN]: string; // id
  [CampaignsActions.DESELECT_ALL]: null;
  [CampaignsActions.UPDATE_EDITING_WORKERS]: EditCampaignsActions;
  [CampaignsActions.UPDATE_FORM_PROCESSING]: boolean;
  [CampaignsActions.UPDATE_FORM_SUCCESS]: boolean;
  [CampaignsActions.UPDATE_FORM_ERRORS]: Array<string>;
  [CampaignsActions.UPDATE_SEARCH]: string;
};

type CampaignsActionTypes =
  ActionMap<CampaignsPayload>[keyof ActionMap<CampaignsPayload>];

export const campaignsReducer = (draft, action: CampaignsActionTypes) => {
  const { type } = action;
  switch (type) {
    case CampaignsActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case CampaignsActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case CampaignsActions.SELECT_CAMPAIGN: {
      draft.selectedCampaigns[action?.payload.uuid] = action?.payload;
      break;
    }
    case CampaignsActions.SELECT_CAMPAIGNS: {
      action?.payload.forEach(campaign => {
        const key = campaign.uuid;
        draft.selectedCampaigns[key] = campaign;
      });
      break;
    }
    case CampaignsActions.DESELECT_CAMPAIGN: {
      delete draft.selectedCampaigns[action?.payload];
      break;
    }
    case CampaignsActions.DESELECT_ALL: {
      draft.selectedCampaigns = {};
      break;
    }
    case CampaignsActions.UPDATE_EDITING_WORKERS: {
      draft.editingWorkers = action?.payload;
      break;
    }
    case CampaignsActions.UPDATE_FORM_PROCESSING: {
      draft.formState.isProcessing = action?.payload;
      break;
    }
    case CampaignsActions.UPDATE_FORM_SUCCESS: {
      draft.formState.isSuccess = action?.payload;
      break;
    }
    case CampaignsActions.UPDATE_FORM_ERRORS: {
      draft.formState.errors = action?.payload;
      break;
    }
    case CampaignsActions.UPDATE_SEARCH: {
      draft.search = action?.payload;
      break;
    }
    default:
      return draft;
  }
  return draft;
};
