import { UserFlagStatus } from 'types/src/userFlag';

export const CREATE_FLAG_MUTATION = `
  mutation CREATE_FLAG_MUTATION(
    $users: [String]
    $flags: [String]
    $flaggedNote: String
  ) {
    updateUserFlags(
      users: $users
      flags: $flags
      status: "${UserFlagStatus.ACTIVE}"
      flaggedNote: $flaggedNote
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const RESOLVE_FLAG_MUTATION = `
  mutation RESOLVE_FLAG_MUTATION(
    $users: [String]
    $flags: [String]
    $resolutionNote: String
  ) {
    updateUserFlags(
      users: $users
      flags: $flags
      status: "${UserFlagStatus.RESOLVED}"
      resolutionNote: $resolutionNote
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
