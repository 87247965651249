import { location, LocationStatus } from 'types';
import { getShortTimeZone } from '../dates';

export const getSortedLocations = (
  locations: location[],
  displayRetailerName = false,
) => {
  if (displayRetailerName) {
    return locations?.sort((a, b) => {
      if (a?.retailer?.name === b?.retailer?.name) {
        return a.name.localeCompare(b.name);
      }
      return a?.retailer?.name.localeCompare(b?.retailer?.name);
    });
  }
  return locations?.sort((a, b) => a?.name.localeCompare(b?.name));
};

export const getFullLocationName = (location: location): string => {
  if (!location?.retailer) return location.name;
  return `${location?.retailer?.name} - ${location?.name}`;
};

export const getLocationNameForDisplay = (
  location: location,
  displayRetailerName = false,
): string => {
  if (!location?.retailer) return location.name;
  if (displayRetailerName)
    return `${location?.retailer.name} - ${location?.name}`;
  return location?.name;
};

export const getLocationComplianceInformation = (
  location: location,
  minShiftLength: number,
  maxShiftLength: number,
) => {
  const hasCompliance = !!location?.compliance;
  const shiftMaxLength =
    (location?.compliance?.shiftMaxLength ?? maxShiftLength) / 60;
  const shiftMinLength =
    (location?.compliance?.shiftMinLength ?? minShiftLength) / 60;
  const breakRules = location?.compliance?.breakRules[0];
  const minShiftHoursToRequireBreak =
    breakRules?.shiftMinutesToRequireBreak / 60 || 0;
  const isBreakPaid = breakRules?.paid;
  const breakLength = breakRules?.breakLength;
  return {
    hasCompliance,
    shiftMaxLength,
    shiftMinLength,
    breakRules,
    minShiftHoursToRequireBreak,
    isBreakPaid,
    breakLength,
  };
};

export const getLocationTimezones = location => {
  const longTimeZone = location?.timezone ? location.timezone : 'US/Central';
  const timezones = {
    shortTimeZone: getShortTimeZone(new Date(), longTimeZone),
    longTimeZone,
  };
  return timezones;
};

export const canRetailerUserBookForLocation = (
  status: LocationStatus,
): boolean => [LocationStatus.ACTIVE].includes(status);

export const canOpsUserBookForLocation = (status: LocationStatus): boolean => {
  return true;
  // FUTURE: enable when ready
  // [LocationStatus.ACTIVE, LocationStatus.ONBOARDED].includes(status);
};

export const getFullAddress = (location?: location): string => {
  const { address } = location || {};
  if (!address || !Object.keys(address).length) return '';
  return `${address?.address1}, ${
    address?.address2 ? address?.address2 + ', ' : ''
  } ${address?.city}, ${address?.state} ${address?.zip}`;
};
