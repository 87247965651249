import {
  ActionMap,
  customer,
  document,
  location,
  market,
  retailer,
  terms,
  user,
} from 'types';
import { FilterOption } from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export enum WizardForms {
  RETAILER = 'RETAILER',
  LOCATION = 'LOCATION',
  TERMS = 'TERMS',
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
}

export interface WizardData {
  compliances: Array<FilterOption>;
  customers: Array<customer>;
  documents: Array<document>;
  locations: Array<location>;
  markets: Array<market>;
  retailers: Array<retailer>;
  terms: Array<terms>;
  users: Array<Partial<user>>;
  staff: Array<user>;
}

export interface WizardStateInterface {
  formState: formState;
  activeForm?: WizardForms;
  data?: WizardData;
}

export const initialState = {
  formState: formStateDefaults,
  activeForm: null,
};

export enum WizardActions {
  LOAD_DATA = 'LOAD_WIZARD_DATA',
  UPDATE_DATA = 'UPDATE_WIZARD_DATA',
  UPDATE_FORM_ACTIVE = 'UPDATE_WIZARD_FORM_ACTIVE',
  UPDATE_FORM_STATE = 'UPDATE_WIZARD_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_WIZARD_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_WIZARD_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_WIZARD_FORM_ERRORS',
}

type WizardPayload = {
  [WizardActions.LOAD_DATA]: WizardData;
  [WizardActions.UPDATE_DATA]: {
    key: string;
    data: Array<
      customer | document | location | market | retailer | terms | user
    >;
  };
  [WizardActions.UPDATE_FORM_ACTIVE]: WizardForms;
  [WizardActions.UPDATE_FORM_PROCESSING]: boolean;
  [WizardActions.UPDATE_FORM_SUCCESS]: boolean;
  [WizardActions.UPDATE_FORM_ERRORS]: Array<unknown>;
};

type WizardActionTypes =
  ActionMap<WizardPayload>[keyof ActionMap<WizardPayload>];

export const wizardReducer = (draft, action: WizardActionTypes) => {
  const { type } = action;
  switch (type) {
    case WizardActions.LOAD_DATA:
      draft.data = action?.payload;
      break;
    case WizardActions.UPDATE_DATA: {
      draft.data = {
        ...draft.data,
        [action?.payload?.key]: action?.payload?.data,
      };
      break;
    }
    case WizardActions.UPDATE_FORM_ACTIVE:
      draft.activeForm = action?.payload;
      break;
    case WizardActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case WizardActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case WizardActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
