import {
  EasyWorkerShiftEvent,
  EventDetailState,
  TimelinessEnums,
  workerShiftEvent,
  WorkerShiftEventsEnum,
} from 'types';
import { friendlyDatesHelper } from '../friendlyDates';

const WorkerTimingContent = {
  [WorkerShiftEventsEnum.CLOCK_IN]: {
    [TimelinessEnums.EARLY]: 'In Early',
    [TimelinessEnums.ON_TIME]: 'In On Time',
    [TimelinessEnums.LATE]: 'In Late',
  },
  [WorkerShiftEventsEnum.CLOCK_OUT]: {
    [TimelinessEnums.EARLY]: 'Out Early',
    [TimelinessEnums.ON_TIME]: 'Out On Time',
    [TimelinessEnums.LATE]: 'Out Late',
  },
};

const getEventContentHelper = (eventType, timeliness) => {
  switch (eventType) {
    case WorkerShiftEventsEnum.BREAK_ACKNOWLEDGED:
      return 'Took Break';
    case WorkerShiftEventsEnum.CLOCK_IN:
      return WorkerTimingContent[eventType][timeliness];
    case WorkerShiftEventsEnum.CLOCK_OUT:
      return WorkerTimingContent[eventType][timeliness];
    default:
      return '';
  }
};

const determineTimeliness = (timestamp, expectedStart, expectedEnd) => {
  const expectedStartDate = new Date(expectedStart);
  const expectedEndDate = new Date(expectedEnd);
  const timestampDate = new Date(timestamp);
  if (timestampDate <= expectedStartDate) return TimelinessEnums.EARLY;
  if (timestampDate >= expectedEndDate) return TimelinessEnums.LATE;
  return TimelinessEnums.ON_TIME;
};

export const getEventState = ({
  timestamp,
  approved,
  expectedTimeRangeEnd,
  expectedTimeRangeStart,
}: workerShiftEvent): EventDetailState => {
  const now = new Date();
  const startOfTimeRange = new Date(expectedTimeRangeStart);
  const endOfTimeRange = new Date(expectedTimeRangeEnd);
  if (timestamp && approved) return EventDetailState.COMPLETE;
  if (now < startOfTimeRange) return EventDetailState.NOT_READY;
  if (timestamp && !approved) return EventDetailState.ISSUE_NEEDS_APPROVAL;
  if (!timestamp) {
    if (now < endOfTimeRange) {
      return EventDetailState.INCOMPLETE;
    }
    return EventDetailState.ISSUE_MISSING;
  }
  return EventDetailState.COMPLETE;
};

export const formatEventTimeStamp = (
  timestamp?: string,
  timezone?: string,
): string => {
  if (!timestamp) return;
  const { dayPart, formattedStartTime } = friendlyDatesHelper({
    start: timestamp,
    timezone,
  });
  return `${dayPart} @ ${formattedStartTime}`;
};

export type WorkerShiftEventsMap = {
  [key in WorkerShiftEventsEnum]: EasyWorkerShiftEvent;
};

export const createWorkershiftEventMaps = (
  workershiftEvents: workerShiftEvent[],
  timezone = 'US/Central',
): Partial<WorkerShiftEventsMap> => {
  if (!workershiftEvents) return {};
  return workershiftEvents.reduce((result, event) => {
    const eventKey = event?.eventType as WorkerShiftEventsEnum;

    const eventTimeliness = !!event?.timestamp
      ? determineTimeliness(
          timezone,
          event?.expectedTimeRangeStart,
          event?.expectedTimeRangeEnd,
        )
      : TimelinessEnums.MISSING;

    result[eventKey] = {
      ...event,
      formattedTimestamp: formatEventTimeStamp(event?.timestamp, timezone),
      state: getEventState(event),
      dateStamp: new Date(event?.timestamp),
      timeliness: eventTimeliness,
      wasEarly: eventTimeliness === TimelinessEnums.EARLY,
      wasLate: eventTimeliness === TimelinessEnums.LATE,
      contentHelper: getEventContentHelper(event?.eventType, eventTimeliness),
    };
    return result;
  }, {} as Partial<WorkerShiftEventsMap>);
};
