import { formatInTimeZone } from 'date-fns-tz';
import { titleCase } from 'helpers';
import { CheckboxOption, SelectOption } from 'types';
import { ControlOption } from 'components/common/controls/control.helpers';

const defaultEnumToSelectArgs = {
  labels: 'key',
  fixedOptions: [],
};

interface convertEnumToSelectArgs {
  fixedOptions?: Array<string>;
}

export const convertEnumToSelectOptions = (
  en: object,
  args: convertEnumToSelectArgs = defaultEnumToSelectArgs,
): Array<SelectOption> => {
  const { fixedOptions } = args;
  return Object.keys(en).map(key => ({
    value: key,
    label: titleCase(en[key].toLowerCase()).replace('_', ' '),
    isFixed: fixedOptions.includes(en[key]),
  }));
};

const defaultEnumToCheckboxArgs = {
  labels: 'value',
};

interface convertEnumToCheckboxArgs {
  labels: string;
}

export const convertEnumToCheckboxOptions = (
  en: object,
  args: convertEnumToCheckboxArgs = defaultEnumToCheckboxArgs,
): Array<CheckboxOption> => {
  const { labels } = args;
  return Object.keys(en).map(key => ({
    id: key,
    name: labels === 'value' ? en[key] : key,
  }));
};

export const convertDataToSelectOptions = (
  data: Array<object>,
  labelKey: string | ((obj) => string),
  valueKey: string,
): Array<SelectOption> => {
  if (!data) return [];
  return data?.map(item => ({
    value: item[valueKey],
    label: typeof labelKey === 'string' ? item[labelKey] : labelKey(item),
    isFixed: false,
  }));
};

export const selectOptionsFromNumericalRange = (
  start,
  end,
  type = null,
  iterator = 0.25,
) => {
  const output = [];
  for (let step = start; step <= end; step = step + iterator) {
    output.push(step);
  }
  if (type === 'dollar') {
    return output.map(val => ({
      label: `$${val.toFixed(2)}`,
      value: val,
    }));
  }
  if (type === 'day') {
    return output.map(val => ({
      label: `${val} day${val > 1 ? 's' : ''}`,
      value: val,
    }));
  }
  if (type === 'percent') {
    return output.map(val => ({
      label: `${val}%`,
      value: val,
    }));
  }
  return output.map(val => ({
    label: val,
    value: val,
  }));
};

export const convertDateStringToTimeSelectOption = (
  dateTimeString: string,
  timezone: string,
): SelectOption => {
  return {
    label: formatInTimeZone(dateTimeString, timezone, 'h:mmaaa'),
    value: formatInTimeZone(dateTimeString, timezone, 'HH:mm'),
  };
};

export const convertEnumToControlOptions = (en: object): Array<ControlOption> =>
  Object.keys(en).map(key => ({
    value: key,
    label: titleCase(en[key].toLowerCase()).replace('_', ' '),
  }));
