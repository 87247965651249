// FUTURE: rename image to logoImage
export const CREATE_RETAILER_MUTATION = `
  mutation CREATE_RETAILER_MUTATION (
    $name: String
    $image: String
    $brandImage: String
  ) {
    createRetailer(
      name: $name
      image: $image
      brandImage: $brandImage
    ) {
      ok
      ok
      uuid
      messages { code }
    }
  }
`;

export const UPDATE_RETAILER_MUTATION = `
  mutation UPDATE_RETAILER_MUTATION (
    $uuid: String!
    $name: String
    $logoImage: String
    $brandImage: String
    $linkWebsite: String
    $linkInstagram: String
    $linkTiktok: String
  ) {
    updateRetailer(
      uuid: $uuid
      name: $name
      logoImage: $logoImage
      brandImage: $brandImage
      linkWebsite: $linkWebsite
      linkInstagram: $linkInstagram
      linkTiktok: $linkTiktok
    ) {
      ok
      messages { code }
    }
  }
`;
