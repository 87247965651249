export const MESSAGE_MUTATION = `
  mutation MESSAGE_USERS_MUTATION(
    $to: [String],
    $textBody: String,
    $pushBody: String,
    $shift: String,
    $media: [String],
    $exceptedUsers: [String],
    $title: String,
    $pushData: GenericScalar,
    $zipcodeMaxRange: Float,
  ) {
    messageUsers(
      to: $to, 
      textBody: $textBody,
      pushBody: $pushBody,
      shift: $shift,
      media: $media,
      exceptedUsers: $exceptedUsers,
      title: $title,
      pushData: $pushData,
      zipcodeMaxRange: $zipcodeMaxRange
    ) { 
      ok
      messages {
        code
        description
      }
    }
  }
`;
