import { FilterOption } from 'utils/types/filters';

type retailerListQueryResponseRow = {
  name: string;
  uuid: string;
};

type convertedRetailerList = {
  retailers: Array<FilterOption>;
};

/**
 * @function retailerOptionsParser
 * @summary convert GQL retailer list data into a format expected by our filter list component
 * @param {Array<retailerListQueryResponseRow>}
 * @returns {convertedRetailerList}
 */
export const retailerOptionsParser = (
  options: Array<retailerListQueryResponseRow>,
): convertedRetailerList => {
  const convertedArr = options
    ?.map(retailer => ({
      label: retailer.name,
      value: retailer.uuid,
    }))
    .sort((a, b) => String(a.label).localeCompare(b.label));
  return { retailers: convertedArr };
};

/**
 * @function retailersParser
 * @summary convert Front end retailer filter values into GQL expected format
 * @param {Array<FilterOption>} array of filter options
 * @returns {Object} And object with a key of retailers equal to UUID's array
 */
export const retailersParser = retailersArray => {
  if (!retailersArray || retailersArray.length === 0) return undefined;
  return { retailers: retailersArray.map(retailer => retailer.value) };
};
