export const SEND_EMPLOYMENT_REQUIREMENT_MUTATION = `
  mutation SEND_EMPLOYMENT_REQUIREMENT_MUTATION(
    $users: [String]
    $requirementType: String
  ) {
    sendEmploymentRequirement(
      users: $users
      requirementType: $requirementType
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const EDIT_EMPLOYMENT_REQUIREMENT_MUTATION = `
  mutation EDIT_EMPLOYMENT_REQUIREMENT (
    $requirements: [Int]
    $state: String
    $expiresAt: DateTime
    $log: String
  ) {
    updateEmploymentRequirement (
      requirements: $requirements
      state: $state
      expiresAt: $expiresAt
      log: $log
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
