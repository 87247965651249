import { compliance } from './compliance';
import { terms } from './terms';

export enum AmountThresholdEntryUnit {
  HOURS = 'HOURS',
  DOLLARS = 'DOLLARS',
  PERCENT = 'PERCENT',
}

export type amountThresholdEntry = {
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  amountThreshold?: amountThreshold;
  timing?: number; // number of hours to determine when to apply fee
  amount?: number; // Float
  unit?: AmountThresholdEntryUnit;
};

export type amountThreshold = {
  amounts?: Array<amountThresholdEntry>;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  name?: string;
  terms?: terms;
  compliance?: compliance;
};
