import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL } from 'types/src/routes';

interface fetchWorkflowsProps {
  isVisible?: boolean;
}

export const fetchWorkflows = async (
  data: fetchWorkflowsProps,
  query = Queries.WORKFLOWS_QUERY,
  contextOverride?: string,
) =>
  fetchObject({
    route: applyContext(
      GQL,
      contextOverride ? contextOverride : 'WORKFLOWS_QUERY',
    ),
    query,
    data,
    returnKey: 'workflows',
  });

export interface mutateEnterWorkflowProps {
  workflowUuid: string;
  targetUuids: string[];
}

export const mutateEnterWorkflow = async (data: mutateEnterWorkflowProps) =>
  await mutateObject({
    route: applyContext(GQL, 'ENTER_WORKFLOW_MUTATION'),
    query: Mutations.ENTER_WORKFLOW_MUTATION,
    data,
    returnKey: 'enterWorkflow',
  });
