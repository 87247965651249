export const FILTERS_DEFAULT_QUERY = `
  query FILTERS_DEFAULT_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
    }
  }
`;
export const FILTERS_SHIFTS_QUERY = `
  query FILTERS_SHIFTS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      locations {
        label
        value
      }
      retailers {
        label
        value
      }
      tags {
        label
        value
      }
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
    }
  }
`;
export const FILTERS_SHIFT_QUERY = `
  query FILTERS_SHIFT_QUERY {
    filterOptions {
      tags {
        label
        value
      }
    }
  }
`;
export const FILTERS_LOCATIONS_QUERY = `
  query FILTERS_LOCATIONS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      retailers {
        label
        value
      }
      tags {
        label
        value
      }
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
      staffUsers {
        label
        value
      }
    }
  }
`;
export const FILTERS_REVIEWS_QUERY = `
  query FILTERS_REVIEWS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      locations {
        label
        value
      }
      retailers {
        label
        value
      }
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
    }
  }
`;
export const FILTERS_TAGS_QUERY = `
  query FILTERS_WORKERS_QUERY {
    filterOptions {
      staffUsers {
        label
        value
      }
    }
  }
`;
export const FILTERS_ONBOARDINGS_QUERY = `
  query FILTERS_ONBOARDINGS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
      staffUsers {
        label
        value
      }
    }
  }
`;
export const FILTERS_WORKERS_QUERY = `
  query FILTERS_WORKERS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      locations {
        label
        value
      }
      retailers {
        label
        value
      }
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
      tags {
        label
        value
      }
      staffUsers {
        label
        value
      }
      acquisitionCampaigns {
        label
        value
      }
    }
  }
`;

export const FILTERS_CAMPAIGNS_QUERY = `
  query FILTERS_CAMPAIGNS_QUERY(
    $marketsIsActive: Boolean
  ) {
    filterOptions {
      markets(
        isActive: $marketsIsActive
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
    }
  }
`;

export const FILTERS_ACTIVATE_WORKERS = `
  query FILTERS_ACTIVATE_WORKERS {
    filterOptions {
      markets (
        isActive: true
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
      tags {
        label
        value
      }
    }
  }
`;

export const FILTERS_ACTIONS_SHIFTS_QUERY = `
  query FILTERS_SHIFTS_QUERY {
    filterOptions {
      locations {
        label
        value
      }
      retailers {
        label
        value
      }
      tags {
        label
        value
      }
      markets (
        isActive: true
      ) {
        label
        value
        isActive
        isGroup
        hasLiveLocations
      }
    }
  }
`;

export const WIZARD_OPTIONS_QUERY = `
  query WIZARD_OPTIONS_QUERY {
    filterOptions {
      compliances {
        label
        value
      }
    }
  }
`;

export const FiltersQueries = {
  default: FILTERS_DEFAULT_QUERY,
  locations: FILTERS_LOCATIONS_QUERY,
  onboardings: FILTERS_ONBOARDINGS_QUERY,
  reviews: FILTERS_REVIEWS_QUERY,
  shifts: FILTERS_SHIFTS_QUERY,
  shift: FILTERS_SHIFT_QUERY,
  tags: FILTERS_TAGS_QUERY,
  workers: FILTERS_WORKERS_QUERY,
  wizard: WIZARD_OPTIONS_QUERY,
  campaigns: FILTERS_CAMPAIGNS_QUERY,
};
