export interface formState {
  isProcessing: boolean;
  isSuccess: boolean;
  errors?: Array<string>;
}

export const formStateDefaults = {
  isProcessing: false,
  isSuccess: false,
  errors: [],
};

export type SelectOption = {
  label?: string;
  value?: string;
  isFixed?: boolean;
};

export type CheckboxOption = {
  id: string | number;
  name: string;
  icon?: string;
};
