import { getStore, REFLEX_STORE } from 'helpers';
import { FilterOption } from 'utils/types/filters';
import { ActionOrigin } from 'utils/types/origins';

const isEmpty = obj =>
  !(obj && obj instanceof Object && Object.keys(obj).length);

export enum UserPreferences {
  DEFAULT_FILTERS = 'default-filters',
}

type DefaultFilters = Array<FilterOption>;
export type UserPreferencesDefaultFiltersInterface = Record<
  string,
  DefaultFilters
>;
export type UserPreferencesInterface = {
  [UserPreferences.DEFAULT_FILTERS]: UserPreferencesDefaultFiltersInterface;
};

export interface ReflexOpsStore {
  analytics?: {
    activePage?: ActionOrigin;
  };
  preferences?: Partial<UserPreferencesInterface>;
}

const defaultUserPreferences = {
  [UserPreferences.DEFAULT_FILTERS]: {},
};

const defaultReflexStore: ReflexOpsStore = {
  preferences: defaultUserPreferences,
  analytics: { activePage: null },
};

function createReflexStore(): void {
  if (typeof window === 'undefined') return null;
  window.localStorage.setItem(REFLEX_STORE, JSON.stringify(defaultReflexStore));
}

export function updateStore(
  updateData: Partial<ReflexOpsStore>,
  storeName = REFLEX_STORE,
): void {
  if (typeof window === typeof undefined || !Object.keys(updateData).length)
    return null;
  const currentStore = getStore(storeName);
  if (!currentStore || isEmpty(currentStore)) {
    createReflexStore();
  }

  window.localStorage.setItem(
    storeName,
    JSON.stringify({
      analytics: {
        ...defaultReflexStore.analytics,
        ...currentStore?.analytics,
        ...updateData.analytics,
      },
      preferences: {
        ...defaultReflexStore.preferences,
        ...currentStore?.preferences,
        ...updateData.preferences,
      },
    }),
  );
}

export const getPreferencesFromLocalStorage = () => {
  const data = getStore();
  return data?.preferences || {};
};
