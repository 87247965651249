import {
  capitalizeFirstLetter,
  filterNullsFromObject,
  filterUndefinedsFromObject,
  getEnumKeyByValue,
} from 'helpers';
import { graphRetailerLocation, LocationType } from 'types';
import { LocationsFilterInterface } from 'utils/context/reducers/locationsReducer';
import { FilterOption, LocationStatusOptions } from 'utils/types/filters';
import { arrayOfStringsParser } from './filters';
import { marketsParser } from './filtersMarkets';
import { retailersParser } from './filtersRetailers';

type convertedRetailerLocationsList = {
  locations: Array<FilterOption>;
};

/**
 * @function retailerLocationsOptionsParser
 * @summary convert GQL retailer location list data into a format expected by our filter list component
 * @param {Array<graphRetailerLocation>}
 * @returns {convertedRetailerLocationsList}
 */
export const retailerLocationsOptionsParser = (
  options: Array<graphRetailerLocation>,
): convertedRetailerLocationsList => {
  const convertedArr = options
    ?.map(location => ({
      label: `${location.retailer?.name} - ${location.name}`,
      value: location.uuid,
    }))
    .sort((a, b) => String(a.label).localeCompare(b.label));
  return { locations: convertedArr };
};

export const locationsActiveParser = (activeArray: Array<FilterOption>) => {
  if (!activeArray || activeArray.length === 0) return undefined;
  switch (activeArray[0].value) {
    // Type 'all' does not exist in backend. Backend returns all by default.
    case LocationStatusOptions.ACTIVE:
      return { isActive: true };
    case LocationStatusOptions.INACTIVE:
      return { isActive: false };
    case LocationStatusOptions.ALL:
    default:
      return undefined;
  }
};

export const locationsTypeParser = (activeArray: Array<FilterOption>) => {
  if (!activeArray || activeArray.length === 0) return undefined;
  return {
    types: activeArray.map(option =>
      getEnumKeyByValue(LocationType, option.value),
    ),
  };
};

export const locationsPaymentTypeParser = (
  activeArray: Array<FilterOption>,
) => {
  if (!activeArray || activeArray.length === 0) return undefined;
  return {
    paymentTypes: activeArray.map(option =>
      capitalizeFirstLetter(option.value.toLowerCase()),
    ),
  };
};

/**
 * @function locationsParser
 * @summary convert Front end locations filter values into GQL expected format
 * @param {Array<FilterOption>} array of filter options
 * @returns {Object} An object with a key of locations equal to uuid array
 */
export const locationsParser = locationsArray => {
  if (!locationsArray || locationsArray.length === 0) return undefined;
  return { locations: locationsArray.map(location => location.value) };
};

export const convertFilterLocationsOptionsToDataFilters = (
  filters: LocationsFilterInterface,
) => {
  const dataFilters = {
    ...marketsParser(filters.markets),
    ...retailersParser(filters.retailers),
    ...locationsTypeParser(filters.type),
    ...locationsPaymentTypeParser(filters.payment),
    ...arrayOfStringsParser('retailerSizes', filters.retailerSize),
    ...arrayOfStringsParser('retailerCategories', filters.retailerCategory),
    ...arrayOfStringsParser('tags', filters.tags),
    ...arrayOfStringsParser('accountOwner', filters.accountOwner, true),
    ...arrayOfStringsParser('status', filters.status),
  };
  return filterUndefinedsFromObject(filterNullsFromObject(dataFilters));
};
