import { CamelToSnakeCaseNested } from './_helpers';
import { uuid } from './common';

export enum PaymentContractTypeOptions {
  TYPE_W2 = 'W2',
  TYPE_1099 = '1099', // Comes back from Django as "A_1099"
  TYPE_INTERNAL = 'INTERNAL',
}

// Django by default prefixes number enum keys with "A_"
export enum BillingDayOfWeekOptions {
  A_0 = 'A_0',
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
  A_4 = 'A_4',
  A_5 = 'A_5',
  A_6 = 'A_6',
}

export enum BillingMethodOptions {
  IMMEDIATE = 'IMMEDIATE',
  SEND_INVOICE = 'SEND_INVOICE',
}

export enum BillingScheduleOptions {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIMONTHLY = 'BIMONTHLY',
  MONTHLY = 'MONTHLY',
}

export enum BillingTypeOptions {
  INTERNAL = 'INTERNAL',
  STRIPE = 'STRIPE',
}

export enum UrgentBookingFeeType {
  PRE_SHIFT_12 = 'PRE_12',
  PRE_SHIFT_24 = 'PRE_24',
  PRE_SHIFT_48 = 'PRE_48',
  PRE_SHIFT_72 = 'PRE_72',
}

export const UrgentBookingFeeLabels = {
  [UrgentBookingFeeType.PRE_SHIFT_12]: 'Less Than 12 Hours',
  [UrgentBookingFeeType.PRE_SHIFT_24]: 'Less Than 24 Hours',
  [UrgentBookingFeeType.PRE_SHIFT_48]: 'Less Than 48 Hours',
  [UrgentBookingFeeType.PRE_SHIFT_72]: 'Less Than 72 Hours',
} as const;

export enum CancellationFeeTypes {
  PRE_SHIFT_72 = 'PRE_72',
  PRE_SHIFT_24 = 'PRE_24',
  PRE_SHIFT_4 = 'PRE_4',
}

export const CancellationFeeLabels = {
  [CancellationFeeTypes.PRE_SHIFT_72]: '72 Hours Before Shift',
  [CancellationFeeTypes.PRE_SHIFT_24]: '24 Hours Before Shift',
  [CancellationFeeTypes.PRE_SHIFT_4]: '4 Hours Before Shift',
};

export type SimpleCancellationFees = {
  label?: CancellationFeeTypes;
  amount?: string; // percent
}[];

export type billingExtrasObject = {
  id?: uuid;
  planLabel?: string; // Reflex
  pricingLabel?: string; // Pricing
  extrasLabel?: string; // Additional Details
  planDetails?: Array<string>; // ['pay-per-use']
  extras?: Array<{
    title: string; // Setup Cost
    description?: string;
    amount?: number;
    type?: string | PlanExtrasTypes;
    timing?: string | PlanExtrasTiming;
  }>;
  cancellation_fees?: Record<CancellationFeeTypes, number>;
};

export type billingInvoiceMixin = {
  billingRate?: string;
  billingSchedule?: BillingScheduleOptions;
  billingType?: BillingTypeOptions;
  billingMethod?: BillingMethodOptions;
  billingDaysUntilDue?: number;
  billingCustomerId?: string;
  billingDayOfWeek?: BillingDayOfWeekOptions;
  billingApplyCcFee?: boolean;
  billingProcessingFee?: number;
  billingExtras?: string; // JSON blob billingExtrasObject
  billingUrgentBookingFee?: string;
  billingUrgentBookingType?: UrgentBookingFeeType;
};

export type apiBillingInvoiceMixin =
  CamelToSnakeCaseNested<billingInvoiceMixin>;

export enum PlanExtrasTypes {
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
  NONE = 'NONE',
}

export enum PlanExtrasTiming {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export enum PlanExtrasDetails {
  PAY_PER_USE = 'PAY_PER_USE',
  UP_FRONT_BILLING = 'UP_FRONT_BILLING',
}
