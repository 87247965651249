import { ActionMap } from 'types';
import { filters, FilterScopes } from 'utils/helpers/filters/filters.config';
import { FilterOption } from 'utils/types/filters';

export type FiltersStateInterface = {
  filterOptions: Record<FilterScopes, Record<string, Array<FilterOption>>>;
};

export const getOptionsOrDefaults = (selection: 'options' | 'default') =>
  Object.assign(
    {},
    ...Object.keys(filters).map(scope => ({
      [scope]: Object.assign(
        {},
        ...Object.keys(filters[scope]).map(key => ({
          [key]: filters[scope][key][selection],
        })),
      ),
    })),
  );

export const initialState = {
  filterOptions: getOptionsOrDefaults('options'),
};

export enum FiltersActions {
  RESET_FILTER = 'RESET_FILTER',
  RESET_ALL_FILTERS = 'RESET_ALL_FILTERS',
  UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS',
  CHANGE_FILTER = 'CHANGE_FILTER',
}

type FiltersPayload = {
  [FiltersActions.UPDATE_FILTER_OPTIONS]: {
    scope: FilterScopes;
    options: Record<string, Array<FilterOption>>;
  };
};

type FiltersActionsTypes =
  ActionMap<FiltersPayload>[keyof ActionMap<FiltersPayload>];

export const filtersReducer = (draft, action: FiltersActionsTypes) => {
  const { type } = action;
  switch (type) {
    case FiltersActions.UPDATE_FILTER_OPTIONS: {
      const { payload } = action;
      const { scope = FilterScopes.DYNAMIC, options } = payload;
      Object.keys(options).forEach(optionKey => {
        draft.filterOptions[scope][optionKey] = options[optionKey];
      });
      break;
    }
    default:
      return draft;
  }
  return draft;
};
