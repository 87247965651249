/**
 * For GLOBAL CONFIGS across ALL front end applications.
 * Any config that is used by two or more applications belongs in this file.
 * Configs that are aplication specific belong in their respective app folders.
 */

export const USER_TOKEN = 'reflex.token';

export const PageSizes = {
  five: 5,
  ten: 10,
  twentyFive: 25,
  fifty: 50,
  hundred: 100,
  twoHundredFifty: 250,
  fiveHundred: 500,
} as const;
