export const CREATE_TAG_MUTATION = `
  mutation CREATE_TAG_MUTATION (
    $name: String!
    $description: String
    $isListVisible: Boolean
  ) {
    createTag (
      name: $name
      description: $description
      isListVisible: $isListVisible
    ) {
      id
      ok
      messages { code }
    }
  }
`;

export const UPDATE_TAG_MUTATION = `
  mutation UPDATE_TAG_MUTATION (
    $id: Int
    $ids: [Int]
    $name: String
    $description: String
    $isListVisible: Boolean
    $isArchived: Boolean
    $addUsers: [String]
    $removeUsers: [String]
    $addLocations: [String]
    $removeLocations: [String]
    $addShifts: [String]
    $removeShifts: [String]
  ) {
    updateTag (
      id: $id
      ids: $ids
      name: $name
      description: $description
      isListVisible: $isListVisible
      isArchived: $isArchived
      addUsers: $addUsers
      removeUsers: $removeUsers
      addLocations: $addLocations
      removeLocations: $removeLocations
      addShifts: $addShifts
      removeShifts: $removeShifts
    ) {
      ok
      messages { code }
    }
  }
`;
