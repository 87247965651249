export type graphRetailerPlaybook = {
  isDefault?: boolean;
  playbook?: graphPlaybook;
};

export type graphActivePlaybook = {
  currentStep?: number;
  status?: PlaybookStatus;
};

export type graphPlaybook = {
  uuid?: string;
  name?: string;
  globalWait?: number;
  currentStep?: number;
  status?: PlaybookStatus;
  playbook?: graphPlaybook;
  steps?: graphPlaybookStep[];
};

export type graphPlaybookStep = {
  cohortName?: string;
  scheduledAt?: string;
  completedAt?: string;
  stepType?: StepType;
  timingPercentage?: number;
};

export enum StepType {
  CREATE_WSOS = 'CREATE_WSOS',
}

export enum PlaybookStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  STOPPED = 'STOPPED',
}
