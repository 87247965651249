import { QueryParamModal } from 'types/src/queryParams';
import { create } from 'zustand';

export { QueryParamModal };

interface ViewStore {
  modalOpen?: QueryParamModal;
  openModal: (QueryParamModal) => void;
  closeModal: () => void;
}

const viewStore = (set): ViewStore => ({
  modalOpen: null,
  openModal: (modal: QueryParamModal) =>
    set(
      {
        modalOpen: modal,
      },
      false,
      'openModal',
    ),
  closeModal: () =>
    set(
      {
        modalOpen: null,
      },
      false,
      'closeModal',
    ),
});

export const useViewStore = create(viewStore);
