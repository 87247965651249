export const NOTIFICATION_RULES_QUERY = `
  query NOTIFICATION_RULES_QUERY (
    $users: [String]!
  ) {
    notificationRules (
      users: $users
    ) {
      uuid
      startDate
      startTime
      endDate
      endTime
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
  }
`;
