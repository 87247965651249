export type PagingInfo = {
  page: number;
  page_count: number;
  page_size: number;
  total_count: number;
  all: Array<Record<string, unknown>>;
};

export interface PagingParams {
  page: number;
  pageSize: number;
  all?: Array<string>; // List of model properties
}

export const SEARCH_DEBOUNCE_TIMING = 1500;
