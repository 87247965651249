import { applyContext, mutateObject, Mutations, Queries } from 'helpers';
import {
  BillingMethodOptions,
  BillingScheduleOptions,
  BillingTypeOptions,
  GQL,
  paging,
  PaymentContractTypeOptions,
  ReflexResponse,
  uuid as uuidType,
} from 'types';

export interface fetchTermsProps extends paging {
  uuid?: uuidType;
  uuids?: Array<uuidType>;
  name?: string;
  locations?: Array<uuidType>;
}

export const fetchTerms = async (data: fetchTermsProps) =>
  await mutateObject({
    route: applyContext(GQL, 'TERMS_QUERY'),
    query: Queries.TERMS_QUERY,
    data,
    returnKey: ['terms', 'terms_paging'],
  });

export interface mutateTermsUpdateProps {
  terms: uuidType;
  customer?: uuidType;
  locations?: Array<uuidType>;
  paymentMethod?: uuidType;
  paymentType?: uuidType;
  status?: string;
  name?: string;
  activeFrom?: Date;
  activeTo?: Date;
}

export const mutateTermsUpdate = async (
  data: mutateTermsUpdateProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_Terms_MUTATION'),
    query: Mutations.UPDATE_TERMS_MUTATION,
    data,
    returnKey: 'updateTerms',
  });

export interface mutateTermsCreateProps {
  customer?: uuidType;
  name?: string;
  activeFrom?: Date;
  activeTo?: Date;
  autoRenew?: boolean;
  paymentType?: PaymentContractTypeOptions;
  paymentRate?: number;
  billingRate?: number;
  billingProcessingFee?: number;
  billingSchedule?: BillingScheduleOptions;
  billingType?: BillingTypeOptions;
  billingMethod?: BillingMethodOptions;
  billingDay?: number;
  billingDue?: number;
  billingExtras?: string; // JSON
}

export const mutateTermsCreate = async (data: mutateTermsCreateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_TERMS_MUTATION'),
    query: Mutations.CREATE_TERMS_MUTATION,
    data,
    returnKey: 'createTerms',
  });
