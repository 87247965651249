import { apiWorkerShiftEvent, review, tag, workerShiftEvent } from '..';
import { uuid } from './common';
import { communicationBatch } from './communicationBatch';
import { graphRetailerLocation, LocationType } from './location';
import { noteType } from './note';
import { graphPayment } from './payment';
import { graphPlaybook } from './playbook';
import { apiRetailer } from './retailer';
import { role } from './role';
import { shiftCriteria } from './shiftCriteria';
import { apiUserLight, graphUserFull, normalizedUserLight, user } from './user';
import { benefitAccrualEvent } from './workerBenefit';

export const DEFAULT_CONTACT_INTEGRATION = ''; // 2d66e00b43554094a2777b5664e8e743
export const DEFAULT_INFO_INTEGRATION = ''; // 2d66e00b43554094a2777b5664e8e743
export const DEFAULT_HOURLY_RATE = 16;

export enum ShiftStatus {
  MISSED = 'MISSED',
  FILLED = 'FILLED',
  FILLED_PARTIAL = 'FILLED_PARTIAL',
  OVERBOOKED = 'OVERBOOKED',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
}

export enum ShiftCancelClassification {
  RETAILER_CANCEL = 'RETAILER_CANCEL',
  STAFF_CANCEL = 'STAFF_CANCEL',
  UNFILLED = 'UNFILLED',
  OTHER = 'OTHER',
  NONE_SPECIFIED = 'NONE_SPECIFIED',
}

export const ShiftCancelClassificationLabel = {
  [ShiftCancelClassification.RETAILER_CANCEL]: [
    ShiftCancelClassification.RETAILER_CANCEL,
    'Retailer Cancel: Cancel fee',
  ],
  [ShiftCancelClassification.STAFF_CANCEL]: [
    ShiftCancelClassification.STAFF_CANCEL,
    'Reflex Cancel: No cancel fee',
  ],
  [ShiftCancelClassification.UNFILLED]: [
    ShiftCancelClassification.UNFILLED,
    'Reflex - Unfilled: No cancel fee',
  ],
  [ShiftCancelClassification.OTHER]: [
    ShiftCancelClassification.OTHER,
    'Reflex - Other: No cancel fee',
  ],
  [ShiftCancelClassification.NONE_SPECIFIED]: [
    ShiftCancelClassification.NONE_SPECIFIED,
    'None Specified',
  ],
};

export enum WorkerShiftStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  OVERBOOKED = 'OVERBOOKED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  INTERESTED = 'INTERESTED',
  // CONFIRMED = 'CONFIRMED',
  // MISSED = 'MISSED',
  // LATER: fully remove the above
}

export enum WorkerShiftConfirmationStatus {
  HOUR_24 = 'HOUR_24',
  HOUR_2 = 'HOUR_2',
  ARRIVAL = 'ARRIVAL',
}

export enum WorkerShiftCancelClassification {
  ADMIN_PULL = 'ADMIN_PULL',
  ADMIN_CANCEL = 'ADMIN_CANCEL',
  PARTNER_CANCEL = 'PARTNER_CANCEL',
  WORKER_CANCEL = 'WORKER_CANCEL',
  NONE_SPECIFIED = 'NONE_SPECIFIED',
}

export const WorkerShiftCancelClassificationLabel = {
  [WorkerShiftCancelClassification.ADMIN_PULL]: [
    WorkerShiftCancelClassification.ADMIN_PULL,
    'Admin Cancel: Penalty',
  ],
  [WorkerShiftCancelClassification.ADMIN_CANCEL]: [
    WorkerShiftCancelClassification.ADMIN_CANCEL,
    'Admin Cancel: No Penalty',
  ],
  [WorkerShiftCancelClassification.WORKER_CANCEL]: [
    WorkerShiftCancelClassification.WORKER_CANCEL,
    'Worker Cancel: Penalty',
  ],
  [WorkerShiftCancelClassification.PARTNER_CANCEL]: [
    WorkerShiftCancelClassification.PARTNER_CANCEL,
    'Partner Cancel: No Penalty',
  ],
  [WorkerShiftCancelClassification.NONE_SPECIFIED]: [
    WorkerShiftCancelClassification.NONE_SPECIFIED,
    'None Specified',
  ],
};

export enum ShiftAccessStatus {
  NONE = 'NONE',
  DATA = 'DATA_ERROR',
  MISSED_OWN = 'MISSED_OWN',
  MISSED_NOT_OWN = 'MISSED_OTHERS',
  FILLED_OWN = 'FILLED_OWN',
  FILLED_NOT_OWN = 'FILLED_OTHERS',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  COMPLETE_OWN = 'COMPLETE_OWN',
  COMPLETE_NOT_OWN = 'COMPLETE_OTHERS',
  INTEREST_SUCCESS = 'INTEREST_SUCCESS',
  INTEREST_SUCCESS_NO_CALL = 'INTEREST_SUCCESS_NO_CALL',
  ERROR_ACCEPT = 'ERROR_ACCEPT',
  ERROR_USER_FLAGGED = 'ERROR_USER_FLAGGED',
}

export enum ShiftPaymentType {
  TYPE_W2 = 'W2',
  TYPE_1099 = '1099',
  TYPE_INTERNAL = 'Internal',
}

// DEPRECATED, FUTURE: remove
export type apiRequiredBreak = {
  shift_minutes_to_require_break?: number;
  break_length?: number;
  paid?: boolean;
  blocks_payment?: boolean;
};

// DEPRECATED, FUTURE: remove
export type apiShiftDetail = {
  id?: number;
  uuid?: string;
  retailer?: Omit<apiRetailer, 'locations' | 'open_shifts'>;
  retailer_location?: number;
  status?: string;
  start?: string;
  end?: string;
  amount?: number;
  base_amount?: number;
  worker_roles?: Array<number>;
  description?: string;
  assigned_reflexer?: apiUserLight;
  assigned_reflexer_id?: number;
  info_integration?: string;
  contact_integration?: string;
  payment_type?:
    | ShiftPaymentType.TYPE_1099
    | ShiftPaymentType.TYPE_W2
    | ShiftPaymentType.TYPE_INTERNAL;
  retailer_location_type?: LocationType;
  retailer_location_name?: string;
  retailer_location_id?: number;
  retailer_location_uuid?: uuid;
  retailer_location_timezone?: string;
  retailer_location_address_text?: string;
  pending_review: number;
  pending_worker_review: number;
  workershift_events: apiWorkerShiftEvent[];
  required_break: apiRequiredBreak;
  shift_workers: Array<workershift>;
};

// DEPRECATED, FUTURE: remove
export type normalizedShift = {
  id: number;
  retailer: Omit<apiRetailer, 'locations' | 'open_shifts'>;
  locationId: number;
  status: string;
  startDate: Date;
  endDate: Date;
  payout: string;
  payoutBonus: string;
  payoutTotal: string;
  workerRoles: Array<number>;
  description: string;
  worker: normalizedUserLight;
  workerId: number;
  contactId: string;
  infoId: string;
  paymentType:
    | ShiftPaymentType.TYPE_1099
    | ShiftPaymentType.TYPE_W2
    | ShiftPaymentType.TYPE_INTERNAL;
  retailer_location_type: LocationType;
  retailer_location_name: string;
  retailer_location_id: number;
  retailer_location_uuid: uuid;
  retailer_location_timezone: string;
  retailer_location_address_text: string;
  pending_review: number;
  pending_worker_review: number;
  uuid: string;
  workershift_events: apiWorkerShiftEvent[];
  required_break: apiRequiredBreak;
  shift_workers: Array<workershift>;
};

// DEPRECATED, FUTURE: remove
export type apiShiftsPaginated = {
  count: number;
  next?: string;
  previous?: string;
  results: Array<apiShiftDetail>;
};

// DEPRECATED, FUTURE: remove
export type normalizedShiftsPaginated = {
  count: number;
  next?: string;
  previous?: string;
  results: Array<normalizedShift>;
};

// FUTURE: remove this, use workershift instead
export type graphShiftWorker = {
  uuid: string;
  confirmationStatus?: string;
  shift?: graphShift;
  status?: WorkerShiftStatus;
  viewCount?: number;
  messageCount?: number;
  cancelNote: string;
  cancelClassification: WorkerShiftCancelClassification;
  cancellationPenalty?: number;
  worker?: graphUserFull;
};

export type graphUserAndWorkershift = graphUserFull & {
  workerShift?: workershift;
};

export enum ShiftRequestType {
  DEFAULT = 'DEFAULT',
  FAVORITES = 'FAVORITES',
  REPEATS = 'REPEATS',
  LIST = 'LIST',
}

export enum ShiftRequestDetails {
  USERS = 'request_users',
}

export type graphShift = {
  acceptedCount?: number;
  acceptLimit?: number;
  actionableReasons?: string;
  activePlaybook?: graphPlaybook;
  assignedWorkers?: Array<graphUserAndWorkershift>;
  benefitAccrualEvents?: Array<benefitAccrualEvent>;
  billingDiscount?: number;
  cancelClassification?: ShiftCancelClassification;
  cancelledWorkers?: Array<graphUserAndWorkershift>;
  cancelReason?: string;
  createdAt?: string; // Datetime
  communicationBatches?: communicationBatch[];
  description?: string;
  duration?: number;
  end?: string;
  flexibleEndTime?: boolean;
  flexibleStartTime?: boolean;
  id?: number;
  isCancelled?: boolean;
  isRetailerApproved?: boolean;
  market?: string;
  notes?: noteType[];
  overbookedWorkers?: Array<graphUserAndWorkershift>;
  overbookLimit?: number;
  payments?: Array<graphPayment>;
  paymentType?: ShiftPaymentType;
  pendingReviews?: Array<review>;
  requestDetails?: Partial<Record<ShiftRequestDetails, unknown>>;
  requestType?: ShiftRequestType;
  retailerLocation?: graphRetailerLocation;
  retailerNotes?: string;
  reviews?: Array<review>;
  shiftCriterias?: shiftCriteria[];
  shiftWorkers?: Array<graphShiftWorker>;
  start?: string;
  status?: ShiftStatus;
  tags?: Array<tag>;
  updatedAt?: string; // Datetime
  uuid?: string;
  visibleWorkerCount?: number;
  workerRoles?: Array<role>;
};

export type shift = graphShift;

export type workershift = {
  breakDurationMinutes?: number;
  cancelClassification?: WorkerShiftCancelClassification;
  cancellationPenalty?: number;
  cancelNote?: string;
  cancellationTime?: string; // DateTime
  checkInTime?: string; // DateTime
  checkOutTime?: string; // DateTime
  createdAt?: string; // DateTime
  confirmationStatus?: string;
  hasCompletedCompliancePaperwork?: boolean;
  lastViewed?: string; // DateTime
  messageCount?: number;
  shift?: graphShift;
  status?: WorkerShiftStatus;
  updatedAt?: string; // DateTime
  uuid?: uuid;
  viewCount?: number;
  worker?: user;
  workershiftEvents?: workerShiftEvent[];
};

export enum ShiftActionErrorCode {
  FLAGGED = 'Flagged User',
  COMPLETED = 'Flex Completed',
  ACTIVE = 'Must be active',
  WEEKLY_LIMIT = 'Exceeds weekly limit',
  CANCELLED = 'Cancelled',
  CONFLICTING_SHIFT = 'Conflicting shift',
  ERROR = 'Error',
}
