export const ENTER_WORKFLOW_MUTATION = `
  mutation ENTER_WORKFLOW_MUATION(
    $workflowUuid: String,
    $targetUuids: [String]
  ) {
    enterWorkflow(
      workflowUuid: $workflowUuid,
      targetUuids: $targetUuids
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
