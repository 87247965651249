import {
  ActionableShiftInputs,
  getEnumKeyByValue,
  snakeToCamel,
} from 'helpers';
import {
  FilterOption,
  ShiftPaperworkFilters,
  ShiftStatusFilters,
} from 'utils/types/filters';
import { ControlOption } from 'components/common/controls/control.helpers';
import {
  actionableDefaults,
  ActionableTypes,
} from 'components/shifts/ActionShifts/ActionShiftsStore.helpers';

interface shiftStatusFilterObject {
  status: string[];
}

interface shiftPaperworkGQLFilterObject {
  hasUnfinishedPaperwork: boolean;
}

/**
 * @function shiftStatusParser
 * @summary normalize frontend filter status options into GQL expected format
 * @param {Array<FilterOption>} status array of ShiftStatusFilters
 * @returns {shiftStatusFilterObject}
 */
export const shiftStatusParser = (
  status: Array<FilterOption>,
): shiftStatusFilterObject => {
  const statusValues = status.map(indvStatus =>
    indvStatus?.value.toUpperCase(),
  );

  // Type 'all' does not exist in backend. Backend returns all by default.
  if (statusValues.includes(ShiftStatusFilters.ALL.toUpperCase())) {
    return { status: null };
  }

  return { status: statusValues };
};

/**
 * @function shiftsPaperworkParser
 * @summary normalize frontend filter paperwork options into GQL expected format
 * @param {Array<FilterOption>} paperwork array of ShiftPaperworkFilters
 * @returns {shiftPaperworkGQLFilterObject}
 */
export const shiftsPaperworkParser = (
  paperwork: Array<FilterOption>,
): shiftPaperworkGQLFilterObject => {
  const paperworkOption = paperwork[0].value; // Only handle one option at a time. Not a multiselect
  switch (paperworkOption) {
    case ShiftPaperworkFilters.ALL:
      return { hasUnfinishedPaperwork: null };
    case ShiftPaperworkFilters.COMPLETE:
      return { hasUnfinishedPaperwork: false };
    case ShiftPaperworkFilters.INCOMPLETE:
      return { hasUnfinishedPaperwork: true };
  }
};

export const actionableParser = (
  options: Array<ControlOption>,
): ActionableShiftInputs => {
  if (!options || options?.length === 0) return actionableDefaults;
  const firstOption = options[0];
  if (
    firstOption.value ===
    getEnumKeyByValue(ActionableTypes, ActionableTypes.ALL)
  )
    return actionableDefaults;

  return Object.keys(actionableDefaults).reduce((acc, key) => {
    if (key === snakeToCamel(firstOption.value.toString())) {
      acc[key] = true;
    } else {
      acc[key] = false;
    }
    return acc;
  }, {});
};
