import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const CAMPAIGNS_LIST_QUERY = `
  query CAMPAIGNS_LIST_QUERY (
    $markets: [String]
    $uuid: String
    ${_PAGING_PARAMS}
  ) {
    acquisitionCampaigns (
      markets: $markets
      uuid: $uuid
      ${_PAGING_VARIABLES}
    ) {
      name
      createdAt
      uuid
      market {
        name
        id
      }
      medium
      description
      campaignStart
      campaignEnd
      totalSpend
      totalImpressions
      totalConversions
      totalClicks
      attributionCodes {
        name
        queryString
        uuid
      }

    }
  }
`;

export const CAMPAIGNS_ITEM_QUERY = `
  query CAMPAIGNS_ITEM_QUERY (
    $markets: [String]
    $uuid: String
    ${_PAGING_PARAMS}
  ) {
    acquisitionCampaigns (
      markets: $markets
      uuid: $uuid
      ${_PAGING_VARIABLES}
    ) {
      name
      createdAt
      uuid
      market {
        name
        id
      }
      medium
      description
      campaignStart
      campaignEnd
      records {
        recordDate
        spendAmount
        impressions
        clicks
        id
      }
      attributionCodes {
        name
        queryString
        uuid
      }
    }
  }
`;

export const CAMPAIGNS_SIMPLE_QUERY = `
  query CAMPAIGNS_LIST_QUERY (
    $markets: [String]
    $uuid: String
  ) {
    acquisitionCampaigns (
      markets: $markets
      uuid: $uuid
    ) {
      name
      uuid
    }
  }
`;

export const ATTRIBUTION_CODE_QUERY = `
  query ATTRIBUTION_CODE_QUERY (
    $missingCampaign: Boolean
    ${_PAGING_PARAMS}
  ) {
    attributionCodes (
      missingCampaign: $missingCampaign
      ${_PAGING_VARIABLES}
    ) {
        name
        queryString
        uuid
        createdAt
        acquisitionCampaign {
            name
            uuid
        }
    }
  }
`;
