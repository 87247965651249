import { useEffect, useMemo, useState } from 'react';
import { getStore, REFLEX_STORE } from 'helpers';
import { ReflexOpsStore, updateStore } from 'utils/helpers/localStorage';
import { ActionOrigin, actionOriginRouteMap } from 'utils/types/origins';

interface AnalyticsInterface {
  activePage?: ActionOrigin;
  store?: ReflexOpsStore;
  updateActivePage?: (page: ActionOrigin) => void;
  updateActivePageFromUrl?: (url: string) => void;
}

export const useAnalytics = (): AnalyticsInterface => {
  const [currentStore, setCurrentStore] = useState<ReflexOpsStore>({});
  const trackedStore = useMemo(() => getStore(REFLEX_STORE) || {}, []);

  useEffect(() => {
    setCurrentStore(trackedStore);
  }, [trackedStore]);

  const updateActivePage = (page: ActionOrigin) => {
    updateStore({
      analytics: {
        activePage: page,
      },
    });
  };

  const updateActivePageFromUrl = (url: string) => {
    // nextjs weirdness...
    // url is router.pathname on page load (ex. /worker/[uuid])
    // url is full path on path change, in this case, strip query param and replace uuid string
    const uuidRegex =
      /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    const path = url.split('?')[0].replace(uuidRegex, '[uuid]');
    updateActivePage(actionOriginRouteMap[path]);
  };

  return {
    activePage: currentStore?.analytics?.activePage,
    store: currentStore,
    updateActivePage,
    updateActivePageFromUrl,
  };
};
