import { flatten, partition } from 'lodash';
import { market as marketType } from 'types';
import { MarketSelectOption } from 'types/src/form';

type MarketInfo = {
  isActive: boolean;
  hasLiveLocations: boolean;
  isGroup?: boolean;
};

type convertedMarketList = {
  markets: Array<MarketSelectOption & MarketInfo>;
};

/**
 * @function marketOptionsParser
 * @summary convert GQL market list data into a format expected by our filter list component
 * @param {Array<marketType>}
 * @returns {convertedMarketList}
 */
export const marketOptionsParser = (
  options: Array<marketType>,
): convertedMarketList => {
  const convertedArr = options?.map(market => ({
    label: `${`${market?.name}${market?.state ? `, ${market.state}` : ''}`}`,
    value: market.id.toString(),
    isActive: !!market.isActive,
    hasLiveLocations: market?.hasLiveLocations,
    isGroup: market?.markets?.length > 0,
  }));
  const partitionedArr = partition(
    convertedArr,
    option => option.isActive && option.hasLiveLocations,
  );
  const sortedArr = partitionedArr.map(arr =>
    arr.sort((a, b) => String(a.label).localeCompare(b.label)),
  );
  return { markets: flatten(sortedArr) };
};

/**
 * @function marketsParser
 * @summary convert Front end markets filter values into GQL expected format
 * @param {Array<FilterOption>} array of filter options
 * @returns {Object} An object with a key of markets equal to ID's array
 */
export const marketsParser = marketsArray => {
  if (!marketsArray || marketsArray.length === 0) return undefined;
  return { markets: marketsArray.map(market => market.value) };
};
