export const ADD_LOCATION_PERMISSIONS = `
  mutation ADD_LOCATION_PERMISSIONS(
    $users: [String]
    $locations: [String]
    $permissionTypes: [String]
  ) {
    addLocationPermissions(
      users: $users
      locations: $locations
      permissionTypes: $permissionTypes
    ) {
      ok
      messages {
        code
        
      }
    }
  }
`;

export const REMOVE_LOCATION_PERMISSIONS = `
  mutation REMOVE_LOCATION_PERMISSIONS(
    $users: [String]
    $locations: [String]
    $permissionTypes: [String]
  ) {
    removeLocationPermissions(
      users: $users
      locations: $locations
      permissionTypes: $permissionTypes
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
