export interface FormState {
  isProcessing: boolean;
  isSuccess: boolean;
  errors?: Array<any>;
}

export const formStateDefaults = {
  isProcessing: false,
  isSuccess: false,
  errors: [],
};

export type SelectOption = {
  label?: string;
  value?: string;
  isFixed?: boolean;
  isActive?: string | boolean;
  isDisabled?: boolean;
};

export type PushNotificationScreenOption = Omit<SelectOption, 'value'> & {
  value?: string | Record<string, unknown>;
};

export type MarketSelectOption = SelectOption & {
  hasLiveLocations?: string | boolean;
};

export type CheckboxOption = {
  id: string | number;
  name: string;
  icon?: string;
};

export interface MenuOption {
  action?: () => void;
  label: JSX.Element;
  value?: unknown;
}
