import { _SHIFT_CRITERIA_FIELDS } from './shiftCriteria';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _SHIFTS_PARAMS = `
  $excludeStatus: [String]
  $hasUnfinishedPaperwork: Boolean
  $isRetailerApproved: Boolean
  $limit: Int
  $locations: [String]
  $locationTags: [String]
  $market: Int
  $markets: [Int]
  $retailerCategories: [String]
  $retailers: [String]
  $retailerSizes: [String]
  $since: Date
  $sinceDatetime: DateTime
  $status: [String]
  $tags: [String]
  $to: Date
  $toDatetime: DateTime
  $uuid: String
  $uuids: [String]
  ${_PAGING_PARAMS}
`;
const _SHIFTS_VARIABLES = `
  excludeStatus: $excludeStatus
  hasUnfinishedPaperwork: $hasUnfinishedPaperwork
  isRetailerApproved: $isRetailerApproved
  limit: $limit
  locations: $locations
  locationTags: $locationTags
  market: $market
  markets: $markets
  retailerCategories: $retailerCategories
  retailers: $retailers
  retailerSizes: $retailerSizes
  since: $since
  sinceDatetime: $sinceDatetime
  status: $status
  tags: $tags
  to: $to
  toDatetime: $toDatetime
  uuid: $uuid
  uuids: $uuids
  ${_PAGING_VARIABLES}
`;

const _SHIFTS_WORKER_FIELDS_EXTERNAL = `
  firstName
  lastName
  preferredName
  uuid
  profileImg
  hasWorkedAtLocation
  favoritedBy {
    uuid
  }
  approvedBy {
    uuid
  }
  blockedBy {
    uuid
  }
  workerShift {
    workershiftEvents {
      eventType
      timestamp
      approved
    }
    uuid
  }
  hasWorkedAtLocation
`;

export const RETAILER_SHIFTS_LIST_QUERY = `
  query SHIFT_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      createdAt
      start
      end
      duration
      isRetailerApproved
      requestType
      workerRoles {
        name
        icon
      }
      status
      retailerLocation {
        name
        timezone
        uuid
        retailer {
          name
        }
        compliance {
          breakRules {
            shiftMinutesToRequireBreak
            breakLength
            paid
          }
          retailerPreFlexCancelDisclaimers {
            title
            body
          }
        }
        terms {
          billingRate
          cancellationFee {
            amounts {
              timing
              amount
              unit
            }
          }
        }
      }
      assignedWorkers{
        ${_SHIFTS_WORKER_FIELDS_EXTERNAL}
      }
      pendingReviews {
        id
        uuid
      }
    }
  }
`;

export const RETAILER_REPORTS_SHIFTS_QUERY = `
  query RETAILER_REPORTS_SHIFTS_QUERY (
    $since: Date
    $to: Date
    $locations: [String]
    $isRetailerApproved: Boolean
  ) {
    shifts (
      since: $since
      to: $to
      locations: $locations
      isRetailerApproved: $isRetailerApproved
    ) {
      id
      uuid
      status
      start
      end
      duration
      billingDiscount
      assignedWorkers{
        uuid
        workerShift {
          uuid
          workershiftEvents {
            timestamp
            eventType
          }
        }
      }
      retailerLocation{
        uuid
        timezone
        retailer {
          uuid
        }
      }
    }
  }
`;

const _SHIFTS_WORKER_FIELDS = `
  firstName
  lastName
  preferredName
  uuid
  profileImg
  isI9Verified
  isW2PayproviderComplete
  onboardedBy {
    uuid
  }
  is1099PayproviderComplete
  isBackcheckComplete
  hoursThisWeek
  totalShiftsCompleted
  isCurrentlyWorking
  tier
  isFlagged
  workerShift {
    hasCompletedCompliancePaperwork
    confirmationStatus
    workershiftEvents {
      eventType
      expectedTimeRangeStart
      expectedTimeRangeEnd
      timestamp
      approved
      lat
      lon
      meta
      distanceApproved
    }
    status
    uuid
  }
  hasWorkedAtLocation
  notificationPreferences {
    isPushNotificationEnabled
  }
  isFavorite
  isApproved
  isBlocked
`;

export const SHIFTS_QUERY = `
  query SHIFTS_QUERY(
    $market: Int
    $markets: [Int]
    $uuid: String
    $uuids: [String]
    $since: Date
    $to: Date
    $status: [String]
    $limit: Int
    $retailers: [String]
    $locations: [String]
    $hasUnfinishedPaperwork: Boolean
    $retailerSizes: [String]
    $retailerCategories: [String]
    $tags: [String]
    $locationTags: [String]
    $excludeStatus: [String]
    $isRetailerApproved: Boolean
    $requestType: [String]
    $pageSize: Int
    $page: Int
    $orderBy: String
    $search: String
  ) {
    shifts(
      market: $market
      markets: $markets
      uuid: $uuid
      uuids: $uuids
      since: $since
      to: $to
      status: $status
      hasUnfinishedPaperwork: $hasUnfinishedPaperwork
      limit: $limit
      retailers: $retailers
      locations: $locations
      retailerSizes: $retailerSizes
      retailerCategories: $retailerCategories
      tags: $tags
      locationTags: $locationTags
      excludeStatus: $excludeStatus
      isRetailerApproved: $isRetailerApproved
      requestType: $requestType
      pageSize: $pageSize
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      id
      uuid
      isCancelled
      status
      duration
      paymentType
      flexibleStartTime
      flexibleEndTime
      visibleWorkerCount
      acceptedCount
      acceptLimit
      isRetailerApproved
      assignedWorkers{
        ${_SHIFTS_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_SHIFTS_WORKER_FIELDS}
      }
      retailerLocation{
        uuid
        name
        type
        timezone
        compliance {
          breakRules {
            shiftMinutesToRequireBreak
          }
        }
        retailer {
          uuid
          name
          logoImage
        }
        market {
          name
          abbreviation
        }
      }
      start
      end
      requestType
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const LOCATION_SHIFTS_METRICS_QUERY = `
  query SHIFTS_QUERY(
    $since: Date
    $to: Date
    $status: [String]
    $locations: [String]
  ) {
    shifts(
      since: $since
      to: $to
      status: $status
      locations: $locations
    ) {
      uuid
      workerRoles{
        name
      }
      start
    }
  }
`;

export const SHIFT_QUERY = `
  query SHIFT_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      flexibleStartTime
      flexibleEndTime
      retailerNotes
      description
      requiredBreak {
        breakLength
      }
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
      assignedWorkers{
        location
        firstName
        lastName
        preferredName
        onboardedBy {
          uuid
        }
        workerShift {
          hasCompletedCompliancePaperwork
          confirmationStatus
          workershiftEvents {
            eventType
            timestamp
            approved
            expectedTimeRangeStart
            expectedTimeRangeEnd
            noteCount
            lat
            lon
            userConfirmed
            distanceApproved
            meta
            notes {
              uuid
              content
              createdBy {
                firstName
                lastName
              }
              createdAt
              extraData
            }
          }
          status
          uuid
        }
        uuid
        profileImg
        isW2PayproviderComplete
        is1099PayproviderComplete
        isI9Verified
        isBackcheckComplete
        hoursThisWeek
        totalShiftsCompleted
        tier
        hasWorkedAtLocation
        notificationPreferences {
          isPushNotificationEnabled
        }
      }
      overbookLimit
      overbookedWorkers {
        uuid
        firstName
        lastName
        preferredName
        onboardedBy {
          uuid
        }
        workerShift {
          confirmationStatus 
          hasCompletedCompliancePaperwork
          status
          uuid
          workershiftEvents {
            eventType
            timestamp
            approved
            expectedTimeRangeStart
            expectedTimeRangeEnd
            noteCount
            lat
            lon
            notes {
              uuid
              content
              createdBy {
                firstName
                lastName
              }
              createdAt
              extraData
            }
          }
        }
        uuid
        profileImg
        isW2PayproviderComplete
        is1099PayproviderComplete
        isI9Verified
        isBackcheckComplete
        hoursThisWeek
        totalShiftsCompleted
        tier
        hasWorkedAtLocation
      }
      payments {
        status
        rate
        quantity
        type
        id
      }
      workerRoles {
        id
        name
        icon
      }
      retailerLocation {
        uuid
        name
        type
        timezone
        phoneNumber
        compliance {
          breakRules {
            shiftMinutesToRequireBreak
          }
        }
        market {
          name
          id
        }
        address {
          zip
        }
        retailer {
          uuid
          name
          logoImage
        }
      }
      shiftWorkers {
        uuid
        viewCount
        messageCount
        status
        cancelNote
        cancelClassification
        hasCompletedCompliancePaperwork
        confirmationStatus
        worker {
          uuid
        }
      }
      activePlaybook{
        currentStep
        status
        playbook {
          uuid
          name
          globalWait
          steps {
            cohortName
            scheduledAt
            completedAt
            stepType
            timingPercentage
          }
        }
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const SHIFT_ID_QUERY = `
  query SHIFT_QUERY (
    $uuid: String
    $id: Int
  ) {
    shifts (
      uuid: $uuid
      id: $id
    ) {
      id
      uuid
    }
  }
`;

export const SHIFT_EDIT_FORM_QUERY = `
  query SHIFT_QUERY (
    $uuid: String
  ) {
    shifts (
      uuid: $uuid
    ) {
      id
      uuid
      start
      end
      description
      overbookLimit
      flexibleStartTime
      flexibleEndTime
      retailerLocation {
        timezone
      }
    }
  }
`;

export const SHIFTS_LIST_QUERY_REFLEXERS = `
  query SHIFTS_LIST_QUERY_REFLEXERS (
    $since: Date
    $to: Date
    $locations: [String]
    $status: [String]
  ) {
    shifts (
      since: $since
      to: $to
      locations: $locations
      status: $status
    ) {
      id
      uuid
      isCancelled
      status
      start
      end
      duration
      billingDiscount
      pendingReviews {
        id
        worker {
          uuid
        }
        status
      }
      assignedWorkers{
        firstName 
        lastName
        preferredName
        uuid
        profileImg
        tier
        eritId
      }
      retailerLocation{
        uuid
        name
        timezone
        retailer {
          uuid
          name
        }
      }
    }
  }
`;

export const ShiftQueries = {
  full: SHIFT_QUERY,
  editForm: SHIFT_EDIT_FORM_QUERY,
};

const _WORKER_SHIFT_EVENT_FIELDS = `
  eventType
  timestamp
  approved
  expectedTimeRangeStart
  expectedTimeRangeEnd
  noteCount
  lat
  lon
  userConfirmed
  distanceApproved
  verificationImg
  meta
  notes {
    uuid
    content
    createdBy {
      firstName
      lastName
    }
    createdAt
    extraData
  }
`;

const _WORKER_FIELDS = `
  uuid
  firstName
  lastName
  preferredName
  workerShift {
    confirmationStatus 
    hasCompletedCompliancePaperwork
    status
    uuid
    workershiftEvents {
      ${_WORKER_SHIFT_EVENT_FIELDS}
    }
  }
  profileImg
  isW2PayproviderComplete
  is1099PayproviderComplete
  isI9Verified
  isBackcheckComplete
  onboardedBy {
    uuid
  }
  hoursThisWeek
  totalShiftsCompleted
  tier
  hasWorkedAtLocation
  notificationPreferences {
    isPushNotificationEnabled
  }
`;

const _WORKER_FIELDS_CANCELLATION = `
  uuid
  firstName
  lastName
  preferredName
  workerShift {
    status
    uuid
    cancelClassification
    cancelNote
    cancellationTime
  }
  profileImg
`;

const _NOTES_FIELDS = `
  uuid
  content
  updatedAt
  type
  targetUuid
  createdBy {
    firstName
    lastName
  }
`;

const _LOCATION_FIELDS = `
  uuid
  name
  type
  timezone
  phoneNumber
  compliance {
    breakRules {
      shiftMinutesToRequireBreak
    }
  }
  market {
    name
    id
  }
  address {
    zip
  }
  retailer {
    uuid
    name
    logoImage
  }
`;

const _PLAYBOOK_FIELDS = `
  currentStep
  status
  playbook {
    uuid
    name
    globalWait
    steps {
      cohortName
      scheduledAt
      completedAt
      timingPercentage
    }
  }
`;

const _COMMUNICATION_BATCH_FIELDS = `
  createdAt
  name
  meta
  sentBy {
    firstName
    lastName
  }
  pushCount
  textCount
`;

const _PAYMENT_FIELDS = `
  status
  rate
  quantity
  type
  id
`;

const _RETAILER_LOCATION_CANCEL_INFO_FIELDS = `
  payRate
  compliance {
    cancellationPayout {
      amounts {
        timing
        amount
        unit
      }
    }
  }
  terms {
    billingRate
    cancellationFee {
      amounts {
        timing
        amount
        unit
      }
    }
  }
`;

export const SHIFT_CORE_QUERY = `
   query SHIFT_CORE_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      flexibleStartTime
      flexibleEndTime
      retailerNotes
      description
      requiredBreak {
        breakLength
      }
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      payments {
        status
        rate
        quantity
        type
      }
      workerRoles {
        id
        name
        icon
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
      }
      activePlaybook{
        ${_PLAYBOOK_FIELDS}
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const SHIFT_DETAILS_QUERY = `
   query SHIFT_DETAILS_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      flexibleStartTime
      flexibleEndTime
      retailerNotes
      description
      isRetailerApproved
      requiredBreak {
        breakLength
      }
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      payments {
        status
        rate
        quantity
        type
      }
      workerRoles {
        id
        name
        icon
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
        ${_RETAILER_LOCATION_CANCEL_INFO_FIELDS} 
      }
      activePlaybook{
        ${_PLAYBOOK_FIELDS}
      }
      shiftCriterias {
        ${_SHIFT_CRITERIA_FIELDS}
      }
      communicationBatches {
        ${_COMMUNICATION_BATCH_FIELDS}
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
      requestType
      requestDetails
    }
  }
`;

export const SHIFT_NOTES_QUERY = `
  query SHIFT_NOTES_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      notes {
        ${_NOTES_FIELDS}
      }
    }
  }
`;

export const SHIFT_PAYMENTS_QUERY = `
  query SHIFT_NOTES_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      payments {
        ${_PAYMENT_FIELDS}
      }
    }
  }
`;

export const SHIFT_EVENTS_QUERY = `
  query SHIFT_QUERY (
    $uuid: String
    $workershiftStatus: [String]
  ) {
    shifts (
      uuid: $uuid
      workershiftStatus: $workershiftStatus
    ) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      requiredBreak {
        breakLength
      }
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
      }
    }
  }
`;

const _USER_SHIFTS_PARAMS = `
  $id: Int
  $users: [String]
  $userWorkershiftStatus: [String]
  $excludeUserWorkershiftStatus: [String]
  $since: Date
  $to: Date
  $status: [String]
  $excludeStatus: [String]
  $retailers: [String]
  $locations: [String]
  $market: Int
  $markets: [Int]
  $pageSize: Int
  $page: Int
  $orderBy: String
  $search: String
`;
const _USER_SHIFTS_VARIABLES = `
  id: $id
  users: $users
  userWorkershiftStatus: $userWorkershiftStatus
  excludeUserWorkershiftStatus: $excludeUserWorkershiftStatus
  since: $since
  to: $to
  status: $status
  excludeStatus: $excludeStatus
  retailers: $retailers
  locations: $locations
  market: $market
  markets: $markets
  pageSize: $pageSize
  page: $page
  orderBy: $orderBy
  search: $search
`;
const _USER_SHIFT_CORE = `
  uuid
  start
  end
  status
  acceptedCount
  acceptLimit
  overbookLimit
  paymentType
  retailerLocation {
    name
    type
    timezone
    retailer {
      logoImage
      name
    }
  }
`;
const _USER_SHIFT_REVIEWS = `
  reviews {
    id
    prioritization
    recommended
    rating
    status
    reviewType
  }
`;
const _USER_SHIFT_ASSIGNED_AND_OVERBOOKED = `
  assignedWorkers {
    ${_WORKER_FIELDS}
  }
  overbookedWorkers {
    ${_WORKER_FIELDS}
  }
`;
const _USER_SHIFT_PAST_CANCELLATIONS = `
  cancelledWorkers {
    ${_WORKER_FIELDS_CANCELLATION}
  }
`;

export const SHIFTS_FOR_USER_OZ_QUERY = `
  query SHIFT_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
    }
  }
`;

export const SHIFTS_FOR_USER_ACTIVE_OZ_QUERY = `
  query SHIFT_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_ASSIGNED_AND_OVERBOOKED}
    }
  }
`;

export const SHIFTS_FOR_USER_VISIBILITY_OZ_QUERY = `
  query SHIFT_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
    }
  }
`;

export const SHIFTS_FOR_USER_PAST_OZ_QUERY = `
  query SHIFT_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_REVIEWS}
    }
  }
`;

export const SHIFTS_FOR_USER_CANCELLED_OZ_QUERY = `
  query SHIFT_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_PAST_CANCELLATIONS}
    }
  }
`;

export const RETAILER_REQUEST_SHIFTS_SUCCESS_QUERY = `
  query SHIFTS_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      workerRoles{
        name
        icon
      }
      retailerLocation {
        name
        timezone
        terms {
          billingRate
          cancellationFee {
            amounts {
              timing
              amount
              unit
            }
          }
        }
        compliance {
          retailerPreFlexCancelDisclaimers {
            title
            body
          }
        }
      }
    }
  }
`;

export const RETAILER_REQUEST_SHIFTS_CALENDAR_QUERY = `
  query SHIFTS_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      isRetailerApproved
      workerRoles{
        name
        icon
      }
      retailerLocation {
        timezone
      }
      assignedWorkers {
        uuid
        profileImg
      }
    }
  }
`;

// ActionableShiftInput
// missingPaperwork: Boolean
// missingClockin: Boolean
// missingClockout: Boolean
// missingT2: Boolean
// stillOverbooked: Boolean
// missingMobile: Boolean
// missingT24: Boolean
// stillUnfilled: Boolean
// outOfBounds: Boolean
export const ACTION_SHIFTS_QUERY = `
  query ACTION_SHIFTS_QUERY(
    $markets: [Int]
    $since: Date
    $to: Date
    $actionable: ActionableShiftInput
    $pageSize: Int
    $page: Int
    $orderBy: String
    $search: String
  ) {
    shifts(
      markets: $markets
      since: $since
      to: $to
      actionable: $actionable
      pageSize: $pageSize
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      id
      uuid
      actionableReasons
      status
      isCancelled
      isRetailerApproved
      duration
      paymentType
      flexibleStartTime
      flexibleEndTime
      visibleWorkerCount
      requestType
      acceptedCount
      acceptLimit
      assignedWorkers{
        ${_SHIFTS_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_SHIFTS_WORKER_FIELDS}
      }
      retailerLocation{
        uuid
        name
        type
        timezone
        compliance {
          breakRules {
            shiftMinutesToRequireBreak
          }
        }
        retailer {
          uuid
          name
          logoImage
        }
        market {
          name
          abbreviation
        }
      }
      start
      end
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

const _SHIFT_WORKER = `
  uuid
  profileImg
  workerShift {
    confirmationStatus
    status
    uuid
    workershiftEvents {
      expectedTimeRangeStart
      expectedTimeRangeEnd
      timestamp
      eventType
      approved
    }
  }
`;

export const SHIFTS_FOR_WORKER_OPEN_QUERY = `
  query SHIFTS_FOR_WORKER_OPEN_QUERY (${_USER_SHIFTS_PARAMS}) {
    shifts (${_USER_SHIFTS_VARIABLES}) {
      uuid
      id
      start
      end
      duration
      status
      description
      pendingReviews {
        id
        worker {
          uuid
        }
        status
        reviewType
      }
      workerRoles {
        name
        icon
      }
      paymentType
      payments {
        rate
        quantity
        type
      }
      assignedWorkers {
        ${_SHIFT_WORKER}
      }
      overbookedWorkers {
        ${_SHIFT_WORKER}
      }
      retailerLocation {
        uuid
        name
        type
        timezone
        mapHref
        mapImg
        retailerSheetHref
        useV2FlexDetails
        compliance {
          name
          shiftStartDelta
          shiftGapDelta
          maxShiftsPerDay
          maxHoursPerDay
          shiftMaxLength
          shiftMinLength
          preFlexViewDisclaimers {
            uuid
            name
            title
            body
          }
          preFlexClaimDisclaimers {
            uuid
            name
            title
            body
          }
          postFlexClaimDisclaimers {
            uuid
            name
            title
            body
          }
          breakRules {
            shiftMinutesToRequireBreak
            breakLength
            paid
          }
        }
        retailer {
          uuid
          name
          logoImage
          brandImage
        }
        address {
          city
          state
          address1
          address2
          zip
        }
      }
    }
  }
`;

export const SHIFTS_FOR_ASSIGNED_WORKER_QUERY = ``;
