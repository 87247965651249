import { PaperworkStatus } from 'types/src/paperwork';

export const PAPERWORK_FOR_WORKER_QUERY = `
  query PAPERWORK_FOR_WORKER_QUERY(
    $user: String!
  ) {
    activePaperwork: paperwork (
      status: "${PaperworkStatus.ACTIVE}"
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
      }
    }
    archivedPaperwork: paperwork (
      status: "${PaperworkStatus.ARCHIVED}"
      user: $user
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
      }
    }
  }
`;
