import { applyContext, mutateObject, Mutations } from 'helpers';
import { GQL } from 'types';

export interface workerBenefitSpendProps {
  workerBenefit: string; // uuid
  note: string;
  amount: number;
  spendDate?: string; // YYYY-MM-DD
}

export const mutateWorkerBenefitSpend = async (
  workerBenefitSpendData: workerBenefitSpendProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'WORKER_BENEFIT_SPEND'),
    query: Mutations.WORKER_BENEFIT_SPEND_MUTATION,
    data: workerBenefitSpendData,
    returnKey: 'spendWorkerBenefit',
  });
