export const CREATE_CUSTOMER_MUTATION = `
  mutation CREATE_CUSTOMER_MUTATION(
    $name: String
    $admins: [String]
    $externalCustomerBillingId: String
    $accountOwner: String
  ) {
    createCustomer (
      name: $name
      admins: $admins
      externalCustomerBillingId: $externalCustomerBillingId
      accountOwner: $accountOwner
    ) {
      ok
      messages {
        code
      }
      uuid
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = `
  mutation UPDATE_CUSTOMER_MUTATION (
    $customer: String,
    $name: String,
    $admins: [String],
    $retailers: [String],
    $isActive: Boolean,
    $externalCustomerBillingId: String,
    $accountOwner: String,
  ) {
    updateCustomer (
      customer: $customer,
      name: $name,
      isActive: $isActive,
      externalCustomerBillingId: $externalCustomerBillingId,
      admins: $admins,
      retailers: $retailers,
      accountOwner: $accountOwner,
    ) {
      ok
      messages {
        code
      }
      
    }
  }
`;

export const CREATE_DOCUMENT_MUTATION = `
  mutation CREATE_DOCUMENT_MUTATION (
    $name: String,
    $signatureRequired: Boolean,
    $terms: String,
    $title: String,
  ) {
    createDocument(
      name: $name,
      signatureRequired: $signatureRequired,
      terms: $terms,
      title: $title,
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_DOCUMENT_MUTATION = `
  mutation UPDATE_DOCUMENT_MUTATION (
    $document: String,
    $terms: String,
    $name: String,
    $title: String,
    $body: String,
    $externalId: String,
    $signatureRequired: Boolean,
    $signedBy: String,
    $serviceProvider: String,
  ) {
    updateDocument (
      document: $document,
      terms: $terms,
      name: $name,
      title: $title,
      body: $body,
      externalId: $externalId,
      signatureRequired: $signatureRequired,
      signedBy: $signedBy,
      serviceProvider: $serviceProvider,
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_TERMS_MUTATION = `
  mutation UPDATE_TERMS_MUTATION (
    $terms: String,
    $customer: String,
    $locations: [String],
    $paymentMethod: String,
    $paymentType: String,
    $status: String,
    $name: String,
    $activeFrom: DateTime,
    $activeTo: DateTime,
    $autoRenew: Boolean,
  ) {
    updateTerms(
      terms: $terms,
      customer: $customer,
      locations: $locations,
      paymentMethod: $paymentMethod,
      paymentType: $paymentType,
      status: $status,
      name: $name,
      activeFrom: $activeFrom,
      activeTo: $activeTo,
      autoRenew: $autoRenew,
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CREATE_TERMS_MUTATION = `
  mutation CREATE_TERMS_MUTATION (
    $name: String,
    $customer: String,
    $activeFrom: DateTime,
    $activeTo: DateTime
    $autoRenew: Boolean,
    $paymentType: String,
    $billingRate: Float,
    $billingProcessingFee: Float,
    $billingSchedule: String,
    $billingType: String,
    $billingMethod: String,
    $billingDay: Int,
    $billingDue: String,
    $billingExtras: JSONString, 
  ) {
    createTerms(
      name: $name,
      customer: $customer,
      activeFrom: $activeFrom,
      activeTo: $activeTo,
      autoRenew: $autoRenew,
      paymentType: $paymentType,
      billingRate: $billingRate,
      billingProcessingFee: $billingProcessingFee,
      billingSchedule: $billingSchedule,
      billingType: $billingType,
      billingMethod: $billingMethod,
      billingDay: $billingDay,
      billingDue: $billingDue,
      billingExtras: $billingExtras,
    ) {
      ok
      messages {
        code
      }
      uuid
    }
  }
`;

export const CREATE_PAYMENT_METHOD_MUTATION = `
  mutation CREATE_PAYMENT_METHOD_MUTATION(
    $name: String,
    $customer: String,
    $externalPaymentMethodId: String
  ) {
  createPaymentMethod(
    name: $name,
    customer: $customer,
    externalPaymentMethodId: $externalPaymentMethodId,
    ) {
        ok
        uuid
        messages {
            code
        }
    }
  }
`;
