import { uuid } from './common';
import { customer } from './customer';
import { terms } from './terms';

enum PaymentMethodServiceProviderOptions {
  STRIPE = 'STRIPE',
}

export type paymentMethod = {
  id?: string;
  uuid?: uuid;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  name?: string;
  serviceProvider?: PaymentMethodServiceProviderOptions;
  externalCustomerId?: string;
  externalPaymentMethodId?: string;
  customer?: customer;
  usedBy?: Array<terms>;
};
