import { shiftCriteria, shiftCriteriaExtensionParsedArgs } from 'types';

export const isExtension = (criteria: shiftCriteria): boolean => {
  return criteria?.extensions?.length > 0;
};

export const getExtensionParsedArgs = (
  criteria: shiftCriteria,
): shiftCriteriaExtensionParsedArgs => {
  const { args } = criteria?.extensions[0];
  return JSON.parse(args) as shiftCriteriaExtensionParsedArgs;
};
