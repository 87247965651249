import crypto from 'crypto';

const algorithm = 'aes-256-cbc';

interface decryptSecretProps {
  secret: string;
  key: string;
  iv: string;
}

export const decryptSecret = ({
  secret,
  key,
  iv,
}: decryptSecretProps): string => {
  const decipher = crypto.createDecipheriv(algorithm, key, iv);
  let decrypted = decipher.update(secret, 'base64', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
};
