import { CalendarDate } from 'ui';
import { uuid } from './common';

export type notificationDateObject = {
  id: string;
  start: CalendarDate;
  end?: CalendarDate;
};

export enum DaysOfWeek {
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
  SUN = 'sunday',
}

export enum NotificationRuleType {
  SHIFT = 'shift',
  MESSAGE = 'message',
  ANY = 'any',
}

export type notificationRuleUpdate = {
  id?: string;
  ruleType: NotificationRuleType;
  dates?: Array<{
    startDate: string; // 'yyyy-MM-dd'
    startTime?: string; // 00:00:00
    endDate?: string; // 'yyyy-MM-dd'
    endTime?: string; // 23:59:59
  }>;
  daysOfWeek?: Array<string>; // DayOfWeek
  userUuid: uuid;
  deleteDates?: boolean;
  deleteDaysOfWeek?: boolean;
};

export type notificationRuleDayOfWeek = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

export type notificationRuleDate = {
  startDate: string; // 'yyyy-MM-dd'
  startTime?: string; // 00:00:00
  endDate?: string; // 'yyyy-MM-dd'
  endTime?: string; // 23:59:59
  uuid?: uuid;
};

export type notificationRuleDisplay = {
  ruleType: NotificationRuleType;
  dates?: Array<notificationRuleDate>;
  daysOfWeek?: notificationRuleDayOfWeek;
};

export type notificationRuleFull = notificationRuleDayOfWeek &
  notificationRuleDate & {
    ruleType: NotificationRuleType;
  };
