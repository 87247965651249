import { applyContext, mutateObject, Mutations } from 'helpers';
import { notificationRuleUpdate } from 'types';
import { GQL } from 'types/src/routes';

export const mutateNotificationRule = async (data: notificationRuleUpdate) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_NOTIFICATION_RULE_MUTATION'),
    query: Mutations.CREATE_NOTIFICATION_RULE_MUTATION,
    data,
    returnKey: 'createSimpleNotificationRule',
  });
