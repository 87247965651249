export const WORKER_BENEFIT_SPEND_MUTATION = `
  mutation SPEND_WORKER_BENEFIT_MUTATION(
    $workerBenefit: String!
    $amount: Float!
    $note: String!
    $spendDate: Date
  ) {
    spendWorkerBenefit(
      workerBenefit: $workerBenefit
      amount: $amount
      note: $note
      spendDate: $spendDate
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
