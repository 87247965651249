export const CANCEL_CURRENT_PLAYBOOK_MUTATION = `
  mutation CANCEL_CURRENT_PLAYBOOK_MUTATION(
    $shift: String
    ) {
    cancelCurrentPlaybook(shift: $shift) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const SET_ACTIVE_PLAYBOOK_MUTATION = `
  mutation SET_ACTIVE_PLAYBOOK_MUTATION($location: String, $playbook: String) {
    setActivePlaybook(location: $location, playbook: $playbook) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const FAST_FORWARD_PLAYBOOK_MUTATION = `
  mutation FAST_FORWARD_PLAYBOOK_MUTATION(
    $shift: String
    $stepNumber: Int
  ) {
    fastForwardPlaybook(shift: $shift, stepNumber: $stepNumber) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
