import { log } from './log';

export enum EmploymentRequirementType {
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  PAYPROVIDER_1099 = 'PAYPROVIDER_1099',
  PAYPROVIDER_W2 = 'PAYPROVIDER_W2',
  I9_VERIFICATION = 'I9_VERIFICATION',
}

export enum EmploymentRequirementState {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REJECTED = 'REJECTED',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
}

export enum UpdateActions {
  USER_VIEWED = 'USER_VIEWED',
  USER_COMPLETED = 'USER_COMPLETED',
  USER_UPDATED = 'USER_UPDATED',
  STAFF_REMINDED = 'STAFF_REMINDED',
  STAFF_VERIFIED = 'STAFF_VERIFIED',
  STAFF_SENT = 'STAFF_SENT',
  STAFF_EDITED = 'STAFF_EDITED',
  STAFF_FLAGGED = 'STAFF_FLAGGED',
  STAFF_COMPLETED = 'STAFF_COMPLETED',
  SYSTEM_SENT = 'SYSTEM_SENT',
  SYSTEM_COMPLETED = 'SYSTEM_COMPLETED',
  SYSTEM_REMINDED = 'SYSTEM_REMINDED',
  SYSTEM_FLAGGED = 'SYSTEM_FLAGGED',
  SYSTEM_UPDATED = 'SYSTEM_UPDATED',
}

export type employmentRequirement = {
  requirementType?: EmploymentRequirementType;
  state?: EmploymentRequirementState;
  expiresAt?: string; // DateTime
  logs?: log[];
  id?: string;
  externalLink?: string;
};
