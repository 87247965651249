export const UPSERT_PAYMENT = `
  mutation UPSERT_PAYMENT(
    $id: String
    $rate: Decimal
    $type: String
    $shift: String
  ) {
    upsertPayment(
      id: $id
      rate: $rate
      type: $type
      shift: $shift
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const DELETE_PAYMENT = `
  mutation DELETE_PAYMENT(
    $id: String
  ) {
    deletePayment(
      id: $id
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
