import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL, paging, ReflexResponse } from 'types';

export interface fetchOrganizationProps extends paging {
  uuids?: string[];
  isLeaf?: boolean;
  hasParent?: boolean;
}

export const fetchOrganization = async (
  data: fetchOrganizationProps,
  query = Queries.ORGANIZATIONS_LOCATIONS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['organizations', 'organizations_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'ORGANIZATIONS_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey,
  });

export interface mutateCreateOrganizationProps {
  name: string;
  parentOrganization?: string; // UUID
}

export const mutateCreateOrganization = async (
  data: mutateCreateOrganizationProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_ORGANIZATION_MUTATION'),
    query: Mutations.CREATE_ORGANIZATION_MUTATION,
    data,
    returnKey: 'createOrganization',
  });
