export enum GuideViews {
  INTRO = 'intro',
  BENEFITS = 'benefits',
  PROCESS = 'process',
  CHECKLIST = 'checklist',
  FAQS = 'faqs',
  FINALE = 'finale',
}

export interface guide {
  intro?: {
    text: string; // markdown
    author?: {
      name: string;
      image?: string;
      title?: string;
    };
  };
  benefits?: {
    title: string;
    points?: Array<{
      text: string;
      textColor?: string;
      shape?: string;
      shapeColor?: string;
      shapeTransform?: string;
      shapeOpacity?: string;
      icon?: string;
      iconColor?: string;
    }>;
  };
  process?: {
    title: string;
    subtext?: string;
    steps?: Array<{
      title?: string;
      image?: string;
      text?: Array<{
        backgroundColor?: string;
        textColor?: string;
        text: string; // markdown
        icon?: string;
        iconColor?: string;
      }>;
    }>;
  };
  checklist?: {
    title: string;
    subtext?: string;
    items?: Array<{
      text: string; // markdown
      subtext?: string; // markdown
    }>;
  };
  faqs?: {
    title: string;
    subtext?: string;
    items: Array<{
      question: string;
      answer: string; // markdown
    }>;
  };
  finale?: {
    headline: string;
    text: string; // markdown
    support: string; // markdown
  };
}
