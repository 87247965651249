import { compliance } from './compliance';

export enum BenefitCrtieriaBenefitType {
  SICK_LEAVE = 'SICK_LEAVE',
  PAID_LEAVE = 'PAID_LEAVE',
}

export type benefitCriteria = {
  annualAccrualCap?: number; // Hours
  benefitType?: BenefitCrtieriaBenefitType;
  compliances?: Array<compliance>;
  createdAt?: string; // DateTime
  id?: string;
  minDaysSinceFirstShiftOnPlatformToRedeem?: number;
  minDaysSinceFirstShiftToAccrue?: number;
  minDaysSinceFirstShiftToRedeem?: number;
  minDaysWorkedOnPlatformToAccrue?: number;
  name?: string;
  rate?: number;
  rolloverCap?: number; // Hours
  updatedAt?: string; // DateTime
};
