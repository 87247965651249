import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  GQL,
  paging,
  ReflexResponse,
  ReviewPrioritization,
  ReviewPriority,
  ReviewStatus,
  ReviewType,
} from 'types';

export interface fetchReviewsProps extends paging {
  createdSince?: string; // yyyy-MM-dd
  createdTo?: string; // yyyy-MM-dd
  hasCoaching?: boolean;
  hasFeedback?: boolean;
  hasPrioritization?: boolean;
  isUrgent?: boolean;
  locations?: string[]; // uuid
  markets?: string[]; // id
  prioritization?: number;
  priority?: ReviewPriority;
  rating?: number[];
  retailers?: string[]; // uuid
  reviewType?: ReviewType;
  status?: ReviewStatus[];
  updatedSince?: string; // yyyy-MM-dd
  updatedTo?: string; // yyyy-MM-dd
  uuid?: string;
  workers?: string[]; // uuid
}

export const fetchReviews = async (
  data: fetchReviewsProps,
  query = Queries.REVIEWS_LIST_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = 'reviews',
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      contextOverride ? contextOverride : 'REVIEWS_LIST_QUERY',
    ),
    query,
    data,
    returnKey,
  });

export interface mutateReviewProps {
  coaching?: string;
  feedback?: string;
  endorsements?: number[];
  growthAreas?: number[];
  id?: string;
  ids?: string[];
  prioritization?: ReviewPrioritization;
  rating?: number;
  status?: string;
  uuid?: string;
  uuids?: string[];
}
export const mutateReviewStatus = async (data: mutateReviewProps) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_REVIEW_MUTATION'),
    query: Mutations.UPDATE_REVIEW_MUTATION,
    data,
    returnKey: 'updateReview',
  });

export const mutateReview = async (data: mutateReviewProps) => {
  return await mutateObject({
    route: applyContext(GQL, 'UPDATE_REVIEW_MUTATION'),
    query: Mutations.UPDATE_REVIEW_MUTATION,
    data,
    returnKey: 'updateReview',
  });
};
