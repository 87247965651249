import { graphShift } from './shift';

export enum PaymentStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  POSTED = 'POSTED',
  PAID = 'PAID',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
  RETRY = 'RETRY',
}

export enum PaymentType {
  BASE = 'BASE',
  BONUS = 'BONUS', // Deprecated, leave to support display
  REFERRAL = 'REFERRAL',
  COMMISSION = 'COMMISSION', // Deprecated, leave to support display
  FLEX_FULFILLMENT_INCENTIVE = 'FLEX_FULFILLMENT_INCENTIVE',
  FIRST_FLEX_BONUS = 'FIRST_FLEX_BONUS',
  OTHER_WORKER_ACQUISITION = 'OTHER_WORKER_ACQUISITION',
  MARKETING = 'MARKETING',
  OTHER = 'OTHER',
}

export type graphPayment = {
  id?: string;
  rate?: string;
  quantity?: string;
  type?: PaymentType;
  status?: PaymentStatus;
  shift?: graphShift;
};
