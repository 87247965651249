const _ROLES_PARAMS = `
  $isRequestable: Boolean
  $isActive: Boolean
`;
const _ROLES_VARIABLES = `
  isRequestable: $isRequestable
  isActive: $isActive
`;

export const ROLES_QUERY = `
  query ROLE_QUERY(${_ROLES_PARAMS}) {
    roles(${_ROLES_VARIABLES}) {
      id
      name
      icon
      isRequestable
      isActive
      priority
      category
    }
  }
`;
