import {
  notificationRuleDisplay,
  notificationRuleFull,
  NotificationRuleType,
} from 'types';

export const transformNotificationRulesForDisplay = (
  notificationRules: Array<notificationRuleFull>,
): notificationRuleDisplay => {
  const transformedRules = notificationRules?.reduce(
    (prevVal, currVal) => {
      const nextVal = { ...prevVal };
      if (new Date(currVal.endDate).getFullYear() > 3000) {
        const { mon, tue, wed, thu, fri, sat, sun } = currVal;
        nextVal.daysOfWeek = { mon, tue, wed, thu, fri, sat, sun };
      } else {
        const { startDate, startTime, endDate, endTime, uuid } = currVal;
        nextVal.dates.push({ startDate, startTime, endDate, endTime, uuid });
      }
      return nextVal;
    },
    {
      daysOfWeek: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      dates: [],
      ruleType: NotificationRuleType.SHIFT,
    },
  );
  return transformedRules;
};
