import { CalendarValues } from 'ui';

export enum TimingFilters {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  NEXT_3_DAYS = 'soon',
  THIS_WEEK = 'week',
  NEXT_7_DAYS = 'next 7',
  NEXT_14_DAYS = 'next 14',
  THIS_MONTH = 'month',
  NEXT_30_DAYS = 'next 30',
  YESTERDAY = 'yesterday',
  TODAY_YESTERDAY = 'today & yesterday',
  LAST_3_DAYS = 'last 3',
  LAST_WEEK = 'last week',
  LAST_7_DAYS = 'last 7',
  LAST_14_DAYS = 'last 14',
  LAST_MONTH = 'last month',
  LAST_30_DAYS = 'last 30',
  LAST_60_DAYS = 'last 60',
  LAST_90_DAYS = 'last 90',
  UPCOMING = 'upcoming',
}

export const TimingContainsPastValues = [
  TimingFilters.LAST_90_DAYS,
  TimingFilters.LAST_30_DAYS,
  TimingFilters.LAST_60_DAYS,
  TimingFilters.LAST_7_DAYS,
  TimingFilters.LAST_3_DAYS,
  TimingFilters.LAST_MONTH,
  TimingFilters.LAST_WEEK,
  TimingFilters.THIS_MONTH,
  TimingFilters.THIS_WEEK,
  TimingFilters.TODAY,
  TimingFilters.YESTERDAY,
];

export enum CreatedFilters {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_2_DAYS = 'last 2',
  LAST_3_DAYS = 'last 3',
  LAST_4_DAYS = 'last 4',
  LAST_5_DAYS = 'last 5',
  LAST_7_DAYS = 'last 7',
  LAST_14_DAYS = 'last 14',
  LAST_30_DAYS = 'last 30',
  LAST_60_DAYS = 'last 60',
  LAST_90_DAYS = 'last 90',
}

export enum OnboardingStatusFilters {
  SCHEDULED = 'scheduled',
  UNSCHEDULED = 'unscheduled',
  COMPLETED = 'completed',
  LAST_3_DAYS = 'soon',
  TODAY = 'today',
}

export enum LastWorkedFilters {
  LAST_7_DAYS = 'last 7',
  LAST_14_DAYS = 'last 14',
  LAST_30_DAYS = 'last 30',
  LAST_60_DAYS = 'last 60',
  LAST_90_DAYS = 'last 90',
}

export enum LastNotWorkedFilters {
  LAST_7_DAYS = 'last 7',
  LAST_14_DAYS = 'last 14',
  LAST_30_DAYS = 'last 30',
  LAST_60_DAYS = 'last 60',
  LAST_90_DAYS = 'last 90',
}

export enum ShiftPaperworkFilters {
  ALL = 'all',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

// Separate from types/src/shift ShiftStatus enum to allow additional statuses like "all" that do not exist in API
export enum ShiftStatusFilters {
  ALL = 'all',
  FILLED = 'filled',
  FILLED_PARTIAL = 'filled_partial',
  OVERBOOKED = 'overbooked',
  OPEN = 'open',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  MISSED = 'missed',
}

export enum LocationStatusOptions {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // CHURNED = 'churned', // FUTURE
}

export enum WorkerFlaggedOptions {
  FLAGGED = 'true',
  NOT_FLAGGED = 'false',
}

// extends ReviewStatus
export enum ReviewStatusOptions {
  ALL = 'ALL',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  INTERNAL_REVIEW = 'INTERNAL_REVIEW',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export type FilterOptionsObject =
  | {
      [key: string]: Array<FilterOption>;
    }
  | Record<string, Array<FilterOption>>;

export type FilterOptionsObjectDates = {
  dates?: CalendarValues;
};

export type FilterOption = {
  label?: string;
  value?: string; // LATER: support more than string types
  transformFilterOption?: (value: string) => any;
};

export interface RangeFilterOption {
  rangeMin: FilterOption;
  rangeMax: FilterOption;
  transformFilterOption: (value: string) => any;
}
