import {
  LocationRelations,
  user,
  WorkerBookedOptions,
  WorkerHistoryOptions,
} from 'types';
import { FilterOption, TimingFilters } from 'utils/types/filters';
import { dateParser } from './filters';

/**
 * @function getUserLocationRelationStatus
 * @param {user} user
 * @param {string} locationUUID
 * @returns { LocationRelations | null}
 */
export const getUserLocationRelationStatus = (
  user: user,
  locationUUID: string,
) => {
  const { favoritedBy = [], approvedBy = [], blockedBy = [] } = user;
  if (
    favoritedBy.length === 0 &&
    approvedBy.length === 0 &&
    blockedBy.length === 0
  )
    return null;
  const isFavorite = favoritedBy
    .map(location => location.uuid)
    .includes(locationUUID);
  const isApproved = approvedBy
    .map(location => location.uuid)
    .includes(locationUUID);
  const isBlocked = blockedBy
    .map(location => location.uuid)
    .includes(locationUUID);
  if (isFavorite) return LocationRelations.FAVORITE;
  if (isApproved) return LocationRelations.APPROVED;
  if (isBlocked) return LocationRelations.BLOCKED;
  return null;
};

export const workersStatusParser = (statusArray: Array<FilterOption>) => {
  if (!statusArray || statusArray.length === 0) return undefined;
  switch (statusArray[0].value) {
    // Type 'all' does not exist in backend. Backend returns all by default.
    case 'ALL':
      return undefined;
    default:
      return { status: statusArray.map(option => option.value.toUpperCase()) };
  }
};

/**
 * @function staffUsersOptionsParser
 * @summary Convert From GQL data to filteroptions
 */
export const staffUsersOptionsParser = (
  staffUsers: Array<user>,
): Array<FilterOption> => {
  if (!staffUsers || !staffUsers.length) return [];
  return staffUsers.map(staffUser => {
    const { firstName, lastName, uuid } = staffUser;
    if (!firstName || !lastName || !uuid) {
      return {
        label: '',
        value: '',
      };
    }
    return {
      label: `${firstName} ${lastName}`,
      value: uuid,
    };
  });
};

export const workersIsFlaggedParser = (flaggedArray: Array<FilterOption>) => {
  if (!flaggedArray || flaggedArray.length === 0) return undefined;
  switch (flaggedArray[0].value) {
    case 'FLAGGED':
      return { isFlagged: true };
    case 'NOT_FLAGGED':
    default:
      return { isFlagged: false };
  }
};

export const workersHasWorkedParser = (hasWorkedArray: Array<FilterOption>) => {
  if (!hasWorkedArray || hasWorkedArray.length === 0) return undefined;
  switch (hasWorkedArray[0].value) {
    case WorkerHistoryOptions.HAS_WORKED:
      return { hasWorked: true };
    case WorkerHistoryOptions.HAS_NOT_WORKED:
    default:
      return { hasWorked: false };
  }
};

export const workersisBookedParser = (isBookedArray: Array<FilterOption>) => {
  if (!isBookedArray?.length) return;
  switch (isBookedArray[0].value) {
    case WorkerBookedOptions.IS_BOOKED:
      return { isBooked: true };
    case WorkerBookedOptions.NOT_BOOKED:
    default:
      return { isBooked: false };
  }
};

export const workersRatingsParser = (
  max: Array<FilterOption>,
  min: Array<FilterOption>,
) => {
  if ((!max || !max.length) && (!min || !min.length)) return undefined;
  const parsed = {};
  if (max.length) {
    parsed['reviewRatingAvgMax'] = parseFloat(max[0].value);
  }
  if (min.length) {
    parsed['reviewRatingAvgMin'] = parseFloat(min[0].value);
  }
  return parsed;
};

export const workersPriorityParser = (
  max: Array<FilterOption>,
  min: Array<FilterOption>,
) => {
  if ((!max || !max.length) && (!min || !min.length)) return undefined;
  const parsed = {};
  if (max.length) {
    parsed['reviewPrioritizationAvgMax'] = parseFloat(max[0].value);
  }
  if (min.length) {
    parsed['reviewPrioritizationAvgMin'] = parseFloat(min[0].value);
  }
  return parsed;
};

export const workersTagsParser = (
  tagsArray: Array<FilterOption>,
  key = 'tags',
) => {
  if (!tagsArray || tagsArray.length === 0) return undefined;
  return { [key]: tagsArray.map(tag => parseInt(tag.value, 10)) };
};

export const workersWorkedSinceParser = (sinceDate: Array<FilterOption>) => {
  if (!sinceDate || sinceDate.length === 0) return undefined;
  const parsedDate = dateParser(sinceDate[0].value as TimingFilters);
  return { workedSince: parsedDate.since };
};

export const workersNotWorkedSinceParser = (
  notSinceDate: Array<FilterOption>,
) => {
  if (!notSinceDate || notSinceDate.length === 0) return undefined;
  const parsedDate = dateParser(notSinceDate[0].value as TimingFilters);
  return { notWorkedSince: parsedDate.since };
};

export const workersShiftsCompleteParser = (
  min: Array<FilterOption>,
  max: Array<FilterOption>,
) => {
  if ((!max || !max.length) && (!min || !min.length)) return undefined;
  const parsed = {};
  if (min.length) {
    parsed['shiftsCompletedMin'] = parseInt(min[0].value);
  }
  if (max.length) {
    parsed['shiftsCompletedMax'] = parseInt(max[0].value);
  }
  return parsed;
};

export const workersZipcodeDistanceParser = (
  zipcode: Array<FilterOption>,
  zipcodeMinDistance: Array<FilterOption>,
  zipcodeMaxDistance: Array<FilterOption>,
) => {
  if (!zipcode || !zipcode.length) return undefined;
  const parsed = {};
  parsed['zipcode'] = zipcode[0].value;
  if (zipcodeMinDistance && zipcodeMinDistance.length) {
    parsed['zipcodeMinDistance'] = parseFloat(zipcodeMinDistance[0].value);
  }
  if (zipcodeMaxDistance && zipcodeMaxDistance.length) {
    parsed['zipcodeMaxDistance'] = parseFloat(zipcodeMaxDistance[0].value);
  }
  return parsed;
};
