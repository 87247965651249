import { noteType, uuid, workershift } from '..';

export enum EventDetailState {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  ISSUE_MISSING = 'ISSUE_MISSING',
  ISSUE_NEEDS_APPROVAL = 'ISSUE_NEEDS_APPROVAL',
  NOT_READY = 'NOT_READY',
}

export enum WorkerShiftEventsEnum {
  PREFLEX_AGREEMENT_ACCEPTED = 'PREFLEX_AGREEMENT_ACCEPTED',
  CONFIRMATION_24 = 'CONFIRMATION_24',
  CONFIRMATION_2 = 'CONFIRMATION_2',
  CONFIRMATION_ARRIVAL = 'CONFIRMATION_ARRIVAL',
  CLOCK_IN = 'CLOCK_IN',
  CLOCK_OUT = 'CLOCK_OUT',
  BREAK_START = 'BREAK_START',
  BREAK_END = 'BREAK_END',
  BREAK_ACKNOWLEDGED = 'BREAK_ACKNOWLEDGED',
}

export type workerShiftEventMeta = {
  true_timestamp: string; // ISO
  estimated_distance: number;
  out_of_bounds: boolean;
  distance_accuracy?: number;
};

export type workerShiftEvent = {
  timestamp?: string;
  eventType?: WorkerShiftEventsEnum;
  workershift?: workershift;
  notes?: noteType[];
  uuid?: uuid;
  expectedTimeRangeStart?: string;
  expectedTimeRangeEnd?: string;
  requiredForPayment?: boolean;
  approved?: boolean;
  lat?: number;
  lon?: number;
  userConfirmed?: boolean;
  distanceApproved?: boolean;
  verificationImg?: string;
  meta?: string; // JSON: workerShiftEventMeta
};

export enum TimelinessEnums {
  EARLY = 'EARLY',
  ON_TIME = 'ON_TIME',
  LATE = 'LATE',
  MISSING = 'MISSING',
}

export interface EasyWorkerShiftEvent extends workerShiftEvent {
  formattedTimestamp: string;
  state: EventDetailState;
  dateStamp: Date;
  timeliness: TimelinessEnums;
  wasEarly: boolean;
  wasLate: boolean;
  contentHelper: string;
}

// FUTURE: update api serializer so that we don't have to re-type
export type apiWorkerShiftEvent = {
  timestamp?: string;
  event_type?: WorkerShiftEventsEnum;
  workershift?: workershift;
  notes?: noteType[];
  uuid?: uuid;
  expected_time_range_start?: string;
  expected_time_range_end?: string;
  required_for_payment?: boolean;
  approved?: boolean;
  lat?: number;
  lon?: number;
};
