import { createContext, useState } from 'react';

export const MessengerContext = createContext(null);

export interface MessageDraft {
  text?: string;
  pushTitle?: string;
  push?: string;
}

const defaultValues = {
  text: '',
  pushTitle: 'Reflex',
  push: '',
};

export const MessengerProvider = ({ children }) => {
  const [values, setValues] = useState<MessageDraft>(defaultValues);

  const updateMessengerValues = (newValues: MessageDraft) => {
    setValues(prevValues => ({
      ...prevValues,
      ...newValues,
    }));
  };

  const resetMessengerValues = () => {
    setValues(defaultValues);
  };

  return (
    <MessengerContext.Provider
      value={{ ...values, updateMessengerValues, resetMessengerValues }}
    >
      {children}
    </MessengerContext.Provider>
  );
};
