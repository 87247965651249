import {
  differenceInDays,
  differenceInMinutes,
  formatISO,
  getHours,
  getMinutes,
  getYear,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { getShortTimeZone } from './dates';

export const TIME_FORMAT = 'h:mmaaa';
export const DAY_FORMAT = 'EEE';
export const DATE_FORMAT = 'M/d';
export const DATE_FORMAT_MEDIUM = 'MMM d';
export const DATE_FORMAT_LONG = 'MMMM d';

export interface friendlyDatesHelperReturn {
  friendly: string; // "This Thursday Tomorrow 9/14 · 10am - 5pm"
  friendlyWithYear: string; // "This Thursday Tomorrow 9/14/22 · 10am - 5pm"
  dayPart: string; // "This Thur"
  dayOfWeek: string; // "Mon, Tue, Wed"
  simple: string; // "9/14 · 10am - 5pm EST"
  simpleNoTimezone: string; // "9/14 · 10am - 5pm"
  date: string; // "9/14"
  dateMedium: string; // "Sep 14"
  dateMediumOrdinal: string; // "Sep 14th"
  dateLong: string; // "September 14"
  dateLongOrdinal: string; // "September 14th"
  times: string; // "10am - 5pm"
  fullTimes: string; // "10:00am - 5:00pm"
  fullTimesWithTimezone: string; // "10:00am - 5:00pm EST"
  formattedStartTime: string; // 10:00am
  formattedEndTime: string; // 5:00pm
  formattedDate: string; // Wed, Aug 10
  formattedStartDateISO: string; // 20220810T100000-05:00
  formattedEndDateISO: string; // 20220811T170000-05:00
  isToday: boolean;
  isSoon: boolean; // less than 8 days
  timeInHours: number;
  startYear: string;
  timezone: string; // US/Central
  timezoneShort: string; // CST
}

const friendDatesHelperDefaults: friendlyDatesHelperReturn = {
  friendly: '',
  friendlyWithYear: '',
  dayPart: '',
  dayOfWeek: '',
  simple: '',
  simpleNoTimezone: '',
  times: '',
  fullTimes: '',
  fullTimesWithTimezone: '',
  date: '',
  dateMedium: '',
  dateMediumOrdinal: '',
  dateLong: '',
  dateLongOrdinal: '',
  formattedStartTime: '',
  formattedEndTime: '',
  formattedDate: '',
  formattedStartDateISO: '',
  formattedEndDateISO: '',
  isToday: null,
  isSoon: null,
  timeInHours: null,
  startYear: '',
  timezone: '',
  timezoneShort: '',
};

// Formats date in friendly like format
// x, date
// last x
// yesterday
// today
// tomorrow
// this x
// next x
// x, date

// if both pm XX-XXpm
// if both am XX-XXam
// if different XXam-XXpm

// if :00 XX
// else XX:XX

interface friendlyDatesHelperProps {
  start?: string | Date;
  end?: string | Date;
  timezone?: string;
}
export const friendlyDatesHelper = ({
  start = new Date(),
  end = new Date(),
  timezone = 'US/Central',
}: friendlyDatesHelperProps): friendlyDatesHelperReturn => {
  if (!start || !end) return friendDatesHelperDefaults;
  const today = startOfDay(new Date());
  const startDate = new Date(start);
  const startYear = getYear(startDate);
  const endDate = new Date(end);
  const getDayPart = date => {
    const distance = differenceInDays(startOfDay(date), today);
    if (distance > 14) {
      return formatInTimeZone(startDate, timezone, DAY_FORMAT);
    }
    if (distance > 7) {
      return `Next ${formatInTimeZone(startDate, timezone, DAY_FORMAT)}`;
    }
    if (distance > 1) {
      return `This ${formatInTimeZone(startDate, timezone, DAY_FORMAT)}`;
    }
    if (distance > 0) {
      return 'Tomorrow';
    }
    if (distance === 0) {
      return 'Today';
    }
    if (distance === -1) {
      return 'Yesterday';
    }
    if (distance > -7) {
      return `Last ${formatInTimeZone(startDate, timezone, DAY_FORMAT)}`;
    }
    return formatInTimeZone(startDate, timezone, DAY_FORMAT);
  };

  const getTimePart = (startTime, endTime) => {
    const startTimeHours = getHours(startTime);
    const startTimeMinutes = getMinutes(startTime);
    const endTimeHours = getHours(endTime);
    const endTimeMinutes = getMinutes(endTime);
    const bothAfternoon = startTimeHours > 11 && endTimeHours > 11;
    const bothMorning = startTimeHours < 12 && endTimeHours < 12;

    const getMinutesFormat = minutes => {
      if (minutes === 0) return '';
      return ':mm';
    };

    if (bothMorning || bothAfternoon) {
      // eslint-disable-next-line max-len
      return `${formatInTimeZone(startTime, timezone, `h${getMinutesFormat(startTimeMinutes)}`)}–${formatInTimeZone(endTime, timezone, `h${getMinutesFormat(endTimeMinutes)}aa`).toLowerCase()}`;
    }

    // eslint-disable-next-line max-len
    return `${formatInTimeZone(startTime, timezone, `h${getMinutesFormat(startTimeMinutes)}aa`).toLowerCase()}–${formatInTimeZone(endTime, timezone, `h${getMinutesFormat(endTimeMinutes)}aa`).toLowerCase()}`;
  };

  const dayPart = getDayPart(startDate);
  const timePart = getTimePart(startDate, endDate);
  const shortTimeZone = getShortTimeZone(startDate, timezone);
  const formattedStartTime = formatInTimeZone(startDate, timezone, TIME_FORMAT);
  const formattedEndTime = formatInTimeZone(endDate, timezone, TIME_FORMAT);

  return {
    friendly: `
      ${dayPart} 
      ${formatInTimeZone(startDate, timezone, DATE_FORMAT)}
      · 
      ${timePart} ${shortTimeZone}
    `,
    friendlyWithYear: `
      ${dayPart} 
      ${formatInTimeZone(startDate, timezone, DATE_FORMAT)}/${`${startYear}`.substring(2, 4)}
      · 
      ${timePart} ${shortTimeZone}
    `,
    dayPart,
    dayOfWeek: formatInTimeZone(startDate, timezone, DAY_FORMAT),
    simple: `${formatInTimeZone(startDate, timezone, DATE_FORMAT)} · ${timePart} ${shortTimeZone}`,
    simpleNoTimezone: `${formatInTimeZone(startDate, timezone, DATE_FORMAT)} · ${timePart}`,
    date: formatInTimeZone(startDate, timezone, DATE_FORMAT),
    dateMedium: formatInTimeZone(startDate, timezone, DATE_FORMAT_MEDIUM),
    dateMediumOrdinal: formatInTimeZone(
      startDate,
      timezone,
      `${DATE_FORMAT_MEDIUM}o`,
    ),
    dateLong: formatInTimeZone(startDate, timezone, DATE_FORMAT_LONG),
    dateLongOrdinal: formatInTimeZone(
      startDate,
      timezone,
      `${DATE_FORMAT_LONG}o`,
    ),
    times: timePart,
    fullTimes: `${formattedStartTime} - ${formattedEndTime}`,
    fullTimesWithTimezone: `${formattedStartTime} - ${formattedEndTime} ${shortTimeZone}`,
    formattedStartTime,
    formattedEndTime,
    formattedDate: formatInTimeZone(
      startDate,
      timezone,
      `${DAY_FORMAT}, ${DATE_FORMAT_MEDIUM}`,
    ),
    formattedStartDateISO: formatISO(startDate, { format: 'basic' }),
    formattedEndDateISO: formatISO(endDate, { format: 'basic' }),
    isToday: isSameDay(startOfDay(new Date()), startOfDay(startDate)),
    isSoon: differenceInDays(startOfDay(new Date()), startOfDay(startDate)) < 8,
    timeInHours: parseFloat(
      (differenceInMinutes(endDate, startDate) / 60).toFixed(2),
    ),
    startYear: startYear.toString(),
    timezone: timezone,
    timezoneShort: shortTimeZone,
  };
};
