import { noEmpties } from 'helpers';
import {
  actionableParser,
  locationsParser,
  marketsParser,
  retailersParser,
} from 'utils/helpers/filters';
import { convertEnumToControlOptions } from 'utils/helpers/form';
import {
  ControlProps,
  ControlType,
  ControlValues,
} from 'components/common/controls/control.helpers';

export type filterValuesType = ControlValues;

export enum ActionableTypes {
  ALL = 'All',
  MISSING_CLOCKIN = 'Missing Clock-In',
  MISSING_CLOCKOUT = 'Missing Clock-Out',
  MISSING_MOBILE = 'Missing Mobile',
  MISSING_PAPERWORK = 'Missing Paperwork',
  MISSING_T2 = 'Missing T2',
  MISSING_T24 = 'Missing T24',
  STILL_OVERBOOKED = 'Still Overbooked',
  STILL_UNFILLED = 'Still Unfilled',
  OUT_OF_BOUNDS = 'Out of Bounds',
}

export const actionableDefaults = {
  missingClockin: true,
  missingClockout: true,
  missingMobile: true,
  missingPaperwork: true,
  missingT2: true,
  missingT24: true,
  stillOverbooked: true,
  stillUnfilled: true,
  outOfBounds: true,
};

export const getParsedFilters = (filterValues: filterValuesType) => {
  return noEmpties({
    ...marketsParser(filterValues.markets),
    ...retailersParser(filterValues.retailers),
    ...locationsParser(filterValues.locations),
    actionable: actionableParser(filterValues?.actionable),
  });
};

export const Controls = {
  actionable: {
    type: ControlType.SELECT,
    options: convertEnumToControlOptions(ActionableTypes),
    default: convertEnumToControlOptions(ActionableTypes).filter(
      option => option.label === ActionableTypes.ALL,
    ),
    label: 'Actions Needed',
    name: 'actionable',
    placeholder: 'Choose Types',
  },
  markets: {
    type: ControlType.SELECT,
    options: [],
    default: [],
    label: 'Markets',
    dynamicKey: 'markets',
    name: 'markets',
    placeholder: 'Select',
    selectProps: { isMulti: true },
  },
  shiftTags: {
    name: 'shiftTags',
    type: ControlType.SELECT,
    options: [],
    label: 'Tags',
    dynamicKey: 'tags',
    placeholder: 'Select',
    selectProps: { isMulti: true },
  },
  locationTags: {
    name: 'locationTags',
    type: ControlType.SELECT,
    options: [],
    label: 'Location Tags',
    dynamicKey: 'tags',
    placeholder: 'Select',
    selectProps: { isMulti: true },
  },
  locations: {
    name: 'locations',
    type: ControlType.SELECT,
    options: [],
    label: 'Locations',
    dynamicKey: 'locations',
    placeholder: 'Select',
    selectProps: { isMulti: true },
  },
  retailers: {
    name: 'retailers',
    type: ControlType.SELECT,
    options: [],
    label: 'Retailers',
    dynamicKey: 'retailers',
    placeholder: 'Select',
    selectProps: { isMulti: true },
  },
} as Record<string, ControlProps>;
