import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import {
  UserPreferences,
  UserPreferencesDefaultFiltersInterface,
} from 'utils/helpers/localStorage';
import { usePreferences } from 'utils/hooks/usePreferences';
import {
  CampaignsActions,
  campaignsReducer,
  CampaignsStateInterface,
  filterDefaults as campaignsFilterDefaults,
  initialState as campaignsInitialState,
} from './reducers/campaignsReducer';
import {
  FiltersActions,
  filtersReducer,
  FiltersStateInterface,
  initialState as filtersInitialState,
} from './reducers/filtersReducer';
import {
  filterDefaults as locationsFilterDefaults,
  initialState as locationsInitialState,
  LocationsActions,
  locationsReducer,
  LocationsStateInterface,
} from './reducers/locationsReducer';
import {
  filterDefaults as reviewsFilterDefaults,
  initialState as reviewsInitialState,
  ReviewsActions,
  reviewsReducer,
  ReviewsStateInterface,
} from './reducers/reviewsReducer';
import {
  filterDefaults as shiftFilterDefaults,
  initialState as shiftInitialState,
  ShiftActions,
  shiftReducer,
  ShiftStateInterface,
} from './reducers/shiftReducer';
import {
  filterDefaults as shiftsFilterDefaults,
  initialState as shiftsInitialState,
  ShiftsActions,
  shiftsReducer,
  ShiftsStateInterface,
} from './reducers/shiftsReducer';
import {
  filterDefaults as tagsFilterDefaults,
  initialState as tagsInitialState,
  TagsActions,
  tagsReducer,
  TagsStateInterface,
} from './reducers/tagsReducer';
import {
  initialState as wizardInitialState,
  WizardActions,
  wizardReducer,
  WizardStateInterface,
} from './reducers/wizardReducer';
import {
  filterDefaults as workersFilterDefaults,
  initialState as workersInitialState,
  WorkersActions,
  workersReducer,
  WorkersStateInterface,
} from './reducers/workersReducer';

export {
  ShiftsActions,
  ShiftActions,
  WorkersActions,
  LocationsActions,
  WizardActions,
  ReviewsActions,
  TagsActions,
  FiltersActions,
  CampaignsActions,
};

export const contextFilterDefaults = {
  shifts: shiftsFilterDefaults,
  shift: shiftFilterDefaults,
  workers: workersFilterDefaults,
  locations: locationsFilterDefaults,
  reviews: reviewsFilterDefaults,
  tags: tagsFilterDefaults,
  campaigns: campaignsFilterDefaults,
};

interface StateInterface {
  shifts?: ShiftsStateInterface;
  shift?: ShiftStateInterface;
  workers?: WorkersStateInterface;
  locations?: LocationsStateInterface;
  wizard?: WizardStateInterface;
  reviews?: ReviewsStateInterface;
  tags?: TagsStateInterface;
  filters?: FiltersStateInterface;
  campaigns?: CampaignsStateInterface;
}

const initialState = {
  shifts: shiftsInitialState,
  shift: shiftInitialState,
  workers: workersInitialState,
  locations: locationsInitialState,
  wizard: wizardInitialState,
  reviews: reviewsInitialState,
  tags: tagsInitialState,
  filters: filtersInitialState,
  campaigns: campaignsInitialState,
};

export const OpsContext = createContext<{
  state: StateInterface;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const combineReducers = (reducers = {}) => {
  // adapted from https://github.com/salvoravida/redux-immer/blob/master/src/redux-immer.js
  const keys = Object.keys(reducers);
  const initialState = keys.reduce((a, k) => {
    a[k] = reducers[k](undefined, {});
    return a;
  }, {});

  return produce((draft, action) => {
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      draft[key] = reducers[key](draft[key], action);
    }
    return draft;
  }, initialState);
};

export const OpsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [state, dispatch] = useImmerReducer(
    combineReducers({
      shifts: shiftsReducer,
      shift: shiftReducer,
      workers: workersReducer,
      locations: locationsReducer,
      wizard: wizardReducer,
      reviews: reviewsReducer,
      tags: tagsReducer,
      filters: filtersReducer,
      campaigns: campaignsReducer,
    }),
    initialState,
  );

  const { preferences } = usePreferences();

  const updateDefaultFilters = useCallback(() => {
    const { markets, status, timing, paperwork } = preferences?.[
      UserPreferences.DEFAULT_FILTERS
    ] as UserPreferencesDefaultFiltersInterface;
    if (markets) {
      dispatch({
        type: ShiftsActions.CHANGE_FILTER,
        payload: { markets },
      });
      dispatch({
        type: WorkersActions.CHANGE_FILTER,
        payload: { markets },
      });
      dispatch({
        type: LocationsActions.CHANGE_FILTER,
        payload: { markets },
      });
      dispatch({
        type: ReviewsActions.CHANGE_FILTER,
        payload: { markets },
      });
    }
    if (status) {
      dispatch({
        type: ShiftsActions.CHANGE_FILTER,
        payload: { status },
      });
    }
    if (timing) {
      dispatch({
        type: ShiftsActions.CHANGE_FILTER,
        payload: { timing },
      });
    }
    if (paperwork) {
      dispatch({
        type: ShiftsActions.CHANGE_FILTER,
        payload: { paperwork },
      });
    }
  }, [preferences, dispatch]);

  useEffect(() => {
    if (hasLoaded || !preferences?.[UserPreferences.DEFAULT_FILTERS]) return;
    setHasLoaded(true);
    updateDefaultFilters();
  }, [hasLoaded, preferences, updateDefaultFilters]);

  return (
    <OpsContext.Provider value={{ state, dispatch }}>
      {children}
    </OpsContext.Provider>
  );
};
