const _WORKER_FIELDS = `
  uuid
  firstName
  lastName
  preferredName
  email
  profileImg
  phoneNumber
  textingLink
  isW2PayproviderComplete
  is1099PayproviderComplete
  isBackcheckComplete
  isI9Verified
  hoursThisWeek
  totalShiftsCompleted
  tier
  isRecentlyActive
  hasWorkedAtLocation
  attributes
  notificationPreferences {
    isPushNotificationEnabled
  }
  isFavorite
  isApproved
  isBlocked
  cohort(shift: $shift) {
    name
    uuid
    definition
    createdAt
    createdBy {
      uuid
      firstName
      lastName
    }
  }
`;

export const WORKER_SHIFTS_QUERY = `
  query WORKER_SHIFTS_QUERY(
    $shift: String
    $shifts: [String]
    $status: [String]
    $pageSize: Int
    $page: Int
    $orderBy: String
    $search: String
    $all: [String]
  ) {
    workerShifts (
      shift: $shift
      shifts: $shifts
      status: $status
      pageSize: $pageSize
      page: $page
      orderBy: $orderBy
      search: $search
      all: $all
    ) {
      id
      uuid
      viewCount
      messageCount
      status
      cancelNote
      cancelClassification
      cancellationTime
      hasCompletedCompliancePaperwork
      confirmationStatus
      worker {
        ${_WORKER_FIELDS}
      }
    }
  }
`;

export const WORKER_SHIFTS_MESSAGE_COUNTS_QUERY = `
  query WORKER_SHIFTS_QUERY(
    $shifts: [String]
    $status: [String]
  ) {
    workerShifts (
      shifts: $shifts
      status: $status
    ) {
      messageCount
      worker {
        uuid
      }
    }
  }
`;
