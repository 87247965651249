import { applyContext, fetchObject, Queries } from 'helpers';
import { GQL, ReflexResponse, uuid } from 'types';

export interface fetchCommunicationBatchProps {
  uuid?: uuid;
}

export const fetchCommunicationBatch = async (
  data: fetchCommunicationBatchProps,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'COMMUNICATION_BATCH_QUERY'),
    query: Queries.COMMUNICATION_BATCH_QUERY,
    data,
    returnKey: ['communicationBatch'],
  });
