import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _TERMS_PARAMS = `
  $uuid: String
  $uuids: [String]
  $name: String
  $locations: [String]
  ${_PAGING_PARAMS}
`;

const _TERMS_VARIABLES = `
  uuid: $uuid
  uuids: $uuids
  name: $name
  locations: $locations
  ${_PAGING_VARIABLES}
`;

export const TERMS_QUERY = `
  query termsQuery (${_TERMS_PARAMS}) {
    terms (${_TERMS_VARIABLES}) {
      name
      uuid
      locations {
        name
        uuid
        retailer {
          name
          uuid
          logoImage
        }
      }
    }
  }
`;
