import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const MARKET_LIST_QUERY = `
  query MARKETS_LIST_QUERY(
    $isActive: Boolean
    ${_PAGING_PARAMS}
  ) {
    markets (
      isActive: $isActive
      ${_PAGING_VARIABLES}
    ) {
      id
      name
      state
      isActive
      hasLiveLocations
      markets {
        id
      }
    }
  }
`;
