import { graphMessage } from './api';

export interface paging {
  orderBy?: string;
  pageSize?: number;
  page?: number;
  search?: string;
  all?: string[];
  currentPage?: number;
}

export interface pagingComponent {
  pageSize: number;
  setPageSize: (number) => void;
  currentPage: number;
  setCurrentPage: (number) => void;
  totalCount: number;
  pageCount: number;
}

export interface mutationReturn {
  messages?: graphMessage[];
  ok?: boolean;
}

export enum DataKeys {
  BUDGETS = 'locationBudgets',
  BUDGET_PERIODS = 'budgetPeriods',
  CAMPAIGN = 'campaign',
  CAMPAIGNS = 'campaigns',
  CODE = 'code',
  CODES = 'codes',
  COMMUNICATION_BATCH = 'communicationBatch',
  COMPLIANCES = 'compliances',
  CUSTOMER = 'customer',
  CUSTOMERS = 'customers',
  EMPLOYMENT_REQUIREMENTS = 'employmentRequirements',
  FLEX_DETAILS = 'flexDetails',
  FILTERS = 'filters',
  LOCATION = 'location',
  LOCATION_METRICS = 'locationMetrics',
  LOCATIONS = 'locations',
  MARKETS = 'markets',
  MARKET = 'market',
  ME = 'me',
  ORGANIZATIONS = 'organizations',
  ONBOARDINGS = 'onboardings',
  PAPERWORK = 'paperwork',
  PLAYBOOKS = 'playbooks',
  PLAYBOOK_MESSAGE_TEMPLATES = 'playbookMessageTemplates',
  RELATION = 'relation',
  RETAILERS = 'retailers',
  RETAILER = 'retailer',
  REVIEWS = 'reviews',
  REVIEW = 'review',
  ROLES = 'roles',
  SHIFT = 'shift',
  SHIFT_COHORTS = 'shiftCohorts',
  SHIFT_MESSAGES = 'shiftMessages',
  SHIFTS = 'shifts',
  TAGS = 'tags',
  TERMS = 'terms',
  USER_PAPERWORK = 'userPaperwork',
  USERS = 'users',
  WIZARD_OPTIONS = 'wizardOptions',
  WORKER = 'worker',
  WORKERS = 'workers',
  WORKFLOWS = 'workflows',
  WORKER_SHIFTS = 'workershifts',
}

export enum MetaKeys {
  BUDGETS_META = 'locationBudgets_meta',
}
