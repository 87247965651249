import { ActionMap, review, ReviewType } from 'types';
import {
  convertEnumToFilterOptions,
  getFilterObjectByValue,
} from 'utils/helpers/filters';
import {
  CreatedFilters,
  FilterOption,
  ReviewStatusOptions,
} from 'utils/types/filters';

export interface ReviewsStateInterface {
  filters: {
    reviewType?: Array<FilterOption>;
    status?: Array<FilterOption>;
    rating?: Array<FilterOption>;
    prioritization?: Array<FilterOption>;
    hasFeedback?: Array<FilterOption>;
    hasCoaching?: Array<FilterOption>;
    locations?: Array<FilterOption>;
    retailers?: Array<FilterOption>;
    workers?: Array<FilterOption>;
    markets?: Array<FilterOption>; // IDs
    createdSince?: Array<FilterOption>;
    updatedSince?: Array<FilterOption>;
  };
  selectedReviews: Record<number, review>;
}

export const filterDefaults = {
  status: getFilterObjectByValue(
    ReviewStatusOptions.INTERNAL_REVIEW,
    convertEnumToFilterOptions(ReviewStatusOptions),
  ),
  rating: [],
  prioritization: [],
  hasFeedback: [],
  hasCoaching: [],
  locations: [],
  retailers: [],
  workers: [],
  markets: [],
  createdSince: [],
  updatedSince: getFilterObjectByValue(
    CreatedFilters.LAST_14_DAYS,
    convertEnumToFilterOptions(CreatedFilters),
  ),
  reviewType: getFilterObjectByValue(
    ReviewType.RETAILER,
    convertEnumToFilterOptions(ReviewType),
  ),
};

export const initialState = {
  filterOptions: {
    status: convertEnumToFilterOptions(ReviewStatusOptions),
    hasFeedback: [
      {
        label: 'Has Feedback',
        value: 'true',
      },
      {
        label: 'No Feedback',
        value: 'false',
      },
    ],
    hasCoaching: [
      {
        label: 'Has Coaching',
        value: 'true',
      },
      {
        label: 'No Coaching',
        value: 'false',
      },
    ],
    rating: [1, 2, 3, 4, 5].map(val => {
      return {
        label: `${val} star${val === 1 ? '' : 's'}`,
        value: val.toString(),
      };
    }),
    prioritization: [1, 2, 3].map(val => {
      return {
        label: `${val}`,
        value: val.toString(),
      };
    }),
    markets: [],
    createdSince: convertEnumToFilterOptions(CreatedFilters),
    updatedSince: convertEnumToFilterOptions(CreatedFilters),
    reviewType: convertEnumToFilterOptions(ReviewType),
  },
  filters: filterDefaults,
  selectedReviews: {},
};

export enum ReviewsActions {
  CHANGE_FILTER = 'CHANGE_REVIEWS_FILTER',
  RESET_FILTER = 'RESET_REVIEWS_FILTER',
  SELECT_REVIEW = 'SELECT_REVIEW',
  SELECT_REVIEWS = 'SELECT_REVIEWS',
  DESELECT_REVIEW = 'DESELECT_REVIEW',
  DESELECT_ALL = 'DESELECT_ALL_REVIEWS',
}

type ReviewsPayload = {
  [ReviewsActions.CHANGE_FILTER]: {
    [key: string]: FilterOption;
  };
  [ReviewsActions.RESET_FILTER]: string;
  [ReviewsActions.SELECT_REVIEW]: Partial<review>;
  [ReviewsActions.SELECT_REVIEWS]: Array<review>;
  [ReviewsActions.DESELECT_REVIEW]: string; // id
  [ReviewsActions.DESELECT_ALL]: null;
};

type ReviewsActionTypes =
  ActionMap<ReviewsPayload>[keyof ActionMap<ReviewsPayload>];

export const reviewsReducer = (draft, action: ReviewsActionTypes) => {
  const { type } = action;
  switch (type) {
    case ReviewsActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case ReviewsActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case ReviewsActions.SELECT_REVIEW: {
      draft.selectedReviews[action?.payload.id] = action?.payload;
      break;
    }
    case ReviewsActions.SELECT_REVIEWS: {
      action?.payload.forEach(review => {
        const key = review.id;
        draft.selectedReviews[key] = review;
      });
      break;
    }
    case ReviewsActions.DESELECT_REVIEW: {
      delete draft.selectedReviews[action?.payload];
      break;
    }
    case ReviewsActions.DESELECT_ALL: {
      draft.selectedReviews = {};
      break;
    }
    default:
      return draft;
  }
  return draft;
};
