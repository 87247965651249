import { filterNullsFromObject, filterUndefinedsFromObject } from 'helpers';
import { acquisitionCampaign } from 'types';
import { CampaignsFilterInterface } from 'utils/context/reducers/campaignsReducer';
import { FilterOption } from 'utils/types/filters';

export const convertSelectedCampaignToFilterOptions = (
  campaigns: Record<string, acquisitionCampaign>,
): Array<FilterOption> => {
  return Object.keys(campaigns).map(key => ({
    value: campaigns[key].uuid.toString(),
    label: campaigns[key].name,
    isFixed: false,
  }));
};

const campaignsMarketParser = (options: Array<FilterOption>) => {
  if (!options || options.length === 0) return undefined;
  return options.map(option => option.value);
};

export const convertFilterCampaignsOptionsToDataFilters = (
  filters: CampaignsFilterInterface,
) => {
  const dataFilters = {
    markets: campaignsMarketParser(filters?.markets),
  };
  return filterUndefinedsFromObject(filterNullsFromObject(dataFilters));
};
