import { focusColor } from './colors.config';

export const Button = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'lg',
    _focus: {
      boxShadow: `0 0 0 2px ${focusColor}`,
    },
  },
  sizes: {
    xl: {
      fontSize: 'xl',
      h: 14,
      minW: 12,
      px: 6,
      py: 8,
    },
  },
  defaultProps: {
    colorScheme: 'green',
  },
  variants: {
    subtle: {
      color: 'text.disabled',
      backgroundColor: 'bg.light',
      _hover: {
        backgroundColor: 'blackAlpha.50',
      },
      _active: {
        backgroundColor: 'blackAlpha.100',
      },
    },
    greenish: {
      fontWeight: 'medium',
      backgroundColor: 'green.50',
      color: 'green.500',
      borderColor: 'green.500',
      borderWidth: 1.5,
      borderStyle: 'solid',
      _hover: {
        bg: 'green.100',
      },
    },
    redish: {
      fontWeight: 'medium',
      backgroundColor: 'pink.50',
      color: 'red.500',
      borderColor: 'red.500',
      borderWidth: 1.5,
      borderStyle: 'solid',
      _hover: {
        bg: 'red.100',
      },
    },
    orangish: {
      fontWeight: 'medium',
      backgroundColor: 'orange.50',
      color: 'orange.600',
      borderColor: 'orange.500',
      borderWidth: 1.5,
      borderStyle: 'solid',
      _hover: {
        bg: 'orange.100',
      },
    },
    lightGreen: {
      fontWeight: 'medium',
      backgroundColor: 'green.50',
      color: 'green.500',
    },
    transparent: {
      colorScheme: 'grey',
      color: 'text.hint',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      fontWeight: 'medium',
      _hover: {
        backgroundColor: 'blackAlpha.50',
      },
      _active: {
        backgroundColor: 'blackAlpha.100',
      },
    },
    link: {
      alignSelf: 'flex-start',
      mt: 1,
      mb: 2,
      textDecoration: 'underline',
      color: 'main.blue',
      sx: {
        textDecorationColor: 'blue.100',
        textDecorationThickness: '1.5px',
        textUnderlineOffset: '4px',
      },
      _hover: { color: 'blue.400', bg: 'blue.100' },
      transition: 'background 0.2s',
    },
  },
};
