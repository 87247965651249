import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL, ReflexResponse } from 'types';

export interface fetchCampaignsProps {
  market?: string;
  uuid?: string;
}

export const fetchCampaigns = async (
  data: fetchCampaignsProps,
  query = Queries.CAMPAIGNS_LIST_QUERY,
): Promise<ReflexResponse> =>
  fetchObject({
    route: applyContext(GQL, 'CAMPAIGNS_LIST_QUERY'),
    query,
    data,
    returnKey: ['acquisitionCampaigns', 'campaigns_paging'],
  });

export interface mutateCreateCampaignProps {
  name: string;
  medium: string;
  market: string;
  description?: string;
  campaignStart: string;
  campaignEnd?: string;
}

export const mutateCreateCampaign = async (data: mutateCreateCampaignProps) =>
  mutateObject({
    route: applyContext(GQL, 'CREATE_ACQUISITION_CAMPAIGN_MUTATION'),
    query: Mutations.CREATE_ACQUISITION_CAMPAIGN_MUTATION,
    data,
    returnKey: 'createAcquisitionCampaign',
  });

export interface mutateUpdateCampaignProps {
  uuid: string;
  name?: string;
  description?: string;
  medium?: string;
  campaignStart?: Date;
  campaignEnd?: Date;
  market?: string; // UUID
  addUsers?: string[]; // UUID
  removeUsers?: string[]; // UUID
}

export const mutateUpdateCampaign = async (data: mutateUpdateCampaignProps) =>
  mutateObject({
    route: applyContext(GQL, 'UPDATE_ACQUISITION_CAMPAIGN_MUTATION'),
    query: Mutations.UPDATE_ACQUISITION_CAMPAIGN_MUTATION,
    data,
    returnKey: 'updateAcquisitionCampaign',
  });

export interface mutateCreateCampaignRecordProps {
  acquisitionCampaign: string; //uuid
  recordDate: string; // Datetime
  spendAmount: number;
  clicks: number;
  impressions: number;
}

export const mutateCreateCampaignRecord = async (
  data: mutateCreateCampaignRecordProps,
) =>
  mutateObject({
    route: applyContext(GQL, 'CREATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION'),
    query: Mutations.CREATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION,
    data,
    returnKey: 'createAcquisitionCampaignRecord',
  });

export interface mutateUpdateCampaignRecordProps {
  id: string; // id
  recordDate?: string; // Datetime
  spendAmount?: number;
  clicks?: number;
  impressions?: number;
}

export const mutateUpdateCampaignRecord = async (
  data: mutateUpdateCampaignRecordProps,
) =>
  mutateObject({
    route: applyContext(GQL, 'UPDATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION'),
    query: Mutations.UPDATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION,
    data,
    returnKey: 'updateAcquisitionCampaignRecord',
  });

export interface mutateDeleteCampaignRecordProps {
  id: string; // id
}

export const mutateDeleteCampaignRecord = async (
  data: mutateDeleteCampaignRecordProps,
) =>
  mutateObject({
    route: applyContext(GQL, 'DELETE_ACQUISITION_CAMPAIGN_RECORD_MUTATION'),
    query: Mutations.DELETE_ACQUISITION_CAMPAIGN_RECORD_MUTATION,
    data,
    returnKey: 'deleteAcquisitionCampaignRecord',
  });

export interface mutateCreateAttributionCodeProps {
  acquisitionCampaign: string; // UUID
  name: string;
  queryString: string;
}

export const mutateCreateAttributionCode = async (
  data: mutateCreateAttributionCodeProps,
) =>
  mutateObject({
    route: applyContext(GQL, 'CREATE_ATTRIBUTION_CODE_MUTATION'),
    query: Mutations.CREATE_ATTRIBUTION_CODE_MUTATION,
    data,
    returnKey: 'createAttributionCode',
  });

export interface mutateUpdateAttributionCodeProps {
  attributionCode: string; // UUID
  acquisitionCampaign?: string; // UUID
  name?: string;
  queryString?: string;
  addUsers?: string[];
  removeUsers?: string[];
}

export const mutateUpdateAttributionCode = async (
  data: mutateUpdateAttributionCodeProps,
) =>
  mutateObject({
    route: applyContext(GQL, 'UPDATE_ATTRIBUTION_CODE_MUTATION'),
    query: Mutations.UPDATE_ATTRIBUTION_CODE_MUTATION,
    data,
    returnKey: 'updateAttributionCode',
  });
