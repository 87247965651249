import { document, log, user, uuid as uuidType } from '../../types';
import { compliance } from './compliance';

export enum PaperworkType {
  W2 = 'W2',
  W9 = 'W9',
  I9 = 'I9',
  FORM_1099 = 'FORM_1099',
  OTHER = 'OTHER',
}

export enum PaperworkStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type Paperwork = {
  id?: number;
  name?: string;
  uuid?: uuidType;
  paperworkType?: PaperworkType;
  status?: PaperworkStatus;
  document?: document;
  required?: boolean;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  compliances?: compliance[];
  userPaperwork?: UserPaperwork[];
};

export type UserPaperwork = {
  id?: number;
  uuid?: uuidType;
  user?: user;
  paperwork?: Paperwork;
  paperworkLink?: string;
  completed?: string; // DateTime
  lastSentAt?: string; // DateTime
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  logs?: log[];
};
