export const CREATE_NOTE_MUTATION = `
  mutation CREATE_NOTE_MUTATION(
    $targetUuid: String!
    $content: String
    $type: String
  ) {
    createNote(
      targetUuid: $targetUuid
      content: $content
      type: $type
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_NOTE_MUTATION = `
  mutation UPDATE_NOTE_MUTATION(
    $uuid: String!
    $content: String
    $type: String
  ) {
    updateNote(
      uuid: $uuid
      content: $content
      type: $type
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const DELETE_NOTE_MUTATION = `
  mutation DELETE_NOTE_MUTATION(
    $uuid: String!
  ) {
    deleteNote(
      uuid: $uuid
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
