import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  GQL,
  paging,
  ReflexResponse,
  ShiftCriteriaExtensionStatus,
} from 'types';

export interface mutateUpdateShiftCriteriaExtensionProps {
  extensions?: number[]; // extension ids
  status?: ShiftCriteriaExtensionStatus;
  args?: string;
}

export const mutateUpdateShiftCriteriaExtension = async (
  data: mutateUpdateShiftCriteriaExtensionProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'UPDATE_SHIFT_CRITERIA_EXTENSION'),
    query: Mutations.UPDATE_SHIFT_CRITERIA_EXTENSION,
    data,
    returnKey: 'updateShiftCriteriaExtension',
  });
};

export interface mutateFastForwardPlaybookProps {
  shift?: string; // shift uuid
  stepNumber?: number;
}

export const mutateFastForwardPlaybook = async (
  data: mutateFastForwardPlaybookProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'FAST_FORWARD_PLAYBOOK_MUTATION'),
    query: Mutations.FAST_FORWARD_PLAYBOOK_MUTATION,
    data,
    returnKey: 'fastForwardPlaybook',
  });
};

export interface fetchPlaybookMessageTemplateProps extends paging {
  isActive?: boolean;
}

export const fetchPlaybookMessageTemplates = async (
  data: fetchPlaybookMessageTemplateProps,
  query = Queries.PLAYBOOK_MESSAGE_TEMPLATE_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = [
    'playbookMessageTemplates',
    'playbookMessageTemplates_paging',
  ],
): Promise<ReflexResponse> => {
  return await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'PLAYBOOK_MESSAGE_TEMPLATE_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey,
  });
};
