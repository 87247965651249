import { applyContext, fetchObject, Queries } from 'helpers';
import { paging, ReflexResponse, GQL } from 'types';

export interface fetchRolesProps extends paging {
  isRequestable?: boolean;
  isActive?: boolean;
}

export const fetchRoles = async (
  data?: fetchRolesProps,
  query = Queries.ROLES_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['roles', 'roles_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'ROLES_QUERY'),
    query,
    data,
    returnKey,
  });
