import { isEmpty } from 'lodash';
import { ReflexStoreWeb } from 'types';

export const REFLEX_STORE = 'rflx';

export function getStore(storeName = REFLEX_STORE) {
  if (typeof window === 'undefined') return {};
  return JSON.parse(window.localStorage.getItem(storeName));
}

const defaultReflexStoreWebStore: ReflexStoreWeb = {
  user: {},
  meta: {},
  tags: [],
  preferences: {},
};

export function createReflexStoreWeb(): void {
  if (typeof window === 'undefined') return null;
  window.localStorage.setItem(
    REFLEX_STORE,
    JSON.stringify(defaultReflexStoreWebStore),
  );
}

export function updateReflexStoreWeb(
  updateData: Partial<ReflexStoreWeb>,
  storeName = REFLEX_STORE,
): void {
  if (typeof window === typeof undefined || !Object.keys(updateData).length)
    return null;
  const currentStore = getStore(storeName);
  if (!currentStore || isEmpty(currentStore)) createReflexStoreWeb();
  const { user, meta, tags, preferences } = currentStore || {};
  window.localStorage.setItem(
    storeName,
    JSON.stringify({
      user: { ...user, ...updateData.user },
      meta: { ...meta, ...updateData.meta },
      tags: Array.from(new Set([...(tags || []), ...(updateData.tags || [])])),
      preferences: { ...preferences, ...updateData.preferences },
    }),
  );
}
