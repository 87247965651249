import { user } from 'types';
import { address as addressType } from 'types/src/address';

export const capitalizeFirstLetter = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export function titleCase(str: string): string {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function camelCase(str: string): string {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function snakeToTitleCase(str: string): string {
  return str.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
}

export function snakeToCamel(str: string): string {
  return str
    .toLowerCase()
    .replace(/_([a-z])/g, (match, group) => group.toUpperCase());
}

export const convertToSearchParams = (str: string): string => {
  return str
    .replace(/([A-Z])/g, function ($1) {
      return `_${$1.toLowerCase()}`;
    })
    .replace(/\./g, '__');
};

export const displayAsHuman = (input): string => {
  if (input === null) {
    return 'N/A';
  }
  if (input === false) {
    return 'No';
  }
  if (input === true) {
    return 'Yes';
  }
  return input;
};

/**
 * Adds an 's' (or 'es') to the end of a string if referring to a group of items.
 * Can be extended in the future to handle more complex cases
 * Currently case agnostic
 * @param s String to pluralize
 * @param count Number of items
 * @param customSuffix Optional: plural suffix
 * @returns string Corrected string
 */
export const pluralize = (
  s: string,
  count: number,
  customSuffix?: string,
): string => {
  let pluralSuffix = s.toLowerCase().endsWith('x') ? 'es' : 's';
  if (customSuffix) {
    pluralSuffix = customSuffix;
  }
  return s + (count !== 1 ? pluralSuffix : '');
};

/**
 * @summary returns address as a string.
 * @fucntion formatAddress
 * @param {address}
 * @returns {string}
 */
export const formatAddressAsString = (addr: addressType): string => {
  return `${addr.address1 && `${addr.address1}, `}${
    addr.address2 && `${addr.address2}, `
  }${addr.city && `${addr.city}, `}${addr.state && `${addr.state}, `}${
    addr.zip && `${addr.zip}`
  }`;
};

interface formatUserNameForDisplayOptions {
  short?: boolean;
  firstOnly?: boolean;
}
/**
 * @summary Returns a display name for a user. Uses preferred name if one exists. Can also be configured to return a truncated name
 * @param { user } user instance of user type
 * @param { formatUserNameForDisplayOptions } options configurable options for display name
 * @returns { string }
 */
export const formatUserNameForDisplay = (
  user: Partial<Pick<user, 'firstName' | 'lastName' | 'preferredName'>>,
  options?: formatUserNameForDisplayOptions,
) => {
  if (!user?.firstName) return '';

  const getFirstName = () => {
    if (user.preferredName) return user.preferredName;
    return user.firstName;
  };

  const getLastName = () => {
    if (!user?.lastName || options?.firstOnly) return '';
    if (options?.short) return `${user.lastName.charAt(0)}.`;
    return user.lastName;
  };

  const firstName = getFirstName();
  const lastName = getLastName();

  return titleCase(`${firstName}${lastName ? ` ${lastName}` : ''}`);
};

export const convertArrayToEnglishList = (arr: Array<string>): string => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  return arr.join(', ').replace(/, ([^,]*)$/, ' and $1');
};

export const formatNumWithCommas = (num: number, decimals = 0): string => {
  return `${num?.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;
};

export const stringToBoolean = (val: string): boolean => val === 'true';

export const compactNumber = (num: number | string): string => {
  const number = typeof num === 'string' ? parseInt(num) : num;
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(number);
};
