import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { ReflexResponse, uuid, uuid as uuidType } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchTagsProps {
  id?: number;
  name?: string;
  createdSince?: string; // yyyy-MM-dd
  createdTo?: string; // yyyy-MM-dd
  createdBy?: Array<uuidType>;
  users?: Array<uuidType>;
  isArchived?: boolean;
  isSystem?: boolean;
  isListVisible?: boolean;
}

export const fetchTags = async (
  data: fetchTagsProps,
  query = Queries.TAGS_LIST_QUERY,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'TAGS_LIST_QUERY'), // FUTURE: override to specify when using FULL vs LIST query
    query,
    data,
    returnKey: 'tags',
  });

export interface mutateTagCreateProps {
  name: string;
  description?: string;
  createdBy: uuid;
  isListVisible?: boolean;
}

export const mutateTagCreate = async (data: mutateTagCreateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_TAG_MUTATION'),
    query: Mutations.CREATE_TAG_MUTATION,
    data,
    returnKey: 'createTag',
  });

export interface mutateTagUpdateProps {
  id?: number;
  ids?: Array<number>;
  name?: string;
  description?: string;
  isListVisible?: boolean;
  isArchived?: boolean;
  addUsers?: Array<uuidType>;
  removeUsers?: Array<uuidType>;
  addLocations?: Array<uuidType>;
  removeLocations?: Array<uuidType>;
  addShifts?: Array<uuidType>;
  removeShifts?: Array<uuidType>;
}

export const mutateTagUpdate = async (data: mutateTagUpdateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_TAG_MUTATION'),
    query: Mutations.UPDATE_TAG_MUTATION,
    data,
    returnKey: 'updateTag',
  });
