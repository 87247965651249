export enum IntegrationTypes {
  NONE = 'NONE',
  EVEREE = 'EVEREE',
  GIGWAGE = 'GIGWAGE',
  CHECKR = 'CHECKR',
  HELLOSIGN = 'HELLOSIGN',
  HELLOSIGN_W2 = 'HELLOSIGN_W2',
  HELLOSIGN_1099 = 'HELLOSIGN_1099',
  SIMPLE_TEXTING = 'SIMPLE_TEXTING',
  INTERNAL = 'INTERNAL',
}

export enum IntegrationStates {
  NOT_STARTED = 'NOT_STARTED',
  SENT = 'SENT',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  COMPLETE = 'COMPLETE',
}
