import { useEffect, useMemo, useState } from 'react';
import { getStore, REFLEX_STORE } from 'helpers';
import {
  ReflexOpsStore,
  updateStore,
  UserPreferences,
  UserPreferencesDefaultFiltersInterface,
  UserPreferencesInterface,
} from 'utils/helpers/localStorage';

interface PreferencesInterface {
  preferences: UserPreferencesInterface;
  store?: ReflexOpsStore;
  updatePreference?: (preference: UserPreferences, update: unknown) => void;
  updateStore?: (data: ReflexOpsStore) => void;
}

export const usePreferences = (): PreferencesInterface => {
  const [currentStore, setCurrentStore] = useState<ReflexOpsStore>({});
  const trackedStore = useMemo(() => getStore(REFLEX_STORE) || {}, []);

  const updatePreference = (preference: UserPreferences, update: unknown) => {
    // FUTURE: update this to better handle nested preferences
    if (preference === UserPreferences.DEFAULT_FILTERS) {
      const _update = update as UserPreferencesDefaultFiltersInterface;
      updateStore({
        preferences: {
          [UserPreferences.DEFAULT_FILTERS]: {
            ...currentStore?.preferences[UserPreferences.DEFAULT_FILTERS],
            ..._update,
          },
        },
      });
    }
  };

  useEffect(() => {
    setCurrentStore(trackedStore);
  }, [trackedStore]);

  // LATER: figure out better way to spy on the store
  // right now it doesn't seem like you can depend on store values
  // in useEffect functions
  // side effect here: must reload for preference changes to take effect

  return {
    preferences: currentStore?.preferences as UserPreferencesInterface,
    store: currentStore,
    updatePreference,
  };
};
