export const FLEX_DETAILS_QUERY = `
  query FLEX_DETAILS_QUERY (
    $location: String
    $shift: String
    $roles: [String]
  ) {
    flexDetails (
      location: $location
      shift: $shift
      orderBy: asc
      roles: $roles
    ) {
      uuid
      name
      detailType
      order
      values {
        uuid
        valueType
        value
        meta
      }
      visibility
    }
  }
`;

export const FLEX_DETAILS_LOCATION_QUERY = `
  query FLEX_DETAILS_LOCATION_QUERY (
    $uuid: String
  ) {
    locations (
      uuid: $uuid
    ) {
      name
      uuid
      mapImg
      useV2FlexDetails
      retailer {
        name
        uuid
        brandImage
        linkWebsite
        linkInstagram
        linkTiktok
      }
      address {
        address1
        address2
        city
        state
        zip
        mapHref
        fullAddress
      }
    }
  }
`;
