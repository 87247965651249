import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { focusColor } from './colors.config';

const subtle = defineStyle({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'blackAlpha.200',
});

export const Divider = defineStyleConfig({
  variants: { subtle },
});
