import {
  applyContext,
  getStore,
  mutateObject,
  Mutations,
  updateReflexStoreWeb,
} from 'helpers';
import { GQL } from 'types/src/routes';

const ATTRIBUTION_ID_KEY = 'attributionId';

export const getParsedAttributionFromStorage = (): {
  attributionId: string;
  date: string;
} | null => {
  const storedData = getStore();

  if (!storedData || !storedData.meta || !storedData.meta[ATTRIBUTION_ID_KEY])
    return null;

  return storedData.meta[ATTRIBUTION_ID_KEY];
};

const saveAttributionToStorage = (data: {
  attributionId: string;
  date: string;
}) => {
  updateReflexStoreWeb({ meta: { [ATTRIBUTION_ID_KEY]: data } });
};

export const updateMessengerAttributionId = async (
  messengerAttributionId: string,
) => {
  const currentAttributionData = getParsedAttributionFromStorage();
  const currentAttributionId = currentAttributionData
    ? currentAttributionData?.attributionId
    : null;

  if (currentAttributionId !== messengerAttributionId) {
    const newData = {
      attributionId: messengerAttributionId,
      date: new Date().toISOString(),
    };

    saveAttributionToStorage(newData);

    await mutateObject({
      route: applyContext(GQL, 'UPDATE_MESSENGER_ATTRIBUTION_ID_MUTATION'),
      query: Mutations.ACKNOWLEDGE_COMMUNICATION_MUTATION,
      data: newData,
      returnKey: 'acknowledgeCommunication',
    });
  }
};
