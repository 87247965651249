export const REFLEX_SUPPORT_NUMBER = '(512) 894-8972';
export const REFLEX_SUPPORT_NUMBER_WORKER = '(512) 649-9394';
export const REFLEX_TEXT_NUMBER =
  process.env.NEXT_PUBLIC_TEXTING_SERVICE === 'twilio'
    ? '(512) 649-0306'
    : '(833) 889-0189';
export const REFLEX_SUPPORT_EMAIL = 'support@reflex.careers';
export const REFLEX_TEAM_EMAIL = 'team@reflex.careers';
export const REFLEX_ADDRESS = '106 E. 6th Street Ste 900-156 Austin, TX 78701';
export const TYPEFORM_REVIEW_ABOUT_WORKER =
  'https://reflexhq.typeform.com/to/wILf80w7?name=WORKERNAME&uid=WORKERID&sid=SHIFTID';
export const REFLEX_SHORT_LINK_ROOT = 'https://rxl.ink';
export const REFLEX_SHORT_LINK_SHIFT = `${REFLEX_SHORT_LINK_ROOT}/s`;
export const REFLEX_SHORT_LINK_EXPLORE = `${REFLEX_SHORT_LINK_ROOT}/e`;
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/workreflex';
export const SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/reflexinc';
export const SOCIAL_INSTAGRAM = 'https://www.instagram.com/workreflex_';
export const SOCIAL_TWITTER = 'https://twitter.com/workreflex';
export const SOCIAL_TIKTOK = 'https://www.tiktok.com/@workreflex';
export const REFLEX_JOB_BOARD = 'https://jobs.ashbyhq.com/reflex/';
export const HIDE_MARKET_LIST = ['Reflex'];
