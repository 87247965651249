import { CamelToSnakeCaseNested } from './_helpers';
import { market } from './market';
import { user } from './user';

export type campaign = {
  id?: string;
  uuid?: string;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  title?: string;
  description?: string;
  terms?: string;
  start?: string; // DateTime
  end?: string; // DateTime
  isActive?: boolean;
  name?: string;
};

export enum CampaignMedium {
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
  REFERRAL = 'REFERRAL',
  OTHER = 'OTHER',
}

export type attributionCode = {
  uuid?: string; // UUID
  name?: string;
  queryString?: string;
  id?: string;
  campaign?: acquisitionCampaign;
};

export type acquisitionCampaignRecord = {
  recordDate?: string; // DateTime
  spendAmount?: number;
  clicks?: number;
  impressions?: number;
  id?: string;
};

export type acquisitionCampaign = {
  uuid?: string;
  name?: string;
  description?: string;
  createdBy?: user;
  createdAt?: string; // DateTime
  medium?: CampaignMedium;
  campaignStart?: string; // DateTime
  campaignEnd?: string; // DateTime
  attributionCodes?: attributionCode[];
  isActive?: boolean;
  market?: market;
  users?: user[];
  records?: acquisitionCampaignRecord[];
  totalSpend?: number;
  totalImpressions?: number;
  totalConversions?: number;
  totalClicks?: number;
};

export type apiCampaign = CamelToSnakeCaseNested<campaign>;
