import { ActionMap } from 'types';
import { MarketSelectOption } from 'types/src/form';
import { apiShiftDetail, graphShift } from 'types/src/shift';
import { graphUserLight, worker } from 'types/src/user';
import { CalendarDate, CalendarValues } from 'ui';
import {
  convertArrayToFilterOptions,
  convertEnumToFilterOptions,
  getFilterObjectByValue,
  getFilterObjectByValues,
} from 'utils/helpers/filters';
import {
  FilterOption,
  ShiftPaperworkFilters,
  ShiftStatusFilters,
  TimingFilters,
} from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export interface ShiftsStateInterface {
  activeShift: graphShift;
  filters: {
    markets?: Array<MarketSelectOption>;
    timing?: Array<FilterOption>;
    status?: Array<FilterOption>;
    retailers?: Array<FilterOption>;
    locations?: Array<FilterOption>;
    paperwork?: Array<FilterOption>;
    dates?: CalendarValues;
    retailerSize?: Array<FilterOption>;
    retailerCategory?: Array<FilterOption>;
    tags?: Array<FilterOption>;
    locationTags?: Array<FilterOption>;
    isRetailerApproved?: Array<FilterOption>;
    requestType?: Array<FilterOption>;
  };
  formState: formState;
  selectedShifts?: {
    [uuid: string]: Partial<graphShift>;
  };
  selectedWorkers?: worker;
}

export const filterDefaults = {
  timing: getFilterObjectByValue(
    TimingFilters.TODAY,
    convertEnumToFilterOptions(TimingFilters),
  ),
  status: getFilterObjectByValues(
    [
      ShiftStatusFilters.OPEN,
      ShiftStatusFilters.FILLED,
      ShiftStatusFilters.FILLED_PARTIAL,
      ShiftStatusFilters.OVERBOOKED,
    ],
    convertEnumToFilterOptions(ShiftStatusFilters),
  ),
  paperwork: getFilterObjectByValue(
    ShiftPaperworkFilters.ALL,
    convertEnumToFilterOptions(ShiftPaperworkFilters),
  ),
  markets: [],
  retailers: [],
  locations: [],
  dates: {
    start: null as CalendarDate,
    end: null as CalendarDate,
  },
  retailerSize: [],
  retailerCategory: [],
  tags: [],
  locationTags: [],
  isRetailerApproved: [
    convertArrayToFilterOptions([true, false]).find(
      option => option.value === 'true',
    ),
  ],
  requestType: [],
};

export const initialState = {
  activeShift: {},
  filters: filterDefaults,
  formState: formStateDefaults,
  selectedShifts: {},
  selectedWorkers: {},
};

export enum ShiftsActions {
  CHANGE_FILTER = 'CHANGE_SHIFTS_FILTER',
  RESET_FILTER = 'RESET_SHIFTS_FILTER',
  RESET_ALL_FILTERS = 'RESET_ALL_SHIFTS_FILTER',
  SELECT_SHIFT = 'SELECT_SHIFT',
  REMOVE_SHIFT = 'REMOVE_SELECTED_SHIFT',
  REMOVE_ALL_SHIFTS = 'REMOVE_ALL_SELECTED_SHIFTS',
  ADD_WORKER = 'ADD_SHIFT_WORKER',
  ADD_WORKERS = 'ADD_SHIFT_WORKERS',
  REMOVE_ALL_WORKERS = 'REMOVE_SHIFT_WORKERS',
  REMOVE_WORKER = 'REMOVE_SHIFT_WORKER',
  RESET_SHIFTS_CONTEXT = 'RESET_SHIFTS_CONTEXT',
  UPDATE_ACTIVE_SHIFT = 'UPDATE_ACTIVE_SHIFT',
  UPDATE_FORM_STATE = 'UPDATE_SHIFT_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_SHIFT_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_SHIFT_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_SHIFT_FORM_ERRORS',
}

type ShiftsPayload = {
  [ShiftsActions.CHANGE_FILTER]: {
    [key: string]: FilterOption;
  };
  [ShiftsActions.RESET_FILTER]: string;
  [ShiftsActions.RESET_ALL_FILTERS]: null;
  [ShiftsActions.SELECT_SHIFT]: Partial<apiShiftDetail>;
  [ShiftsActions.REMOVE_SHIFT]: { uuid: string };
  [ShiftsActions.REMOVE_ALL_SHIFTS]: null;
  [ShiftsActions.ADD_WORKER]: Partial<graphUserLight>;
  [ShiftsActions.ADD_WORKERS]: Array<Partial<graphUserLight>>;
  [ShiftsActions.REMOVE_ALL_WORKERS]: null;
  [ShiftsActions.REMOVE_WORKER]: { uuid: string };
  [ShiftsActions.RESET_SHIFTS_CONTEXT]: null;
  [ShiftsActions.UPDATE_ACTIVE_SHIFT]: graphShift;
  [ShiftsActions.UPDATE_FORM_PROCESSING]: boolean;
  [ShiftsActions.UPDATE_FORM_SUCCESS]: boolean;
  [ShiftsActions.UPDATE_FORM_ERRORS]: Array<string>;
};

type ShiftsActionTypes =
  ActionMap<ShiftsPayload>[keyof ActionMap<ShiftsPayload>];

export const shiftsReducer = (draft, action: ShiftsActionTypes) => {
  const { type } = action;
  switch (type) {
    case ShiftsActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case ShiftsActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case ShiftsActions.RESET_ALL_FILTERS:
      draft.filters = filterDefaults;
      break;
    case ShiftsActions.SELECT_SHIFT:
      draft.selectedShifts[action?.payload.uuid] = action?.payload;
      break;
    case ShiftsActions.REMOVE_SHIFT:
      delete draft.selectedShifts[action?.payload.uuid];
      break;
    case ShiftsActions.REMOVE_ALL_SHIFTS:
      draft.selectedShifts = {};
      break;
    case ShiftsActions.ADD_WORKER:
      draft.selectedWorkers[action?.payload.uuid] = action?.payload;
      break;
    case ShiftsActions.ADD_WORKERS:
      action?.payload.forEach(worker => {
        draft.selectedWorkers[worker.uuid] = worker;
      });
      break;
    case ShiftsActions.REMOVE_WORKER:
      delete draft.selectedWorkers[action?.payload.uuid];
      break;
    case ShiftsActions.REMOVE_ALL_WORKERS:
      draft.selectedWorkers = {};
      break;
    case ShiftsActions.RESET_SHIFTS_CONTEXT:
      draft = initialState;
      break;
    case ShiftsActions.UPDATE_ACTIVE_SHIFT:
      draft.activeShift = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
