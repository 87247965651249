import {
  location,
  LocationPermission,
  permissionsMap,
  user,
  UserLocationPermissions,
  userPermissionMap,
} from 'types';

export const defaultUserPermissionMap = (value = false): userPermissionMap => {
  return Object.keys(LocationPermission).reduce(
    (acc, key) => {
      acc[LocationPermission[key as keyof typeof LocationPermission]] = value;
      return acc;
    },
    {} as Record<LocationPermission, boolean>,
  );
};

export const permissionOverlap = (
  allowedPermissions: LocationPermission[],
  userPermissions: LocationPermission[],
) => {
  return userPermissions?.some(permission =>
    allowedPermissions?.includes(permission),
  );
};

export const hasPermission = (
  permission: LocationPermission,
  map: permissionsMap,
) => {
  return Object.values(map).some(permissionArray =>
    permissionArray.includes(permission),
  );
};

// useful when you are querying ~location~ node
// and have approvedUsers results
export const getUserPermissionFromLocationAccess = (
  location: location,
  userUuid: string,
): userPermissionMap => {
  if (!location || !Object?.keys(location)?.length || !userUuid)
    return defaultUserPermissionMap();
  const approvedUser =
    location?.approvedUsers?.filter(a => a?.user?.uuid === userUuid)[0] || {};
  if (!approvedUser || !Object.keys(approvedUser)?.length)
    return defaultUserPermissionMap();

  return Object.keys(LocationPermission).reduce(
    (acc, key) => {
      const permission =
        LocationPermission[key as keyof typeof LocationPermission];
      acc[permission] = approvedUser?.permissions.includes(permission);
      return acc;
    },
    {} as Record<LocationPermission, boolean>,
  );
};

// useful when you are querying ~user~ node
// and have locationPermissions results
export const getUserPermissionsFromUserLocationPermissions = (
  user: user,
  locationUuid: string,
): userPermissionMap => {
  if (
    !user?.locationPermissions ||
    !Object?.keys(user?.locationPermissions)?.length ||
    !locationUuid
  )
    return defaultUserPermissionMap();
  const permissionsForLocation = user?.locationPermissions.filter(
    lp => lp?.location?.uuid === locationUuid,
  );
  const permissionsArray = permissionsForLocation.map(lp => lp?.permissionType);
  return Object.keys(LocationPermission).reduce(
    (acc, key) => {
      const permission =
        LocationPermission[key as keyof typeof LocationPermission];
      acc[permission] = permissionsArray.includes(permission);
      return acc;
    },
    {} as Record<LocationPermission, boolean>,
  );
};

export const getUserLocationsForPermission = (
  locationPermissions: UserLocationPermissions,
  permission: LocationPermission,
): location[] => {
  return locationPermissions
    ?.filter(lp => lp?.permissionType === permission)
    ?.map(lp => lp?.location);
};

export const getPermissionsMapFromUserLocationPermissions = (
  permissions: UserLocationPermissions,
): permissionsMap => {
  return permissions.reduce((acc, { location, permissionType }) => {
    acc[location.uuid] = acc[location.uuid] || [];
    acc[location.uuid].push(permissionType);
    return acc;
  }, {} as permissionsMap);
};
