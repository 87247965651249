import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  FlexDetailType,
  FlexDetailValue,
  FlexDetailVisibility,
  paging,
  ReflexResponse,
  uuid as uuidType,
} from 'types';
import { GQL } from 'types/src/routes';

export interface fetchFlexDetailsProps extends paging {
  location: uuidType; // Location uuid
  roles?: Array<string>;
  name?: string;
  detailType?: FlexDetailType;
  order?: number;
  visibility?: FlexDetailVisibility;
  values?: FlexDetailValue[];
}

export const fetchFlexDetails = async (
  data: fetchFlexDetailsProps,
  query = Queries.FLEX_DETAILS_QUERY,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'FLEX_DETAILS_QUERY'),
    query,
    data,
    returnKey: ['flexDetails', 'locations'],
  });

export interface mutateFlexDetailsCreateProps {
  name?: string;
  detailType: string;
  location: string;
  roles?: string[];
  order?: number;
  visibility?: FlexDetailVisibility;
}

export const mutateFlexDetailsCreate = async (
  data: mutateFlexDetailsCreateProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_FLEX_DETAIL'),
    query: Mutations.CREATE_FLEX_DETAIL,
    data,
    returnKey: 'createFlexDetail',
  });

export interface mutateFlexDetailsUpdateProps {
  uuid: string;
  name?: string;
  detailType?: string;
  location?: string;
  roles?: string[];
  order?: number;
  visibility?: FlexDetailVisibility;
}

export const mutateFlexDetailsUpdate = async (
  data: mutateFlexDetailsUpdateProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_FLEX_DETAIL'),
    query: Mutations.UPDATE_FLEX_DETAIL,
    data,
    returnKey: 'updateFlexDetail',
  });

export interface mutateFlexDetailDeleteProps {
  uuid?: string; // FlexDetail UUID
  retailer?: string; // Retailer UUID
  detailType?: FlexDetailType;
}

export const mutateFlexDetailDelete = async (
  data: mutateFlexDetailDeleteProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'DELETE_FLEX_DETAIL'),
    query: Mutations.DELETE_FLEX_DETAIL,
    data,
    returnKey: 'deleteFlexDetail',
  });

export interface mutateDuplicateFlexDetailsProps {
  fromLocation: string;
  toLocation: string;
}

export const mutateDuplicateFlexDetails = async (
  data: mutateDuplicateFlexDetailsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'DUPLICATE_FLEX_DETAILS'),
    query: Mutations.DUPLICATE_FLEX_DETAILS,
    data,
    returnKey: 'duplicateFlexDetails',
  });

export interface mutateFlexDetailsValueCreateProps {
  flexDetail: string;
  valueType: string;
  value: string;
  meta?: string;
}

export const mutateFlexDetailsValueCreate = async (
  data: mutateFlexDetailsValueCreateProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_FLEX_DETAIL_VALUE'),
    query: Mutations.CREATE_FLEX_DETAIL_VALUE,
    data,
    returnKey: 'createFlexDetailValue',
  });

export interface mutateFlexDetailsValueUpdateProps {
  uuid: string;
  flexDetail?: string;
  valueType?: string;
  value?: string;
  meta?: string;
}

export const mutateFlexDetailsValueUpdate = async (
  data: mutateFlexDetailsValueUpdateProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_FLEX_DETAIL_VALUE'),
    query: Mutations.UPDATE_FLEX_DETAIL_VALUE,
    data,
    returnKey: 'updateFlexDetailValue',
  });

export interface mutateFlexDetailValueDeleteProps {
  uuid: string;
}

export const mutateFlexDetailValueDelete = async (
  data: mutateFlexDetailValueDeleteProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'DELETE_FLEX_DETAIL_VALUE'),
    query: Mutations.DELETE_FLEX_DETAIL_VALUE,
    data,
    returnKey: 'deleteFlexDetailValue',
  });

export interface mutateFlexDetailsValueUpsertProps {
  uuid: string;
  flexDetail?: string;
  valueType?: string;
  value?: string;
  meta?: string;
}
export const mutateFlexDetailsValueUpsert = async (
  data: mutateFlexDetailsValueUpsertProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPSERT_FLEX_DETAIL_VALUE'),
    query: Mutations.UPSERT_FLEX_DETAIL_VALUE,
    data,
    returnKey: 'upsertFlexDetailValue',
  });

export interface mutateFlexDetailsSyncProps {
  uuid: uuidType; // FlexDetail UUID
}

export const mutateFlexDetailsSync = async (data: mutateFlexDetailsSyncProps) =>
  await mutateObject({
    route: applyContext(GQL, 'SYNC_FLEX_DETAIL'),
    query: Mutations.SYNC_FLEX_DETAIL,
    data,
    returnKey: 'syncFlexDetail',
  });
