import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { ReflexResponse } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchShiftCohortsProps {
  shifts: [string];
}

export const fetchShiftCohorts = async (
  data: fetchShiftCohortsProps,
  query = Queries.FETCH_SHIFT_COHORTS_QUERY,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'FETCH_SHIFT_COHORTS_QUERY'),
    query,
    data,
    returnKey: 'cohorts',
  });

export interface findCohortUsersProps {
  cohortDefinition: Record<string, any>;
  shift?: string;
}

export const findCohortUsers = async (
  data: findCohortUsersProps,
  query = Queries.FIND_COHORT_USERS_QUERY,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'FIND_COHORT_USERS_QUERY'),
    query,
    data,
    returnKey: 'cohorts',
  });

export interface mutateCreateCohortWorkershiftsProps {
  shiftUuid: string;
  cohortName: string;
  cohortDefinition: Record<string, any>;
}

export const mutateCreateCohortWorkerShifts = async ({
  shiftUuid,
  cohortName,
  cohortDefinition,
}: mutateCreateCohortWorkershiftsProps) =>
  await mutateObject({
    query: Mutations.CREATE_COHORT_WORKERSHIFTS_MUTATION,
    route: applyContext(GQL, 'CREATE_COHORT_WORKERSHIFTS'),
    data: {
      shift: shiftUuid,
      cohortName: cohortName,
      cohortDefinition: cohortDefinition,
    },
    returnKey: 'createCohortWorkerShifts',
  });
