export const CREATE_ACQUISITION_CAMPAIGN_MUTATION = `    
  mutation CREATE_ACQUISITION_CAMPAIGN_MUTATION (
    $name: String!
    $description: String
    $medium: String!
    $market: String!
    $campaignStart: DateTime!
    $campaignEnd: DateTime
  ) {
    createAcquisitionCampaign(
      name: $name
      description: $description
      medium: $medium
      market: $market
      campaignStart: $campaignStart
      campaignEnd: $campaignEnd
    ) {
      ok 
      messages {
        code
      }
    }
  }
`;

export const UPDATE_ACQUISITION_CAMPAIGN_MUTATION = `
  mutation UPDATE_ACQUISITION_CAMPAIGN_MUTATION (
    $uuid: String!
    $name: String
    $medium: String
    $market: String
    $campaignStart: DateTime
    $campaignEnd: DateTime
    $description: String
    $addUsers: [String]
    $removeUsers: [String]
  ) {
    updateAcquisitionCampaign(
      uuid: $uuid
      name: $name
      market: $market
      medium: $medium
      campaignStart: $campaignStart
      campaignEnd: $campaignEnd
      description: $description
      addUsers: $addUsers
      removeUsers: $removeUsers
    ) {
      ok 
      messages {
        code
      }
    }
  }
`;

export const CREATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION = `
  mutation CREATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION (
    $acquisitionCampaign: String!
    $spendAmount: Int!
    $clicks: Int!
    $impressions: Int!
    $recordDate: Date
  ) {
    createAcquisitionCampaignRecord(
      acquisitionCampaign: $acquisitionCampaign
      spendAmount: $spendAmount
      clicks: $clicks
      impressions: $impressions
      recordDate: $recordDate
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION = `
  mutation UPDATE_ACQUISITION_CAMPAIGN_RECORD_MUTATION (
    $id: String!
    $spendAmount: Int
    $clicks: Int
    $impressions: Int
    $recordDate: Date
  ) {
    updateAcquisitionCampaignRecord(
      id: $id
      spendAmount: $spendAmount
      clicks: $clicks
      impressions: $impressions
      recordDate: $recordDate
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const DELETE_ACQUISITION_CAMPAIGN_RECORD_MUTATION = `
  mutation DELETE_ACQUISITION_CAMPAIGN_RECORD_MUTATION (
    $id: String!
  ) {
    deleteAcquisitionCampaignRecord (
      id: $id
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CREATE_ATTRIBUTION_CODE_MUTATION = `
  mutation CREATE_ATTRIBUTION_CODE_MUTATION (
    $name: String!
    $acquisitionCampaign: String!
    $queryString: String! 
  ) {
    createAttributionCode(
      name: $name
      acquisitionCampaign: $acquisitionCampaign
      queryString: $queryString
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const UPDATE_ATTRIBUTION_CODE_MUTATION = `
  mutation UPDATE_ATTRIBUTION_CODE_MUTATION(
    $attributionCode: String!
    $acquisitionCampaign: String
    $name: String
    $queryString: String
    $addUsers: [String]
    $removeUsers: [String]
  ) {
    updateAttributionCode (
      attributionCode: $attributionCode
      acquisitionCampaign: $acquisitionCampaign
      name: $name
      queryString: $queryString
      addUsers: $addUsers
      removeUsers: $removeUsers
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
