import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

// FUTURE: some back end is not up to date for these fields
// $isLeaf: Boolean
// $hasParent: Boolean
const _ORGANIZATION_PARAMS = `
  $uuid: String
  $uuids: [String]
  ${_PAGING_PARAMS}
`;

// isLeaf: $isLeaf
// hasParent: $hasParent
const _ORGANIZATION_VARIABLES = `
  uuid: $uuid
  uuids: $uuids
  ${_PAGING_VARIABLES}
`;

const _ORGANIZATION_CORE = `
  name
  uuid
`;

const _ORGANIZATION_LOCATIONS = `
  locations {
    uuid
    name
    retailer {
      name
      logoImage
      uuid
    }
  }
`;

const _ORGANIZATION_USERS = `
  users {
    uuid
    firstName
    lastName
    email
    isRetail
    isWorker
    isStaff
  }
`;

export const ORGANIZATIONS_CORE_QUERY = `
  query ORGANIZATIONS_CORE_QUERY (${_ORGANIZATION_PARAMS}) {
    organizations (${_ORGANIZATION_VARIABLES}) {
      ${_ORGANIZATION_CORE}
    }
  }
`;

export const ORGANIZATIONS_LOCATIONS_QUERY = `
  query ORGANIZATIONS_LOCATIONS_QUERY (${_ORGANIZATION_PARAMS}) {
    organizations (${_ORGANIZATION_VARIABLES}) {
      ${_ORGANIZATION_CORE}
      ${_ORGANIZATION_LOCATIONS}
    }
  }
`;

export const ORGANIZATIONS_USERS_QUERY = `
  query ORGANIZATIONS_USERS_QUERY (${_ORGANIZATION_PARAMS}) {
    organizations (${_ORGANIZATION_VARIABLES}) {
      ${_ORGANIZATION_CORE}
      ${_ORGANIZATION_USERS}
    }
  }
`;

export const ORGANIZATIONS_FULL_QUERY = `
  query ORGANIZATIONS_FULL_QUERY (${_ORGANIZATION_PARAMS}) {
    organizations (${_ORGANIZATION_VARIABLES}) {
      ${_ORGANIZATION_CORE}
      ${_ORGANIZATION_LOCATIONS}
      ${_ORGANIZATION_USERS}
    }
  }
`;
