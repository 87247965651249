import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { paging, ReflexResponse } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchRetailersProps extends paging {
  hasContracts?: boolean;
  hasLocations?: boolean;
  uuid?: string;
  uuids?: string[];
}

export const fetchRetailers = async (
  data: fetchRetailersProps,
  query = Queries.RETAILERS_LIST_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = 'retailers',
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      contextOverride ? contextOverride : 'RETAILERS_LIST_QUERY',
    ),
    query,
    data,
    returnKey,
  });

export interface mutateRetailerCreateProps {
  name: string;
  image?: string; // FUTURE: rename image to logoImage
}

export const mutateRetailerCreate = async (
  data: mutateRetailerCreateProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_RETAILER_MUTATION'),
    query: Mutations.CREATE_RETAILER_MUTATION,
    data,
    returnKey: 'createRetailer',
  });

export interface mutateRetailerUpdateProps {
  uuid: string;
  name?: string;
  logoImage?: string;
  brandImage?: string;
}

export const mutateRetailerUpdate = async (
  data: mutateRetailerUpdateProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_RETAILER_MUTATION'),
    query: Mutations.UPDATE_RETAILER_MUTATION,
    data,
    returnKey: 'updateRetailer',
  });
